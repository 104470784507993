import React from "react";
import { FaAngleRight } from "react-icons/fa6";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import "../../Outletinfo/Outlet.css";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import { FaRegFileAlt } from "react-icons/fa";
import Modal from "../../components/Modal";
import { FaCaretRight } from "react-icons/fa";
import {
  faSearch,
  faTimes,
  faPhoneVolume,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import user from "../../assets/chef.webp";
import { TiTicket } from "react-icons/ti";
import { IoIosStar } from "react-icons/io";
import Accordin from "../../components/Accordin";
export function Restarent() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="border m-4 rounded-lg overflow-hidden ">
        <div className="bg-gray-200 text-lg font-semibold py-2 px-5">
          select an option to procced
        </div>
        <div className="mx-5 mb-5">
          <button
            type="button"
            onClick={() => navigate("/helpcenter/restarent-timing")}
            className="flex justify-between border-b w-full hover:bg-gray-50 px-5  py-3"
          >
            <span>Timings</span>
            <FaAngleRight />
          </button>
          <button
            type="button"
            onClick={() => navigate("/helpcenter/restarent-contact")}
            className="flex justify-between border-b hover:bg-gray-50 px-5 w-full  py-3"
          >
            <span>Contacts</span>
            <FaAngleRight />
          </button>
          <button
            type="button"
            className="flex justify-between border-b hover:bg-gray-50 px-5 w-full  py-3"
          >
            <span>FSSI Food Licence</span>
            <FaAngleRight />
          </button>
          <button
            type="button"
            onClick={() => navigate("/helpcenter/restarent-update-gst")}
            className="flex justify-between border-b hover:bg-gray-50 px-5 w-full  py-3"
          >
            <span>Update GSTIN</span>
            <FaAngleRight />
          </button>
          <button
            type="button"
            onClick={() => navigate("/helpcenter/restarent-display-picture")}
            className="flex justify-between border-b hover:bg-gray-50 px-5 w-full  py-3"
          >
            <span>Display Picture</span>
            <FaAngleRight />
          </button>
          <button
            type="button"
            onClick={() =>
              navigate("/helpcenter/restarent-name-address-location")
            }
            className="flex justify-between border-b hover:bg-gray-50 px-5 w-full  py-3"
          >
            <span>Name,Address,Location</span>
            <FaAngleRight />
          </button>
          <button
            type="button"
            onClick={() => navigate("/helpcenter/restarent-review-rating")}
            className="flex justify-between border-b hover:bg-gray-50 px-5 w-full  py-3"
          >
            <span>Ratings,Reviews</span>
            <FaAngleRight />
          </button>
          <button
            type="button"
            onClick={() =>
              navigate("/helpcenter/restarent-delivery-area-changes")
            }
            className="flex justify-between border-b hover:bg-gray-50 px-5 w-full  py-3"
          >
            <span>Delivery Area Changes</span>
            <FaAngleRight />
          </button>
        </div>
      </div>
    </div>
  );
}

export function RestarentTiming() {
  const videoSrc = "https://www.w3schools.com/html/mov_bbb.mp4";
  const initialSlots = [
    {
      day: "Monday",
      isOpen: false,
      timeSlots: [{ startTime: null, endTime: null }],
    },
    {
      day: "Tuesday",
      isOpen: false,
      timeSlots: [{ startTime: null, endTime: null }],
    },
    {
      day: "Wednesday",
      isOpen: false,
      timeSlots: [{ startTime: null, endTime: null }],
    },
    {
      day: "Thursday",
      isOpen: false,
      timeSlots: [{ startTime: null, endTime: null }],
    },
    {
      day: "Friday",
      isOpen: false,
      timeSlots: [{ startTime: null, endTime: null }],
    },
    {
      day: "Saturday",
      isOpen: false,
      timeSlots: [{ startTime: null, endTime: null }],
    },
    {
      day: "Sunday",
      isOpen: false,
      timeSlots: [{ startTime: null, endTime: null }],
    },
  ];

  const [state, setState] = useState({
    slots: initialSlots,
    checked: false,
    copyChecked: false,
  });
  const handleAccordionToggle = (index) => {
    const newSlots = [...state.slots];
    newSlots[index].isOpen = !newSlots[index].isOpen;
    setState((prevState) => ({
      ...prevState,
      slots: newSlots,
    }));
  };

  const handleTimeChange = (dayIndex, slotIndex, type, time) => {
    const newSlots = [...state.slots];
    newSlots[dayIndex].timeSlots[slotIndex][type] = time;
    setState((prevState) => ({
      ...prevState,
      slots: newSlots,
    }));
  };

  const addTimeSlot = (dayIndex) => {
    const newSlots = [...state.slots];
    newSlots[dayIndex].timeSlots.push({ startTime: null, endTime: null });
    setState((prevState) => ({
      ...prevState,
      slots: newSlots,
    }));
  };

  const deleteTimeSlot = (dayIndex, slotIndex) => {
    const newSlots = [...state.slots];
    newSlots[dayIndex].timeSlots.splice(slotIndex, 1);
    setState((prevState) => ({
      ...prevState,
      slots: newSlots,
    }));
  };

  const handleSave = (index) => {
    console.log(
      "Saving data for",
      state.slots[index].day,
      ":",
      state.slots[index]
    );
  };

  const handleToggle = () => {
    setState((prevState) => ({
      ...prevState,
      checked: !prevState.checked,
    }));
  };
  const handleCopyToggle = () => {
    setState((prevState) => ({
      ...prevState,
      copyChecked: !prevState.copyChecked,
    }));
  };

  return (
    <>
      <div className="flex flex-col md:flex-row border-b-2 border-gray-300">
        <div className="p-4 content-center">
          <video controls className="w-full rounded-md">
            <source src={videoSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="p-4">
          <h6 className="text-lg mb-3 font-bold font-sans">
            How to update online ordering operational Timings | Grow with Genie
          </h6>
          <p className="text-base font-sans leading-7 text-gray-600">
            Want to change the operational timings for online ordering? Watch
            how you can easily do so using the Restaurant Partner App. Download
            App Android- hhtps:/{" "}
          </p>
        </div>
      </div>
      <div className="border-b-2 border-gray-300 pb-3 p-4">
        <h6 className="text-base font-bold font-sans text-green">
          Genie Delivery
        </h6>
      </div>
      <div className="p-4">
        <div>
          {state.slots.map((slot, dayIndex) => (
            <div
              key={dayIndex}
              className={`mb-4 pb-2 pt-2 px-5 text-base rounded-md ${slot.isOpen ? "bg-green" : "bg-zinc-200"
                }`}
            >
              <button
                className={`p-2 rounded w-full text-left ${slot.isOpen ? "text-white" : "text-green"
                  }`}
                onClick={() => handleAccordionToggle(dayIndex)}
              >
                {slot.day}
              </button>
              {slot.isOpen && (
                <div className="p-4 bg-white">
                  {slot.timeSlots &&
                    slot.timeSlots.map((timeSlot, slotIndex) => (
                      <div
                        key={slotIndex}
                        className="flex items-center space-x-2 mb-2 border-b-2 border-gray-300 pb-4"
                      >
                        <div className="flex items-center space-x-2 ">
                          <p className="mt-5 font-bold">Slot {slotIndex + 1}</p>
                          <div>
                            <p className="text-center text-gray-700 font-semibold">
                              Start Time
                            </p>
                            <DatePicker
                              selected={timeSlot.startTime}
                              onChange={(time) =>
                                handleTimeChange(
                                  dayIndex,
                                  slotIndex,
                                  "startTime",
                                  time
                                )
                              }
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              dateFormat="h:mm aa"
                              timeCaption="Time"
                              placeholderText="Start Time"
                              className="border p-2 rounded w-32 mt-3"
                            />
                          </div>
                          <span className="mt-5">to</span>
                          <div>
                            <p className="text-center text-gray-700 font-semibold">
                              End Time
                            </p>
                            <DatePicker
                              selected={timeSlot.endTime}
                              onChange={(time) =>
                                handleTimeChange(
                                  dayIndex,
                                  slotIndex,
                                  "endTime",
                                  time
                                )
                              }
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              dateFormat="h:mm aa"
                              timeCaption="Time"
                              placeholderText="End Time"
                              className="border p-2 rounded w-32 mt-3"
                            />
                          </div>
                        </div>
                        <button
                          onClick={() => deleteTimeSlot(dayIndex, slotIndex)}
                          className="text-gray-500 mt-5 px-3"
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </div>
                    ))}
                  <div className="flex justify-end">
                    <button
                      onClick={() => addTimeSlot(dayIndex)}
                      className="text-green mb-4"
                    >
                      + Add time slot
                    </button>
                  </div>
                  <div className="flex items-center space-x-4 mb-4">
                    <input
                      type="checkbox"
                      className="bg-green"
                      checked={state.copyChecked}
                      onChange={handleCopyToggle}
                    />
                    <span>Copy above timings to all days</span>
                    <label
                      htmlFor="toggle"
                      className="flex items-center space-x-2 cursor-pointer"
                    >
                      <input
                        id="toggle"
                        type="checkbox"
                        className="hidden"
                        checked={state.checked}
                        onChange={handleToggle}
                      />
                      <div
                        className={`relative w-12 h-6 rounded-full shadow-inner ring-1 ring-gray-400 ${state.checked ? "bg-green" : "bg-gray-300"
                          }`}
                      >
                        <div
                          className={`dot absolute w-6 h-6 bg-white rounded-full shadow-md ring-1 ring-gray-400 transition-transform duration-300 ${state.checked
                            ? "transform translate-x-full"
                            : "transform translate-x-0"
                            }`}
                        ></div>
                      </div>
                      <span>Outlet Open</span>
                    </label>

                    <div className="flex justify-end">
                      <button
                        onClick={() => handleSave(dayIndex)}
                        className={`px-4 py-2 text-white rounded ${state.copyChecked ? "bg-green" : "bg-gray-300"
                          }`}
                        disabled={!state.copyChecked}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export function ContactDetails() {
  // State management for off-canvas views
  const [contactState, setContactState] = useState({
    isViewPermissionOpen: false,
    isViewStaffPermissionOpen: false,
    isEditContactOpen: false,
    isManagerPermissionOpen: false,
    currentContact: null,
    phoneType: "Mobile",
    ownerProfile: {},
    managerProfile: {},
    isEditing: false,
    showDeleteModal: false,
    showInvitePanel: false,
    inputType: "phone",
    phoneNumber: "",
    error: "",
  });

  useEffect(() => {
    if (
      contactState.isViewPermissionOpen ||
      contactState.isEditContactOpen ||
      contactState.isManagerPermissionOpen ||
      contactState.isViewStaffPermissionOpen
    ) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [
    contactState.isViewPermissionOpen,
    contactState.isEditContactOpen,
    contactState.isManagerPermissionOpen,
    contactState.isViewStaffPermissionOpen,
  ]);

  useEffect(() => {
    // Fetch the owner profile data
    const fetchOwnerProfile = async () => {
      const ownerData = {
        name: "Koushik",
        phoneNumber: "+91 9600745656",
        email: "owner@example.com",
        profileImage: user, // Placeholder image URL
      };

      setContactState((prevState) => ({
        ...prevState,
        ownerProfile: ownerData,
        managerProfile: {
          ...ownerData,
          name: "Koushik", // Change the name to distinguish between owner and manager
        },
      }));
    };

    fetchOwnerProfile();
  }, []);

  const handleOpenViewPermission = () => {
    setContactState((prevState) => ({
      ...prevState,
      isViewPermissionOpen: true,
    }));
  };

  const handleCloseViewPermission = () => {
    setContactState((prevState) => ({
      ...prevState,
      isViewPermissionOpen: false,
    }));
  };

  const handleOpenEditContact = (contact) => {
    setContactState((prevState) => ({
      ...prevState,
      currentContact: contact,
      phoneNumber: contact?.phoneNumber || "",
      isEditContactOpen: true,
    }));
  };

  const handleCloseEditContact = () => {
    setContactState((prevState) => ({
      ...prevState,
      currentContact: null,
      isEditContactOpen: false,
    }));
  };
  const handleOpenManagerPermission = () => {
    setContactState((prevState) => ({
      ...prevState,
      isManagerPermissionOpen: true,
    }));
  };

  const handleCloseManagerPermission = () => {
    setContactState((prevState) => ({
      ...prevState,
      isManagerPermissionOpen: false,
    }));
  };
  const handleOpenViewStaff = () => {
    setContactState((prevState) => ({
      ...prevState,
      isViewStaffPermissionOpen: true,
    }));
  };
  const handleCloseViewStaff = () => {
    setContactState((prevState) => ({
      ...prevState,
      isViewStaffPermissionOpen: false,
    }));
  };

  // Handlers for opening and closing off-canvas panel
  const handleOpenEditPanel = () => {
    setContactState((prevState) => ({
      ...prevState,
      isEditing: true,
    }));
  };

  const handleCloseEditPanel = () => {
    setContactState((prevState) => ({
      ...prevState,
      isEditing: false,
    }));
  };
  // Handler for updating manager details
  const handleUpdateManager = () => {
    // Implement update logic here (e.g., call API to update manager details)
    console.log("Updating manager profile:", contactState.managerProfile);
    handleCloseEditPanel(); // Close the edit panel after updating
  };

  // Handlers for delete confirmation modal
  const handleOpenDeleteModal = () => {
    setContactState((prevState) => ({
      ...prevState,
      showDeleteModal: true,
    }));
  };

  const handleCloseDeleteModal = () => {
    setContactState((prevState) => ({
      ...prevState,
      showDeleteModal: false,
    }));
  };

  const handleDeleteManager = () => {
    // Implement delete logic here (e.g., call API to delete manager)
    console.log("Deleting manager:", contactState.managerProfile.name);
    // Assuming delete logic succeeds, you might redirect or handle state accordingly
    setContactState((prevState) => ({
      ...prevState,
      showDeleteModal: false,
    })); // Close delete modal after deletion
  };
  // invite panel
  const handleOpenInvitePanel = () => {
    setContactState((prevState) => ({
      ...prevState,
      showInvitePanel: true,
    }));
  };

  // Function to handle closing invite panel
  const handleCloseInvitePanel = () => {
    setContactState((prevState) => ({
      ...prevState,
      showInvitePanel: false,
    }));
  };

  const handleInputTypeChange = (type) => {
    setContactState((prevState) => ({
      ...prevState,
      inputType: type,
    }));
  };

  const validatePhoneNumber = (number) => {
    // Regex to allow numbers, dashes, spaces, country code, and a minimum of 10 and max 15 digits
    const phoneRegex = /^[0-9]{10,15}$/;
    return phoneRegex.test(number);
  };

  const handlePhoneNumberChange = (e) => {
    const input = e.target.value;
    if (/^\d*$/.test(input)) {
      setContactState((prevState) => ({
        ...prevState,
        phoneNumber: input,
        error:
          input.length > 10
            ? "Phone number must be exactly 10 digits long."
            : validatePhoneNumber(input)
              ? ""
              : "Phone number must be exactly 10 digits long.",
      }));
    } else {
      setContactState((prevState) => ({
        ...prevState,
        error: "Only digits are allowed.",
      }));
    }
  };

  // Permission details
  const permissions = [
    {
      heading: "Manager Order",
      paragraph: "Accept order, mark order, print bill",
    },
    { heading: "Manage menu", paragraph: "Mark item out of stock" },
    {
      heading: "Manage outlet settings",
      paragraph: "Toggle outlet status ON/OFF",
    },
    { heading: "Manage users", paragraph: "Edit staff contact details" },
    {
      heading: "Manage customer complaints",
      paragraph: "View or take action on customer complaints",
    },
    {
      heading: "Request support",
      paragraph:
        "View and create tickets on order, ads, promos, tapes, menu etc.",
    },
  ];

  // Manager permission details
  const managerPermissions = [
    {
      heading: "Manage orders",
      paragraph: "Accept order, mark order ready, print bill",
    },
    {
      heading: "Manage menu",
      paragraph:
        "Create, edit item details (names, photos, prices etc.), mark item out of stock",
    },
    {
      heading: "Manage outlet settings",
      paragraph: "Edit daily timings, toggle outlet status ON/OFF etc.",
    },
    {
      heading: "Manage users",
      paragraph: "Edit manager, staff contact details",
    },
    {
      heading: "Manage customer complaints",
      paragraph: "View or take action on customer complaints",
    },
    {
      heading: "Request support",
      paragraph:
        "View and create tickets on order, ads, promos, tapes, menu etc.",
    },
    {
      heading: "View business insights",
      paragraph: "Track sales/rejection/top dishes etc. metrics",
    },
    {
      heading: "Manage finances",
      paragraph: "View account statement, payouts & invoices",
    },
  ];
  // Staff Permission
  const staff = [
    {
      heading: "Manager Order",
      paragraph: "Accept order, mark order, print bill",
    },
    { heading: "Manage menu", paragraph: "Mark item out of stock" },
    {
      heading: "Manage outlet settings",
      paragraph: "Toggle outlet status ON/OFF",
    },
    { heading: "Manage users", paragraph: "Edit staff contact details" },
    {
      heading: "Manage customer complaints",
      paragraph: "View or take action on customer complaints",
    },
    {
      heading: "Request support",
      paragraph:
        "View and create tickets on order, ads, promos, tapes, menu etc.",
    },
  ];
  // Contact details
  const contactDetails = [
    {
      id: 1,
      reminder: "Order reminder number1",
      phoneNumber: "+91 9600745656",
    },
    {
      id: 2,
      reminder: "Order reminder number2",
      phoneNumber: "+91 9600745678",
    },
  ];

  return (
    <>
      <div className="p-4">
        <div className="flex items-center border border-gray-300 rounded-lg w-full mb-4">
          <FontAwesomeIcon icon={faSearch} className="text-gray-500 ml-3" />
          <input
            type="text"
            placeholder="Search using name, email or Phone number"
            className="w-full py-2 px-3 focus:outline-none rounded-r-lg"
          />
        </div>
      </div>
      <div className="flex items-center justify-between bg-gray-200 text-black p-3 mb-4">
        <span>Order reminder call</span>
        <button onClick={handleOpenViewPermission} className="text-green">
          View Permission
        </button>
      </div>

      {contactDetails.map((contact) => (
        <div
          key={contact.id}
          className="flex items-center p-3 mt-3 border-b-2 border-gray-300"
        >
          <div className="flex items-center justify-center w-10 h-10 bg-gray-300 rounded-full mr-3">
            <FontAwesomeIcon icon={faPhoneVolume} className="text-gray-600" />
          </div>
          <div className="flex-1">
            <span className="block text-gray-900 font-semibold">
              {contact.reminder}
            </span>
            <span className="block text-gray-700 mt-2">
              {contact.phoneNumber}
            </span>
          </div>
          <FontAwesomeIcon
            icon={faEdit}
            className="text-gray-700 cursor-pointer"
            onClick={() => handleOpenEditContact(contact)}
          />
        </div>
      ))}
      {/* Owner Profile */}
      <div className="flex items-center justify-between bg-gray-200 text-black p-3">
        <span>Owner</span>
        <button onClick={handleOpenManagerPermission} className="text-green">
          View Permission
        </button>
      </div>

      <div className="flex items-center p-4 border-b-2 border-gray-300">
        <div className="w-16 h-16 mr-4">
          <img
            src={contactState.ownerProfile.profileImage}
            alt="Profile"
            className="w-full h-full rounded-full object-cover"
          />
        </div>
        <div>
          <span className="block text-gray-900 font-semibold">
            {contactState.ownerProfile.name}
          </span>
          <span className="block text-gray-700 mt-2">
            {contactState.ownerProfile.phoneNumber}
          </span>
          <span className="block text-gray-700 mt-2">
            {contactState.ownerProfile.email}
          </span>
        </div>
      </div>
      {/* Manager Profile */}
      <div className="flex items-center justify-between bg-gray-200 text-black p-3">
        <span>Manager</span>
        <button onClick={handleOpenManagerPermission} className="text-green">
          View Permission
        </button>
      </div>

      <div className="flex items-center justify-between p-4 border-b-2 border-gray-300">
        <div className="flex items-center">
          <div className="w-16 h-16 mr-4">
            <img
              src={contactState.managerProfile.profileImage}
              alt="Profile"
              className="w-full h-full rounded-full object-cover"
            />
          </div>
          <div>
            <span className="block text-gray-900 font-semibold">
              {contactState.managerProfile.name}
            </span>
            <span className="block text-gray-700 mt-2">
              {contactState.managerProfile.phoneNumber}
            </span>
            <span className="block text-gray-700 mt-2">
              {contactState.managerProfile.email}
            </span>
          </div>
        </div>
        <div className="flex items-center">
          <button className="text-green mr-2" onClick={handleOpenEditPanel}>
            <FontAwesomeIcon icon={faEdit} />
          </button>
          <button className="text-red" onClick={handleOpenDeleteModal}>
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      </div>
      {/* Staff Management */}
      <div className="flex items-center justify-between bg-gray-200 text-black p-3">
        <span>Staff</span>
        <button onClick={handleOpenViewStaff} className="text-green">
          View Permission
        </button>
      </div>
      <div className="flex items-center justify-between text-black p-3 ">
        <span>
          No one added as staff yet.{" "}
          <button onClick={handleOpenInvitePanel} className="text-green">
            Invite Someone now
          </button>
        </span>
      </div>

      {/* Contact details edit off-canvas */}
      {contactState.isEditContactOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-end index">
          <div className="bg-white w-1/3 h-full p-4 shadow-lg overflow-y-auto off-canvas-content">
            <div className="flex justify-between items-center border-b pb-2 mb-2">
              <h2 className="text-lg font-bold">
                Edit {contactState.currentContact.reminder || "Contact"}
              </h2>
              <button onClick={handleCloseEditContact}>
                <FontAwesomeIcon icon={faTimes} className="text-gray-700" />
              </button>
            </div>
            <form>
              <div className="mt-4">
                <label className="block text-gray-700 mb-2">Select type:</label>
                <div className="flex space-x-4 mb-4">
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="phoneType"
                      value="Mobile"
                      checked={contactState.phoneType === "Mobile"}
                      onChange={() =>
                        setContactState((prevState) => ({
                          ...prevState,
                          phoneType: "Mobile",
                        }))
                      }
                      className="mr-2"
                    />
                    Mobile
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="phoneType"
                      value="Landline"
                      checked={contactState.phoneType === "Landline"}
                      onChange={() =>
                        setContactState((prevState) => ({
                          ...prevState,
                          phoneType: "Landline",
                        }))
                      }
                      className="mr-2"
                    />
                    Landline
                  </label>
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 mb-2">
                    Phone number:
                  </label>
                  <input
                    type="number"
                    value={contactState.phoneNumber}
                    onChange={(e) =>
                      setContactState((prevState) => ({
                        ...prevState,
                        phoneNumber: e.target.value,
                      }))
                    }
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none"
                    maxLength={10}
                  />
                  {contactState.error && (
                    <p className="text-red mt-2">{contactState.error}</p>
                  )}
                </div>
                <div className="gird content-end">
                  <button
                    type="submit"
                    className="px-4 py-2 bg-green text-white rounded-lg"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* View Permission off-canvas */}
      {contactState.isViewPermissionOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 z-50 flex justify-end index">
          <div className="bg-white w-1/3 h-full p-4 shadow-lg overflow-y-auto off-canvas-content">
            <div className="flex justify-between items-center border-b pb-2 mb-2">
              <h2 className="text-lg font-bold">
                Order Reminder Call Permission
              </h2>
              <button onClick={handleCloseViewPermission}>
                <FontAwesomeIcon icon={faTimes} className="text-gray-700" />
              </button>
            </div>
            {permissions.map((permission, index) => (
              <div key={index} className="border-b pb-3 mb-3 mt-3">
                <h3 className="font-semibold text-slate-950">
                  {permission.heading}
                </h3>
                <p className="mt-3 font-normal">{permission.paragraph}</p>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Manager Permission off-canvas */}
      {contactState.isManagerPermissionOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-end index">
          <div className="bg-white w-1/3 h-full p-4 shadow-lg overflow-y-auto off-canvas-content">
            <div className="flex justify-between items-center border-b pb-2 mb-2">
              <h2 className="text-lg font-bold">Manager Permission</h2>
              <button onClick={handleCloseManagerPermission}>
                <FontAwesomeIcon icon={faTimes} className="text-gray-700" />
              </button>
            </div>
            {managerPermissions.map((managerPermissions, index) => (
              <div key={index} className="border-b pb-3 mb-3 mt-3">
                <h3 className="font-semibold text-slate-950">
                  {managerPermissions.heading}
                </h3>
                <p className="mt-3 font-normal">
                  {managerPermissions.paragraph}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* staff permission */}
      {contactState.isViewStaffPermissionOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 z-50 flex justify-end index">
          <div className="bg-white w-1/3 h-full p-4 shadow-lg overflow-y-auto off-canvas-content">
            <div className="flex justify-between items-center border-b pb-2 mb-2">
              <h2 className="text-lg font-bold">Staff Permission</h2>
              <button onClick={handleCloseViewStaff}>
                <FontAwesomeIcon icon={faTimes} className="text-gray-700" />
              </button>
            </div>
            {staff.map((staff, index) => (
              <div key={index} className="border-b pb-3 mb-3 mt-3">
                <h3 className="font-semibold text-slate-950">
                  {staff.heading}
                </h3>
                <p className="mt-3 font-normal">{staff.paragraph}</p>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Off-canvas Edit Panel (Modal) */}
      {contactState.isEditing && (
        <div className="fixed inset-0 bg-black bg-opacity-50">
          <div className="off-canvas-content absolute right-0 top-0 bg-white p-8">
            <div className="flex justify-between items-center border-b pb-2 mb-2">
              <h2 className="text-xl font-semibold mb-2">
                Edit Manager Details
              </h2>
              <button
                onClick={handleCloseEditPanel}
                className="text-gray-600 px-2 mx-4"
              >
                <FontAwesomeIcon icon={faTimes} className="text-gray-700" />
              </button>
            </div>
            <form>
              {/* Input fields for editing manager details */}
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Name
                </label>
                <input
                  type="text"
                  value={contactState.managerProfile.name}
                  onChange={(e) =>
                    setContactState((prevState) => ({
                      ...prevState,
                      managerProfile: {
                        ...prevState.managerProfile,
                        name: e.target.value,
                      },
                    }))
                  }
                  className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-green"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Phone Number
                </label>
                <input
                  type="text"
                  value={contactState.managerProfile.phoneNumber}
                  onChange={(e) =>
                    setContactState((prevState) => ({
                      ...prevState,
                      managerProfile: {
                        ...prevState.managerProfile,
                        phoneNumber: e.target.value,
                      },
                    }))
                  }
                  className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-green"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <input
                  type="email"
                  value={contactState.managerProfile.email}
                  onChange={(e) =>
                    setContactState((prevState) => ({
                      ...prevState,
                      managerProfile: {
                        ...prevState.managerProfile,
                        email: e.target.value,
                      },
                    }))
                  }
                  className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-green"
                />
              </div>
              <button
                type="button"
                onClick={handleUpdateManager}
                className="bg-green w-full hover:bg-green-600 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline"
              >
                Update
              </button>
            </form>
          </div>
        </div>
      )}
      {/* Delete popup */}
      {contactState.showDeleteModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="bg-white p-8 rounded-lg z-10 max-w-md text-center">
            <div className="text-red text-4xl mb-4">
              <FontAwesomeIcon icon={faTrash} />
            </div>
            <p className="text-lg mb-4">
              Are you sure you want to delete {contactState.managerProfile.name}
              ?
            </p>
            <p className="text-sm text-gray-600 mb-4">
              {contactState.managerProfile.name} won't be able to login or
              receive communication from the outlet.
            </p>
            <hr className="border-b-2 border-gray-300 my-4" />
            <div className="flex justify-center">
              <button
                onClick={handleDeleteManager}
                className="bg-red hover:bg-red text-white font-bold py-2 px-4 rounded-md mr-2 focus:outline-none focus:shadow-outline"
              >
                Delete
              </button>
              <button
                onClick={handleCloseDeleteModal}
                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Off-canvas Invite Panel */}
      {contactState.showInvitePanel && (
        <div
          className={`fixed inset-0 z-50 transform ${contactState.showInvitePanel ? "translate-x-0" : "translate-x-full"
            } transition-transform duration-300`}
        >
          <div
            className="fixed inset-0 bg-black opacity-50"
            onClick={handleCloseInvitePanel}
          ></div>
          <div className="bg-white h-full w-96 absolute right-0 top-0 overflow-y-auto">
            <div className="flex justify-between mb-4 border-b-2 gray-400 p-3">
              <h2 className="text-xl font-semibold">Invite User</h2>
              <button
                onClick={handleCloseInvitePanel}
                className="text-gray-600"
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            {/* Invite user form */}
            <div className="px-4 py-2">
              <label htmlFor="contactInput" className="block text-gray-700">
                {contactState.inputType === "phone" ? "Phone Number" : "Email"}
              </label>
              <input
                type={contactState.inputType === "phone" ? "text" : "email"}
                id="contactInput"
                className="w-full p-2 border border-gray-300 rounded mt-2"
                placeholder={
                  contactState.inputType === "phone"
                    ? "Enter Mobile Number"
                    : "Enter Email Address"
                }
              />
            </div>
            <div className="px-4 py-2">
              <p className="text-sm text-gray-600">
                This user will receive a link by{" "}
                {contactState.inputType === "phone" ? "SMS" : "email"} which
                they need to click on to accept the invite and be added to your
                outlet.
              </p>
            </div>
            <div className="px-4 py-2">
              <button
                onClick={() =>
                  handleInputTypeChange(
                    contactState.inputType === "phone" ? "email" : "phone"
                  )
                }
                className="text-green"
              >
                {contactState.inputType === "phone"
                  ? "Invite by email"
                  : "Invite by phone"}
              </button>
            </div>
            <div className="px-4 py-2 bg-gray-200 mt-3">
              <p className="text-lg font-semibold">User Role</p>
            </div>
            <div className="px-4 py-2 mt-3">
              <div className="mb-3">
                <input type="radio" id="staff" name="role" value="staff" />
                <label htmlFor="staff" className="ml-2">
                  Staff
                </label>
              </div>
              <button
                onClick={() => handleOpenViewStaff("staff")}
                className="text-green ml-2 px-3"
              >
                View Permission
              </button>
              <div className="mt-2 mb-3">
                <input type="radio" id="manager" name="role" value="manager" />
                <label htmlFor="manager" className="ml-2">
                  Manager
                </label>
              </div>
              <button
                onClick={() => handleOpenManagerPermission("manager")}
                className="text-green ml-2 px-3"
              >
                View Permission
              </button>
            </div>
            <div className="px-4 py-2 mt-6 flex justify-end">
              <button
                className="bg-green w-96 text-white px-4 py-2 rounded"
                onClick={handleCloseInvitePanel}
              >
                Send Invite
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}




export function UpdateGstlin() {
  const [gstState, setGstState] = useState({
    isVisibleAddGst: false,
    gstin: "",
    gstinError: "",
    isValidGstin: false, // Track if the GSTIN is valid
  });
  const navigate = useNavigate();

  // GSTIN Validation Regex for final validation
  const gstinRegex = /^[0-3][0-9][A-Z]{5}[0-9]{4}[A-Z][1-9A-Z][A-Z][0-9A-Z]$/;

  // Function to validate GSTIN character by character as user types
  const handleGstinChange = (event) => {
    let gstin = event.target.value.toUpperCase();

    // Restrict length to 15 characters
    if (gstin.length > 15) return;

    // Validate each character by position
    let valid = true;

    for (let i = 0; i < gstin.length; i++) {
      const char = gstin[i];
      if (i < 2) {
        // First two characters must be numbers (state code)
        if (!/[0-9]/.test(char)) {
          valid = false;
          break;
        }
      } else if (i >= 2 && i < 7) {
        // Next five characters must be uppercase alphabets (PAN entity name)
        if (!/[A-Z]/.test(char)) {
          valid = false;
          break;
        }
      } else if (i >= 7 && i < 11) {
        // Next four characters must be numbers (PAN number)
        if (!/[0-9]/.test(char)) {
          valid = false;
          break;
        }
      } else if (i === 11) {
        // The 12th character must be an uppercase alphabet (PAN check)
        if (!/[A-Z]/.test(char)) {
          valid = false;
          break;
        }
      } else if (i === 12) {
        // The 13th character must be alphanumeric (1-9 or A-Z)
        if (!/[1-9A-Z]/.test(char)) {
          valid = false;
          break;
        }
      } else if (i === 13) {
        // The 14th character must be an uppercase letter (A-Z)
        if (!/[A-Z]/.test(char)) {
          valid = false;
          break;
        }
      } else if (i === 14) {
        // The 15th character must be alphanumeric (0-9 or A-Z)
        if (!/[0-9A-Z]/.test(char)) {
          valid = false;
          break;
        }
      }
    }

    // Update state only if input is valid so far
    let isValid = valid && gstin.length === 15 && gstinRegex.test(gstin);

    setGstState((prevState) => ({
      ...prevState,
      gstin: gstin,
      gstinError: isValid ? "" : gstin.length === 15 ? "Invalid GSTIN format." : "",
      isValidGstin: isValid, // Update if GSTIN is valid
    }));
  };

  // Save button handler
  const handleSave = () => {
    if (!gstState.gstin || gstState.gstinError || gstState.gstin.length !== 15) {
      alert("Please enter a valid GSTIN number.");
    } else {
      // Proceed with the Save operation
      console.log("GSTIN Saved:", gstState.gstin);
    }
  };

  return (
    <div>
      <div className="m-5 border rounded-lg overflow-hidden">
        <div className="flex space-x-3 bg-gray-100 p-2">
          <FaArrowLeftLong
            className="cursor-pointer mt-1"
            onClick={() => navigate("/helpcenter/restaurant")}
          />
          <span className="font-semibold ml-2">Update GSTIN</span>
        </div>
        <div className="p-3">
          <div className="font-semibold ">Select GSTIN</div>
          <div className="py-4">
            <input
              type="checkbox"
              onChange={() =>
                setGstState((prevState) => ({
                  ...prevState,
                  isVisibleAddGst: !prevState.isVisibleAddGst,
                }))
              }
              className="form-checkbox h-4 w-4 rounded-full text-green"
              id="javascript"
              name="fav_language"
            />
            <span className="px-2">Add New GST</span>
          </div>
          {gstState.isVisibleAddGst && (
            <div className="m-5">
              <input
                type="text"
                className={`border-2 rounded focus:outline-none p-2 w-full ${gstState.isValidGstin ? "bg-green-100 border-green-500" : "bg-white"
                  }`}
                placeholder="Enter GSTIN Number"
                value={gstState.gstin}
                onChange={handleGstinChange}
              />
              {gstState.gstinError && (
                <p className="text-red-600 mt-2">{gstState.gstinError}</p>
              )}
            </div>
          )}
        </div>
      </div>
      <button
        type="button"
        className={`p-1 px-5 rounded-lg text-lg mx-5 ${gstState.isValidGstin ? "bg-green text-white hover:bg-green-600" : "bg-gray-300 text-gray-700"
          }`}
        onClick={handleSave}
        disabled={!gstState.isValidGstin}
      >
        Save
      </button>
    </div>
  );
}




export function DisplayPicture() {
  const navigate = useNavigate();
  const initialState = {
    isOpen: false,
    description: "",
    descError: "",
    phoneNumber: "",
    phoneError: "",
    fileError: "",
    fileInfo: null,
    mediaError: "",
    mediaInfo: null,
    isMediaDialogOpened: false,
    isFileDialogOpened: false,
  };

  const [displayState, setDisplayState] = useState(initialState);

  const allowedMediaTypes = [
    "image/jpeg",
    "image/png",
    "image/webp",
    "video/mp4",
    "video/webm",
  ];
  const allowedFileTypes = [
    "image/jpeg",
    "image/png",
    "application/pdf",
    "image/webp",
  ];
  const maxMediaSize = 10 * 1024 * 1024; // 10 MB for media
  const maxFileSize = 5 * 1024 * 1024; // 5 MB for files

  const handleInputNumberChange = (e) => {
    const input = e.target.value.replace(/[^0-9]/g, "").slice(0, 10);
    setDisplayState((prevState) => ({
      ...prevState,
      phoneNumber: input,
      phoneError:
        input === ""
          ? "Phone number is required."
          : input.length < 10
            ? "Phone number must be 10 digits."
            : "",
    }));
  };

  const handleInputDescriptionChange = (e) => {
    const input = e.target.value;
    setDisplayState((prevState) => ({
      ...prevState,
      description: input,
      descError:
        input.length > 200
          ? "Description cannot exceed 200 characters."
          : input.trim().length === 0
            ? "This field cannot be left empty."
            : "",
    }));
  };

  const handleMediaChange = (e) => {
    const media = e.target.files[0];
    setDisplayState((prevState) => ({
      ...prevState,
      isMediaDialogOpened: false,
      mediaError: media
        ? !allowedMediaTypes.includes(media.type)
          ? "Invalid media format. Allowed formats are .jpg, .png, .webp, .mp4, .webm."
          : media.size > maxMediaSize
            ? "Media size exceeds the 10 MB limit."
            : ""
        : "No media selected.",
      mediaInfo:
        media && !prevState.mediaError
          ? {
            name: media.name,
            size: (media.size / 1024 / 1024).toFixed(2) + " MB",
            type: media.type,
          }
          : null,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setDisplayState((prevState) => ({
      ...prevState,
      isFileDialogOpened: false,
      fileError: file
        ? !allowedFileTypes.includes(file.type)
          ? "Invalid file format. Allowed formats are .jpg, .png, .pdf, and .webp."
          : file.size > maxFileSize
            ? "File size exceeds the 5 MB limit."
            : ""
        : "No file selected.",
      fileInfo:
        file && !prevState.fileError
          ? {
            name: file.name,
            size: (file.size / 1024 / 1024).toFixed(2) + " MB",
            type: file.type,
          }
          : null,
    }));
  };

  const handleDeleteMedia = () => {
    setDisplayState((prevState) => ({
      ...prevState,
      mediaInfo: null,
      mediaError: "",
    }));
  };

  const handleDeleteFile = () => {
    setDisplayState((prevState) => ({
      ...prevState,
      fileInfo: null,
      fileError: "",
    }));
  };

  // Check if all required fields are filled
  const isFormValid = () => {
    return (
      displayState.description.trim() !== "" &&
      displayState.phoneNumber.length === 10 &&
      displayState.fileInfo !== null &&
      displayState.mediaInfo !== null &&
      !displayState.descError &&
      !displayState.phoneError &&
      !displayState.fileError &&
      !displayState.mediaError
    );
  };

  // Handle Submit
  const handleSubmit = () => {
    if (!isFormValid()) {
      alert("Please fill in all required fields correctly.");
    } else {
      alert("Ticket Raised Successfully!");
      setDisplayState(initialState); // Reset the form after successful submission
    }
  };

  return (
    <div>
      <div className="m-5 border rounded-lg space-y-5 overflow-hidden">
        <div className="flex space-x-3 bg-gray-100 p-2">
          <span className="font-semibold">Display Picture</span>
        </div>
        <p className="px-3 text-lg">
          To get Your Restaurant display picture updated, please write to us
        </p>
        <button
          type="button"
          onClick={() =>
            setDisplayState((prevState) => ({
              ...prevState,
              isOpen: !prevState.isOpen,
            }))
          }
          className="space-x-2 px-3 pb-3 text-green"
        >
          <TiTicket className="inline" /> <span>Raise a ticket</span>
        </button>
        <Modal
          close={true}
          isOpen={displayState.isOpen}
          width={400}
          onClose={() =>
            setDisplayState((prevState) => ({
              ...prevState,
              isOpen: !prevState.isOpen,
            }))
          }
        >


          <div className="space-y-3">
            <div className="text-lg font-medium">Please Give Details</div>
            <div className="text-lg font-semibold p-1 bg-blue-50">
              Restaurant : Display Picture
            </div>

            {/* Description Field */}
            <div className="space-y-1">
              <p>
                Describe Your Issue <span className="text-red">*</span>
              </p>
              <textarea
                name="describe"
                id="describe"
                className="border w-full p-1 rounded"
                value={displayState.description}
                onChange={(e) => {
                  const value = e.target.value;
                  // Only allow letters and spaces, and limit input to 100 characters
                  if (/^[A-Za-z\s]*$/.test(value) && value.length <= 100) {
                    handleInputDescriptionChange(e); // Update state if valid
                  }
                }}
                placeholder="Describe your issue here..."
              ></textarea>
              {displayState.descError && (
                <p className="font-semibold text-red text-sm">
                  {displayState.descError}
                </p>
              )}
              <p className="text-gray-500 text-sm mt-1">{displayState.description.length}/100 characters</p>
            </div>


            {/* Phone Number Field */}
            <div className="space-y-1">
              <p>
                Callback Contact Number <span className="text-red">*</span>
              </p>
              <div className="flex">
                <span className="border p-1 rounded-l bg-gray-200 text-gray-700">
                  +91
                </span>
                <input
                  type="text"
                  name="callback"
                  className="border w-full p-1 rounded-r"
                  id="callback"
                  value={displayState.phoneNumber}
                  onChange={handleInputNumberChange}
                  placeholder="Enter 10-digit phone number"
                />
              </div>
              {displayState.phoneError && (
                <p className="font-semibold text-red text-sm">
                  {displayState.phoneError}
                </p>
              )}
            </div>

            <p>Attachment</p>

            {/* Media Upload */}
            <div>
              <label htmlFor="mediaInput">
                <div
                  className="flex justify-between text-lg border items-center p-2 rounded cursor-pointer"
                  onClick={() =>
                    setDisplayState((prevState) => ({
                      ...prevState,
                      isMediaDialogOpened: true,
                    }))
                  }
                >
                  <span className="flex items-center gap-2">
                    <FaRegFileAlt size={24} /> Upload Image or Video
                  </span>
                  <FaArrowRightLong />
                </div>
                <input
                  id="mediaInput"
                  name="media"
                  type="file"
                  accept="image/*,video/*"
                  className="hidden"
                  onChange={handleMediaChange}
                  alt="upload your media"
                />
              </label>
              {displayState.mediaError && (
                <p className="text-red text-sm">{displayState.mediaError}</p>
              )}
              {displayState.mediaInfo && (
                <div className="mt-1 p-1 bg-gray-100 rounded-lg shadow-md">
                  <p className="text-green mt-1">
                    <strong>Media Name:</strong> {displayState.mediaInfo.name}
                  </p>
                  <button
                    onClick={handleDeleteMedia}
                    className="text-red font-semibold hover:underline"
                  >
                    Delete Media
                  </button>
                </div>
              )}
            </div>

            {/* File Upload */}
            <div>
              <label htmlFor="fileInput">
                <div
                  className="flex justify-between text-lg border items-center p-2 rounded cursor-pointer"
                  onClick={() =>
                    setDisplayState((prevState) => ({
                      ...prevState,
                      isFileDialogOpened: true,
                    }))
                  }
                >
                  <span className="flex items-center gap-2">
                    <FaRegFileAlt size={24} /> Upload File
                  </span>
                  <FaArrowRightLong />
                </div>
                <input
                  id="fileInput"
                  name="file"
                  type="file"
                  accept=".jpg,.png,.pdf,.webp"
                  className="hidden"
                  onChange={handleFileChange}
                  alt="upload your file"
                />
              </label>
              {displayState.fileError && (
                <p className="text-red text-sm">{displayState.fileError}</p>
              )}
              {displayState.fileInfo && (
                <div className="mt-1 p-1 bg-gray-100 rounded-lg shadow-md">
                  <p className="text-green mt-1">
                    <strong>File Name:</strong> {displayState.fileInfo.name}
                  </p>
                  <button
                    onClick={handleDeleteFile}
                    className="text-red font-semibold hover:underline"
                  >
                    Delete File
                  </button>
                </div>
              )}
            </div>
            <p>Please make sure everything is correct</p>
            <button
              type="button"
              onClick={() => {
                if (isFormValid()) {
                  handleSubmit();
                } else {
                  alert("Please fill in all required fields correctly.");
                }
              }}
              className={`block w-full p-2 rounded ${isFormValid()
                ? "bg-green text-white cursor-pointer"
                : "bg-gray-300 text-gray-600 cursor-not-allowed"
                }`}
              disabled={!isFormValid()}
            >
              Raise Ticket
            </button>
          </div>
          {/* The rest of the modal code remains unchanged */}
        </Modal>
      </div>
    </div>
  );
}






export function ReviewsAndRating() {
  const nevigate = useNavigate();
  return (
    <div>
      <div className="m-5 border rounded">
        <div className="flex space-x-3 bg-gray-100 py-5 p-2">
          <FaArrowLeftLong
            className="cursor-pointer mt-1"
            onClick={() => nevigate("/helpcenter/restaurant")}
          />{" "}
          <span className="font-semibold">Rating,review</span>
        </div>
        <div className="bg-green flex items-center">
          <div className="p-4">
            {" "}
            <img
              className=""
              src="https://www.fnb-online.com/-/media/fnbonline/images/feature/pagecontent/content-sub-header/5-insurance-copy-half.webp"
              width={200}
              alt=""
            />
          </div>
          <div className="text-xl text-white">
            Ratings Represent Your Costomer opinion <br /> About Your Food
            Quality
          </div>
        </div>
        <div className="mx-5 ">
          <div className="my-3 flex space-x-3">
            {" "}
            <span className="font-semibold px-2">Your Restaurent Rating</span>{" "}
            <span className="bg-green px-2 text-white rounded p-[2px] flex items-center w-fit">
              4.2
              <IoIosStar className="inline" />
            </span>
          </div>
          <div className="flex items-center py-1 text-lg text-green cursor-pointer">
            <span className="py-1">view order rating</span>
            <FaCaretRight />
          </div>
          <Accordin
            headtext={
              " Lorem ipsum dolor sit amet consectetur adipisicing elit."
            }
            description={
              "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam, hic doloremque accusamus fuga sequi in qui dolore iste laudantium quos nisi? Reiciendis, molestias reprehenderit eligendi officiis fuga quod quas laudantium."
            }
          />
          <Accordin
            headtext={
              " Lorem ipsum dolor sit amet consectetur adipisicing elit."
            }
            description={
              "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam, hic doloremque accusamus fuga sequi in qui dolore iste laudantium quos nisi? Reiciendis, molestias reprehenderit eligendi officiis fuga quod quas laudantium."
            }
          />
          <Accordin
            headtext={
              " Lorem ipsum dolor sit amet consectetur adipisicing elit."
            }
            description={
              "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam, hic doloremque accusamus fuga sequi in qui dolore iste laudantium quos nisi? Reiciendis, molestias reprehenderit eligendi officiis fuga quod quas laudantium."
            }
          />
          <Accordin
            headtext={
              " Lorem ipsum dolor sit amet consectetur adipisicing elit."
            }
            description={
              "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam, hic doloremque accusamus fuga sequi in qui dolore iste laudantium quos nisi? Reiciendis, molestias reprehenderit eligendi officiis fuga quod quas laudantium."
            }
          />
          <Accordin
            headtext={
              " Lorem ipsum dolor sit amet consectetur adipisicing elit."
            }
            description={
              "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam, hic doloremque accusamus fuga sequi in qui dolore iste laudantium quos nisi? Reiciendis, molestias reprehenderit eligendi officiis fuga quod quas laudantium."
            }
          />
          <Accordin
            headtext={
              " Lorem ipsum dolor sit amet consectetur adipisicing elit."
            }
            description={
              "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam, hic doloremque accusamus fuga sequi in qui dolore iste laudantium quos nisi? Reiciendis, molestias reprehenderit eligendi officiis fuga quod quas laudantium."
            }
          />
        </div>
      </div>
    </div>
  );
}

export function DeliveryAreaChanges() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex space-x-3 bg-gray-100 py-5 p-2">
        <FaArrowLeftLong
          className="cursor-pointer mt-1"
          onClick={() => navigate("/helpcenter/restaurant")}
        />{" "}
        <span className="font-semibold">Delivery Area Changes</span>
      </div>
      <img src="/delivery.webp" alt="" />
      <div className="m-3">
        <p className="text-lg">
          You can not modify the delivery area of your restaurent
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias,
          adipisci atque sequi a ipsum praesentium provident, in error soluta
          impedit architecto dignissimos? Quod praesentium ipsum nostrum dolore
          error odit ducimus.
        </p>
      </div>
    </div>
  );
}

export function NameAddressLocation() {
  return <div>Name Address Location</div>;
}
