import React, { useEffect, useState } from 'react'
import Modal from '../../components/Modal';
// import 'reactjs-popup/dist/index.css';
import { LiaToggleOnSolid } from "react-icons/lia";
import { LiaToggleOffSolid } from "react-icons/lia";
import { fetchOnlineStatus, fetchOutletInfo, restaurantStatusUpdate } from '../../API/Api';
import { format } from 'date-fns'; // Install date-fns for date formatting
import permissionStore from '../../components/PermissionStore';

function OutletOnline() {
    const [outletdata, setOutletdata] = useState({
        toggle: false,
        isOpen: false,
        viewDetailsClicked: false,
        restaurantId: localStorage.getItem("restaurantId"),
        outletName: '',
        imageSrc: '',
        address: "Loading...",
        status: '',
        isSigningAuthority: '',
    })

    useEffect(() => {
        const outletInfoFetch = async () => {
            try {
                const outletData = await fetchOutletInfo(outletdata.restaurantId)
                setOutletdata((prev) => ({
                    ...prev,
                    outletName: outletData.outletName,
                    address: `${outletData.addressLine1}, ${outletData.city}, ${outletData.country}`,
                    imageSrc: outletData.restaurantBanner,
                    isSigningAuthority: permissionStore.getPermission("isSigningAuthority")
                }))
            } catch (error) {
                throw error;
            }
        }

        const fetchStatus = async () => {
            try {
                const response = await fetchOnlineStatus(outletdata.restaurantId);
                if (response.status) {
                    setOutletdata((prev) => ({
                        ...prev,
                        status: response.status
                    }))
                }

            } catch (error) {
                throw error;
            }
        }  
        outletInfoFetch();
        fetchStatus();
    }, [])

    const handleSaveStatus = async () => {
        if (!outletdata.status) {
            alert("Please select a status");
            return;
        }

        let payload = {
            restaurantId: outletdata.restaurantId, // Replace with the actual ID
            newStatus: outletdata.status,
        };

        if (outletdata.status) {
            // Get current date and time from the system
            const now = new Date();
            const nextAvailableAt = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, "0")}-${String(
                now.getDate()
            ).padStart(2, "0")} ${String(now.getHours()).padStart(2, "0")}:${String(now.getMinutes()).padStart(2, "0")}:${String(
                now.getSeconds()
            ).padStart(2, "0")}`;

            payload.nextAvailableAt = nextAvailableAt;
        }

        try {
            const response = await restaurantStatusUpdate(payload);
            permissionStore.setPermissions(outletdata.status);
            setOutletdata((prev) => ({
                ...prev,
                isOpen: false,
            }))
        } catch (error) {
            console.error("Error updating status:", error.response.data.message);
            if (error.response.data.message === "You cannot set the store to online as it is permanently closed. Please contact support") {
                alert("Please contact support")
            }
        }
    };

    return (
        <div className='grid justify-items-center my-9'>
            <div className='w-[90%]'>
                <div >
                    <img src="/helpcenter_outlet.webp" className='' alt="" />
                </div>
                <p className='text-rose-600 text-lg px-6 my-3'>your outlet is currently off line Due to high number of rejections</p>
                <p className='text-[17px] px-6 '>Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio veniam maxime delectus, ullam a, accusantium cumque voluptate, consectetur odit minus dolores optio dolorum. Eaque pariatur suscipit impedit at, officiis nulla.</p>
                <button className='m-5 bg-green text-white p-2 rounded' type='button' onClick={() => setOutletdata(prev => ({
                    ...prev,
                    isOpen: !outletdata.isOpen
                }))}>Turn On The Outlet</button>
                <Modal isOpen={outletdata.isOpen} width={500} close={true} onClose={() => setOutletdata(prev => ({
                    ...prev,
                    isOpen: !outletdata.isOpen
                }))} >
                    <p className='font-semibold'>Manage Outlet Status</p>
                    <div className='flex justify-between mt-2'>
                        <p className='font-semibold'>Outlet Info</p>
                        <p className='font-semibold'>Delivery</p>
                    </div>
                    <div className="mt-4 border p-4 rounded shadow">
                        <div className="flex">
                            <div>
                                <img
                                    src={outletdata.imageSrc}
                                    width={150}
                                    height={150}
                                    className="rounded"
                                    alt={outletdata.outletName || "Outlet"}
                                />
                            </div>
                            <div className="px-4 space-y-2 text-gray-700">
                                <p className="font-bold text-lg">{outletdata.outletName}</p>
                                <p>{outletdata.address}</p>
                            </div>
                        </div>
                        <div className="flex items-center justify-evenly mt-4">
                            <label className="flex items-center">
                                <input
                                    type="radio"
                                    name="status"
                                    value="Online"
                                    checked={outletdata.status === "Online"}
                                    onChange={(e) => setOutletdata((prev) => ({
                                        ...prev,
                                        status: e.target.value,
                                    }))}
                                />
                                <span className="mx-2">Online</span>
                            </label>
                            <label className="flex items-center">
                                <input
                                    type="radio"
                                    name="status"
                                    value="Offline"
                                    checked={outletdata.status === "Offline"}
                                    onChange={(e) => setOutletdata((prev) => ({
                                        ...prev,
                                        status: e.target.value,
                                    }))}
                                />
                                <span className="mx-2">Offline</span>
                            </label>
                            <label className="flex items-center">
                                <input
                                    type="radio"
                                    name="status"
                                    value="Busy"
                                    checked={outletdata.status === "Busy"}
                                    onChange={(e) => setOutletdata((prev) => ({
                                        ...prev,
                                        status: e.target.value,
                                    }))}
                                />
                                <span className="mx-2">Busy</span>
                            </label>
                        </div>
                        {outletdata.isSigningAuthority ? (
                            <div className='mt-2 flex items-center justify-evenly'>
                                <label className="flex items-center">
                                    <input
                                        type="radio"
                                        name="status"
                                        value="TemporarilyClosed"
                                        checked={outletdata.status === "TemporarilyClosed"}
                                        onChange={(e) => setOutletdata((prev) => ({
                                            ...prev,
                                            status: e.target.value,
                                        }))}
                                    />
                                    <span className="mx-2">Temporarily Closed</span>
                                </label>
                                <label className="flex items-center">
                                    <input
                                        type="radio"
                                        name="status"
                                        value="PermanentlyClosed"
                                        checked={outletdata.status === "PermanentlyClosed"}
                                        onChange={(e) => setOutletdata((prev) => ({
                                            ...prev,
                                            status: e.target.value,
                                        }))}
                                    />
                                    <span className="mx-2">Permanently Closed</span>
                                </label>
                            </div>
                        ) : ""}
                        <div className="flex items-center justify-center mt-4">
                            <button
                                className="bg-green px-4 py-2 text-white rounded hover:bg-green-600"
                                onClick={handleSaveStatus}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default OutletOnline