import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Outlet.css';
import Aboutoutlet from './Aboutoutlet';
import ContactDetails from './ContactDetails';
import OutletTiming from './OutletTiming';
import Outletname from './Outletname';

const Outlet = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Map of active items to query parameters
  const queryMapping = {
    'About outlet': 'about',
    'Contact details': 'contact',
    'Outlet timing': 'timing',
    'Outlet name, address & location': 'name-address-location',
  };

  // Set the initial active item based on the query parameter
  const initialActiveItem = (() => {
    const query = new URLSearchParams(location.search).get('/');
    const matchedItem = Object.keys(queryMapping).find((key) => queryMapping[key] === query);
    return matchedItem || 'About outlet';
  })();

  const [activeItem, setActiveItem] = useState(initialActiveItem);

  // Update the URL query string without reloading the page
  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
    navigate(`/outlet?/${queryMapping[itemName]}`, { replace: true });
  };

  const renderContent = () => {
    switch (activeItem) {
      case 'About outlet':
        return <Aboutoutlet />;
      case 'Contact details':
        return <ContactDetails />;
      case 'Outlet timing':
        return <OutletTiming />;
      case 'Outlet name, address & location':
        return <Outletname />;
      default:
        return <Aboutoutlet />;
    }
  };
  return (
    <>
      <div className="outlet">
        <div className="container border-b-2 border-slate-300">
          <div className="row flex justify-between p-3">
            <h3 className="text-xl font-bold">Outlet Information</h3>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12">
        <ul className="col-span-3">
          <li
            onClick={() => handleItemClick('About outlet')}
            className={`flex justify-between items-center p-4 cursor-pointer border-r-4 ${activeItem === 'About outlet' ? 'bg-green text-white border-green' : 'border-transparent'
              }`}
          >
            About outlet <span className="ml-1 justify-end text-base">&gt;</span>
          </li>
          <li
            onClick={() => handleItemClick('Contact details')}
            className={`flex justify-between items-center p-4 cursor-pointer border-r-4 ${activeItem === 'Contact details' ? 'bg-green text-white border-green' : 'border-transparent'
              }`}
          >
            Contact details <span className="ml-1">&gt;</span>
          </li>
          <li
            onClick={() => handleItemClick('Outlet timing')}
            className={`flex justify-between items-center p-4 cursor-pointer border-r-4 ${activeItem === 'Outlet timing' ? 'bg-green text-white border-green' : 'border-transparent'
              }`}
          >
            Outlet timings <span className="ml-1">&gt;</span>
          </li>
          <li
            onClick={() => handleItemClick('Outlet name, address & location')}
            className={`flex justify-between items-center p-4 cursor-pointer border-r-4 ${activeItem === 'Outlet name, address & location' ? 'bg-green text-white border-green' : 'border-transparent'
              }`}
          >
            Outlet name, address & location <span className="ml-1">&gt;</span>
          </li>
        </ul>
        <div className="col-span-9 border-l-2 h-lvh border-gray-400">
          {renderContent()}
        </div>
      </div>
    </>
  )
}

export default Outlet