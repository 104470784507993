import React, { useEffect, useState } from 'react';
import { fetchMerchantUserPermissions } from '../API/Api';
import permissionStore from './PermissionStore';

function OrderMangement() {

  const [state, setState] = useState({
    permissions: '',
    errorMessage: '',
    restaurantId: localStorage.getItem("restaurantId"),
  });

  useEffect(() => {
    const getPermissions = async () => {
      try {
        const data = await fetchMerchantUserPermissions(state.restaurantId); // Call the service to fetch permissions
        setState(prevState => ({
          ...prevState,
          permissions: data
        }));

        permissionStore.setPermissions(data);

      } catch (error) {
        setState(prevState => ({
          ...prevState,
          errorMessage: error.message
        }));
      }
    };

    getPermissions(); // Call the function inside useEffect
  }, []);


  // State for online/offline toggle
  const [isOnline, setIsOnline] = useState(true);

  // const alarmSound = new Audio("/sounds/alarm.mp3"); // Local path in your project


  // State for active tab
  const [activeTab, setActiveTab] = useState("running");

  // State for orders and popup
  const [orders, setOrders] = useState([]); // Orders list
  const [popupOrder, setPopupOrder] = useState(null); // Popup for new order
  const [orderReadyTime, setOrderReadyTime] = useState(10); // Default ready time

  // Mock data for running, completed, and dispatched orders
  const runningOrders = [
    {
      customerName: "Bhuvanesan S",
      orderNumber: "#12345",
      orderId: "ORD001",
      preparationTimeLeft: "15:30",
      pickedUpOrders: "2",
    },
    {
      customerName: "Koushik Raj",
      orderNumber: "#12346",
      orderId: "ORD002",
      preparationTimeLeft: "10:20",
      pickedUpOrders: "1",
    },
  ];

  const completedOrders = [
    { pickedUpOrders: "3", details: "Completed on 2024-11-20" },
    { pickedUpOrders: "5", details: "Completed on 2024-11-19" },
  ];

  const dispatchedOrders = [
    { deliveredOrders: "4", details: "Delivered on 2024-11-18" },
    { deliveredOrders: "2", details: "Delivered on 2024-11-17" },
  ];

  // Simulate new order arrival
  useEffect(() => {
    const timer = setTimeout(() => {
      const newOrder = {
        id: orders.length + 1,
        itemName: "Pizza Margherita",
        orderValue: 599,
        packagingCharge: 50,
      };
      setOrders((prevOrders) => [...prevOrders, newOrder]);
      playAlarm(); // Play alarm when the order arrives
      setPopupOrder(newOrder); // Open the popup for the new order
    }, 5000); // Order arrives in 5 seconds
    return () => clearTimeout(timer);
  }, [orders]);

  // // Play alarm sound
  // const playAlarm = () => {
  //   alarmSound.play();
  // };

  // Close popup
  const closePopup = () => {
    setPopupOrder(null);
  };

  // Increment ready time
  const incrementReadyTime = () => {
    setOrderReadyTime((prevTime) => prevTime + 1);
  };

  // Decrement ready time
  const decrementReadyTime = () => {
    if (orderReadyTime > 1) {
      setOrderReadyTime((prevTime) => prevTime - 1);
    }
  };


  const alarmSound = new Audio(
    "https://www.soundjay.com/button/sounds/button-09.mp3" // Replace with your working sound URL
  );
  alarmSound.load(); // Preload the audio

  const playAlarm = () => {
    if (alarmSound.canPlayType("audio/mpeg")) {
      alarmSound.play().catch((error) => console.error("Audio playback failed:", error));
    } else {
      console.error("Audio format not supported in this browser.");
    }
  };




  return (
    <div className="container mx-auto p-6">
      {/* Online/Offline Toggle */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold text-gray-700">Order Management</h1>
        <button
          className={`px-4 py-2 rounded ${isOnline ? "bg-green text-white" : "bg-red text-white"
            }`}
          onClick={() => setIsOnline(!isOnline)}
        >
          {isOnline ? "Online Since" : "Offline Since"}
        </button>
      </div>

      {/* Tabs */}
      <div className="tabs mb-4 border-b border-gray-200">
        <button
          className={`px-4 py-2 ${activeTab === "running" ? "border-b-2 border-green" : ""
            }`}
          onClick={() => setActiveTab("running")}
        >
          Running Orders
        </button>
        <button
          className={`px-4 py-2 ${activeTab === "completed" ? "border-b-2 border-green" : ""
            }`}
          onClick={() => setActiveTab("completed")}
        >
          Picked Up Orders
        </button>
        <button
          className={`px-4 py-2 ${activeTab === "dispatched" ? "border-b-2 border-blue-500" : ""
            }`}
          onClick={() => setActiveTab("dispatched")}
        >
          Delivered Orders
        </button>
      </div>

      {/* Tab Content */}
      <div>
        {activeTab === "running" && (
          <div>
            <h2 className="text-xl font-medium text-gray-700 mb-4">
              Running Orders
            </h2>
            <table className="min-w-full bg-white border border-gray-200">
              <thead>
                <tr>
                  <th className="px-4 py-2 border-b">Customer Name</th>
                  <th className="px-4 py-2 border-b">Order Number</th>
                  <th className="px-4 py-2 border-b">Order ID</th>
                  <th className="px-4 py-2 border-b">Preparation Time Left</th>
                  <th className="px-4 py-2 border-b">Picked Up Orders</th>
                </tr>
              </thead>
              <tbody>
                {runningOrders.map((order, index) => (
                  <tr key={index}>
                    <td className="px-4 py-2 border-b">{order.customerName}</td>
                    <td className="px-4 py-2 border-b">{order.orderNumber}</td>
                    <td className="px-4 py-2 border-b">{order.orderId}</td>
                    <td className="px-4 py-2 border-b">
                      {order.preparationTimeLeft}
                    </td>
                    <td className="px-4 py-2 border-b">{order.pickedUpOrders}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {activeTab === "completed" && (
          <div>
            <h2 className="text-xl font-medium text-gray-700 mb-4">
              Picked Up Orders
            </h2>
            <table className="min-w-full bg-white border border-gray-200">
              <thead>
                <tr>
                  <th className="px-4 py-2 border-b">Picked Up Orders</th>
                  <th className="px-4 py-2 border-b">Details</th>
                </tr>
              </thead>
              <tbody>
                {completedOrders.map((order, index) => (
                  <tr key={index}>
                    <td className="px-4 py-2 border-b">{order.pickedUpOrders}</td>
                    <td className="px-4 py-2 border-b">{order.details}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {activeTab === "dispatched" && (
          <div>
            <h2 className="text-xl font-medium text-gray-700 mb-4">
              Delivered Orders
            </h2>
            <table className="min-w-full bg-white border border-gray-200">
              <thead>
                <tr>
                  <th className="px-4 py-2 border-b">Delivered Orders</th>
                  <th className="px-4 py-2 border-b">Details</th>
                </tr>
              </thead>
              <tbody>
                {dispatchedOrders.map((order, index) => (
                  <tr key={index}>
                    <td className="px-4 py-2 border-b">{order.deliveredOrders}</td>
                    <td className="px-4 py-2 border-b">{order.details}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* Popup for New Order
      {popupOrder && (

        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg shadow-2xl w-96">
            <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center border-b pb-2">
              Order Details
            </h2>
            <form>
              <div className="mb-4">
                <label className="block text-gray-600 font-medium mb-1">
                  <strong>Item Name:</strong>
                </label>
                <div className="p-3 border border-gray-300 rounded bg-gray-100 text-gray-800">
                  {popupOrder.itemName}
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-gray-600 font-medium mb-1">
                  <strong>Order Value:</strong>
                </label>
                <div className="p-3 border border-gray-300 rounded bg-gray-100 text-gray-800">
                  ₹{popupOrder.orderValue}
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-gray-600 font-medium mb-1">
                  <strong>Packaging Charge:</strong>
                </label>
                <div className="p-3 border border-gray-300 rounded bg-gray-100 text-gray-800">
                  ₹{popupOrder.packagingCharge}
                </div>
              </div>
              <div className="mb-6">
                <label className="block text-gray-600 font-medium mb-1">
                  <strong>Order Ready Time:</strong>
                </label>
                <div className="flex items-center gap-4">
                  <button
                    className="bg-gray-300 text-gray-800 px-4 py-2 rounded shadow hover:bg-gray-400"
                    onClick={decrementReadyTime}
                    type="button"
                  >
                    -
                  </button>
                  <div className="px-6 py-2 border border-gray-300 rounded bg-gray-100 text-center text-gray-800">
                    {orderReadyTime} mins
                  </div>
                  <button
                    className="bg-gray-300 text-gray-800 px-4 py-2 rounded shadow hover:bg-gray-400"
                    onClick={incrementReadyTime}
                    type="button"
                  >
                    +
                  </button>
                </div>
              </div>
              <div className="flex justify-end">
                <button
                  className="bg-red text-white px-6 py-2 rounded shadow hover:bg-red-600"
                  onClick={closePopup}
                  type="button"
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>

      )} */}

      
    </div>
  )
}

export default OrderMangement
