import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faEdit, faStar, faTimes, faMapMarkerAlt, faCaretRight, faMicrophone, faIdBadge } from '@fortawesome/free-solid-svg-icons';
import './Outlet.css';
import { fetchOutletInfo } from "../API/Api";

const Aboutoutlet = () => {
    
    const [state, setState] = useState({
        imageSrc: '',
        isEditing: false,
        showPopup: false,
        restaurantId: localStorage.getItem("restaurantId"),
        outletName: "Loading...", // Placeholder until data is loaded
        ratingCount:'',
        totalReviews:'',
        address: "Loading...", // Placeholder address
        mapLocation: "", // Will be constructed from latitude and longitude
        activeSince: "23 Oct 2020", // Static placeholder; update if available in API
    });

    // Fetch outlet information on component mount
    useEffect(() => {
        const loadOutletInfo = async () => {
            try {
                const outletData = await fetchOutletInfo(state.restaurantId);
                setState((prevState) => ({
                    ...prevState,
                    outletName: outletData.outletName,
                    address: `${outletData.addressLine1}, ${outletData.addressLine2}, ${outletData.addressLine3}, ${outletData.city}, ${outletData.country}`,
                    mapLocation: `https://www.google.com/maps/search/?api=1&query=${outletData.latitude},${outletData.longitude}`,
                    imageSrc: outletData.restaurantBanner,
                    totalReviews: outletData.totalReviews,
                    ratingCount: outletData.ratingCount,
                }));
            } catch (error) {
                console.error("Error loading outlet info:", error);
            }
        };
        loadOutletInfo();
    }, [state.restaurantId]);

    const handleEditClick = () => {
        setState((prevState) => ({
            ...prevState,
            isEditing: true,
        }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setState((prevState) => ({
                    ...prevState,
                    imageSrc: reader.result,
                    isEditing: false,
                }));
            };
            reader.readAsDataURL(file);
        }
    };
    
    const handleRejectClick = () => {
        alert("Image has been rejected!");
    };

    const handleReject = () => {
        setState((prevState) => ({
            ...prevState,
            showPopup: true,
        }));
    };

    const handleClose = () => {
        setState((prevState) => ({
            ...prevState,
            showPopup: false,
        }));
    };

    return (
        <>
            <div className="relative">
                <img src={state.imageSrc} alt="Uploaded" className="w-full h-72" />
                <button className="absolute top-0 right-0 m-2 p-2 bg-white text-black border border-gray-300 rounded" onClick={handleEditClick}>Edit Image</button>
                {state.isEditing && (
                    <div className="absolute inset-0 flex flex-col items-center justify-center bg-white bg-opacity-75">
                        <input type="file" accept="image/*" onChange={handleImageChange} className="mb-4" />
                        <button className="p-2 bg-white text-black border border-gray-300 rounded" onClick={() => setState((prevState) => ({ ...prevState, isEditing: false }))}> Close</button>
                    </div>
                )}
                <div className="absolute bottom-0 left-0 right-0 flex justify-between items-center p-2">
                    <label htmlFor="file-upload" className="cursor-pointer flex flex-col items-center p-2 text-sky-700 bg-white border border-gray-300 rounded">
                        <FontAwesomeIcon icon={faCamera} className="mb-3" />
                        Upload Image
                    </label>
                    <input id="file-upload" type="file" accept="image/*" onChange={handleImageChange} className="hidden" />
                    <button className="p-2 bg-red text-white rounded" onClick={handleRejectClick}>Rejected</button>
                </div>
            </div>
            <div className="grid grid-cols-2 items-center justify-between px-4 mt-2">
                <div className="flex items-center">
                    <h3 className="text-base font-bold mr-2">{state.outletName}</h3>
                    <FontAwesomeIcon icon={faEdit} className="text-sky-700 cursor-pointer mx-3" />
                </div>
                <div className="flex justify-end gap-5 items-center">
                    <button className="bg-green text-white px-1 py-1 rounded mr-2">
                        <FontAwesomeIcon icon={faStar} className="mr-2" />
                        {state.totalReviews}
                    </button>
                    <span className="text-gray-700 text-sm">{state.deliveryReviewCount} Total Review</span>
                </div>
            </div>
            <div className="grid grid-cols-2 items-center justify-between px-4 mt-1">
                <div className="flex items-center">
                    <p className="text-gray-700 text-sm">{state.address}</p>
                    <FontAwesomeIcon icon={faEdit} className="text-sky-700 cursor-pointer mx-3" />
                </div>
                <div className="flex justify-end gap-8 items-center">
                    <button className="bg-gray-500 text-white px-1 py-1 rounded mr-2">
                        <FontAwesomeIcon icon={faStar} className="mr-2" />
                        {state.ratingCount}
                    </button>
                    <span className="text-gray-700 text-sm">Rating</span>
                </div>
            </div>

            <button className="px-3 mx-4 mt-2 py-1 bg-red text-white rounded mb-2" onClick={handleReject}>Rejected</button>
            {state.showPopup && (
                <div className="fixed inset-0 flex items-center justify-center bg-slate-900 opacity-90 index">
                    <div className="bg-white p-4 rounded shadow-lg w-96">
                        <div className="flex justify-between items-center mb-4 border-b-2 border-gray-400 pb-2 w-full">
                            <h2 className="text-base font-bold">Cuisine Rejected</h2>
                            <FontAwesomeIcon icon={faTimes} className="text-gray-700 cursor-pointer" onClick={handleClose} />
                        </div>
                        <p className='leading-4'>Comments: Restaurant is not serving the selected cuisine</p>
                    </div>
                </div>
            )}
            <div className='border-gray-300 border-b-2 border-t-2 px-4 pb-3 pt-3'>
                <div className='flex justify-between mt-2 mb-2'>
                    <div>
                        <FontAwesomeIcon icon={faMapMarkerAlt} className="text-black text-sm mr-2" />
                        <span className="text-black text-sm">Address: {state.address}</span>
                        <FontAwesomeIcon icon={faEdit} className="text-sky-700 cursor-pointer mx-3" />
                    </div>
                    <div>
                        <a href={state.mapLocation} target="_blank" rel="noopener noreferrer" className="text-blue-500 text-sm">
                            View On Map
                            <FontAwesomeIcon icon={faCaretRight} className="ml-1 text-sm" />
                        </a>
                    </div>
                </div>
            </div>
            <div className='border-gray-300 border-b-2 px-4'>
                <div className='mt-2 mb-2'>
                    <div className="flex flex-col mt-1">
                        <div className="flex items-center">
                            <FontAwesomeIcon icon={faMicrophone} className="text-gray-700 mr-2" />
                            <span className="text-gray-700">Record Pickup instruction (Please allow microphone access)</span>
                        </div>
                        <p className="text-gray-700 mt-2 leading-5 text-xs">Help the delivery partner to reach your location easily.</p>
                        <a href="https://support.example.com/microphone-access" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline mt-1 text-sm">
                            How to allow Microphone access
                        </a>
                    </div>
                </div>
            </div>
            <div className="flex items-center mt-3 pb-3 px-4">
                <FontAwesomeIcon icon={faIdBadge} className="text-black mr-2" />
                <span className="text-black">Restaurant ID: {state.restaurantId} | Active since: {state.activeSince}</span>
            </div>
        </>
    );
}

export default Aboutoutlet;
