// import React, { useEffect, useState } from 'react';
// import { listPortalUsers, listCustomRoles, reAssociateUser } from '../../API/Api';

// const ReAssociateUser = () => {
//     const [state, setState] = useState({
//         restaurantId: localStorage.getItem("restaurantId"),
//         users: [],
//         roles: [],
//         selectedUserId: "",
//         selectedRoleId: "",
//     });

//     useEffect(() => {
//         // Fetch users and roles from API
//         const fetchData = async () => {
//             try {
//                 const usersResponse = await listPortalUsers(state.restaurantId);
//                 const rolesResponse = await listCustomRoles(state.restaurantId);
                
//                 setState((prevState) => ({
//                     ...prevState,
//                     users: usersResponse || [],
//                     roles: rolesResponse || [],
//                 }));
//             } catch (error) {
//                 console.error("Error fetching data:", error);
//             }
//         };

//         fetchData();
//     }, [state.restaurantId]);

//     // Handle changes in user selection
//     const handleUserChange = (e) => {
//         setState((prevState) => ({
//             ...prevState,
//             selectedUserId: e.target.value,
//         }));
//     };

//     // Handle changes in role selection
//     const handleRoleChange = (e) => {
//         setState((prevState) => ({
//             ...prevState,
//             selectedRoleId: e.target.value,
//         }));
//     };

//     // Handle the Re-associate button click
//     const handleReAssociate = async () => {
//         const payload = {
//             restaurantId: state.restaurantId,
//             roleType: "custom",
//             roleId: state.selectedRoleId,
//             userId: state.selectedUserId,
//         };

//         try {
//             const response = await reAssociateUser(payload);
//             console.log("User re-associated successfully:", response);
//         } catch (error) {
//             console.error("Error re-associating user:", error);
//         }
//     };

//     return (
//         <div className="p-4 max-w-md mx-auto bg-white rounded-lg shadow-md">
//             <h2 className="text-xl font-semibold mb-4">Re-Associate User</h2>
            
//             {/* User Dropdown */}
//             <div className="mb-4">
//                 <label className="block text-gray-700 font-medium mb-2">Select User</label>
//                 <select
//                     value={state.selectedUserId}
//                     onChange={handleUserChange}
//                     className="w-full px-3 py-2 border border-gray-300 rounded-md"
//                 >
//                     <option value="">Select a User</option>
//                     {state.users.map((user) => (
//                         <option key={user.userId} className='w-3/4' value={user.userId}>
//                             {user.name || 'No Name'}
//                         </option>
//                     ))}
//                 </select>
//             </div>

//             {/* Role Dropdown */}
//             <div className="mb-4">
//                 <label className="block text-gray-700 font-medium mb-2">Select Role</label>
//                 <select
//                     value={state.selectedRoleId}
//                     onChange={handleRoleChange}
//                     className="w-full px-3 py-2 border border-gray-300 rounded-md"
//                 >
//                     <option value="">Select a Role</option>
//                     {state.roles.map((role) => (
//                         <option key={role.roleId} value={role.roleId}>
//                             {role.roleName}
//                         </option>
//                     ))}
//                 </select>
//             </div>

//             {/* Re-associate Button */}
//             <button
//                 onClick={handleReAssociate}
//                 className="w-full py-2 px-4 bg-green text-white font-semibold rounded-md hover:bg-green"
//                 disabled={!state.selectedUserId || !state.selectedRoleId}
//             >
//                 Re-associate User
//             </button>
//         </div>
//     );
// };

// export default ReAssociateUser;




import React, { useEffect, useState } from 'react';
import { listPortalUsers, listCustomRoles, listPredefinedRoles, reAssociateUser } from '../../API/Api';

const ReAssociateUser = () => {
    const [state, setState] = useState({
        restaurantId: localStorage.getItem("restaurantId"),
        users: [],
        roles: [],
        selectedUserId: "",
        selectedRoleId: "",
        roleType: "custom", // default role type
    });

    useEffect(() => {
        // Fetch users and roles based on role type
        const fetchData = async () => {
            try {
                const usersResponse = await listPortalUsers(state.restaurantId);

                // Fetch roles based on selected role type
                let rolesResponse = [];
                if (state.roleType === "custom") {
                    rolesResponse = await listCustomRoles(state.restaurantId);
                } else {
                    rolesResponse = await listPredefinedRoles(state.restaurantId);
                }

                setState((prevState) => ({
                    ...prevState,
                    users: usersResponse || [],
                    roles: rolesResponse || [],
                }));
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [state.restaurantId, state.roleType]);

    // Handle changes in user selection
    const handleUserChange = (e) => {
        setState((prevState) => ({
            ...prevState,
            selectedUserId: e.target.value,
        }));
    };

    // Handle changes in role selection
    const handleRoleChange = (e) => {
        setState((prevState) => ({
            ...prevState,
            selectedRoleId: e.target.value,
        }));
    };

    // Handle role type toggle
    const handleRoleTypeChange = (e) => {
        const selectedType = e.target.value;
        setState((prevState) => ({
            ...prevState,
            roleType: selectedType,
            selectedRoleId: "", // Reset role selection when role type changes
        }));
    };

    // Handle the Re-associate button click
    const handleReAssociate = async () => {
        const payload = {
            restaurantId: state.restaurantId,
            roleType: state.roleType,
            roleId: state.selectedRoleId,
            userId: state.selectedUserId,
        };

        try {
            const response = await reAssociateUser(payload);
            console.log("User re-associated successfully:", response);
        } catch (error) {
            console.error("Error re-associating user:", error);
        }
    };

    return (
        <div className="p-4 max-w-md mx-auto bg-white rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">Re-Associate User</h2>
            
            {/* User Dropdown */}
            <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-2">Select User</label>
                <select
                    value={state.selectedUserId}
                    onChange={handleUserChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                >
                    <option value="">Select a User</option>
                    {state.users.map((user) => (
                        <option key={user.userId} value={user.userId}>
                            {user.name || 'No Name'}
                        </option>
                    ))}
                </select>
            </div>

            {/* Role Type Toggle */}
            <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-2">Select Role Type</label>
                <div className="flex items-center space-x-4">
                    <label className="flex items-center">
                        <input
                            type="radio"
                            value="custom"
                            checked={state.roleType === "custom"}
                            onChange={handleRoleTypeChange}
                            className="mr-2"
                        />
                        Custom Role
                    </label>
                    <label className="flex items-center">
                        <input
                            type="radio"
                            value="predefined"
                            checked={state.roleType === "predefined"}
                            onChange={handleRoleTypeChange}
                            className="mr-2"
                        />
                        Predefined Role
                    </label>
                </div>
            </div>

            {/* Role Dropdown */}
            <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-2">Select Role</label>
                <select
                    value={state.selectedRoleId}
                    onChange={handleRoleChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                >
                    <option value="">Select a Role</option>
                    {state.roles.map((role) => (
                        <option key={role.roleId} value={role.roleId}>
                            {role.roleName}
                        </option>
                    ))}
                </select>
            </div>

            {/* Re-associate Button */}
            <button
                onClick={handleReAssociate}
                className="w-full py-2 px-4 bg-green text-white font-semibold rounded-md hover:bg-green"
                disabled={!state.selectedUserId || !state.selectedRoleId}
            >
                Re-associate User
            </button>
        </div>
    );
};

export default ReAssociateUser;
