
import React, { useState, useRef, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { createCampaign } from '../API/Api';

const CreateNewOffer = ({ show, onHide }) => {
  const [formData, setFormData] = useState({
    campaignStartDate: '',
    campaignEndDate: '',
    discountTypeCode: 0, // Default to Percentage Discount
    discountWindows: [{ campaign_day_code: 0, discount_time_windows: [{ start_time: '', end_time: '' }] }],
    selectedOffer: { discount_type_code: 0 }, // Default set to avoid null issues
    couponCode: '',
    nestedDiscountType: null, // For custom coupon discount
    minimumOrderValue: 100,
    percentage: 10,
    maxDiscountValue: 50,
    discountValue: 50,
    couponName: '',
    isFlat: false,
    isMaxRadius: false,
    maxDiscountRadius: 0,
    radius: 0,
    offerItems: [],
    maxItems: 1,
    discountItem: 0,
    maxPairs: 1,
    predefinedCouponCode: ''
  });

  const offerCanvasRef = useRef(null);

  const closeOffCanvas = () => {
    setFormData(prevData => ({
      ...prevData,
      selectedOffer: { discount_type_code: 0 } // Reset the selectedOffer
    }));
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (offerCanvasRef.current && !offerCanvasRef.current.contains(event.target)) {
        onHide();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onHide]);

  const handleDiscountTypeChange = (e) => {
    const selectedType = parseInt(e.target.value);
    setFormData((prevData) => ({
      ...prevData,
      discountTypeCode: selectedType,
      nestedDiscountType: selectedType
    }));
  };
  const handleDiscountWindowChange = (index, timeIndex, field, value) => {
    const updatedWindows = [...formData.discountWindows];
    updatedWindows[index].discount_time_windows[timeIndex][field] = value;
    setFormData((prevData) => ({
      ...prevData,
      discountWindows: updatedWindows
    }));
  };

  const user = sessionStorage.getItem("userId");
  const restaurantId = sessionStorage.getItem("restaurantId");

  const handleActivateOffer = async () => {
    try {
      let discountDetails = {
        discount_type_code: formData.discountTypeCode,
        discount_description: {},
      };

      switch (formData.discountTypeCode) {
        case 0: // Percentage Discount
          discountDetails.discount_description = {
            percentage: formData.percentage,
            minimum_order_value: formData.minimumOrderValue,
            max_discount_value: formData.maxDiscountValue,
            ineligible_cart_item: ["cly8cluxz1wc4nn2gnho4nu0d"]
          };
          break;
        case 1: // Flat Discount
          discountDetails.discount_description = {
            minimum_order_value: formData.minimumOrderValue,
            discount_value: formData.discountValue,
            ineligible_cart_item: ["cly8cluxz1wc4nn2gnho4nu0d"]
          };
          break;
        case 2: // Flat Percentage Discount
          discountDetails.discount_description = {
            percentage: formData.percentage,
            minimum_order_value: formData.minimumOrderValue,
            ineligible_cart_item: ["cly8cluxz1wc4nn2gnho4nu0d"]
          };
          break;
        case 3: // Predefined Discount
          discountDetails.discount_description = {
            predefined_coupon_code: formData.predefinedCouponCode,
            coupon_name: formData.couponName,
            coupon_code: formData.couponCode,
            ineligible_cart_item: ["cly8cluxz1wc4nn2gnho4nu0d"]
          };
          break;
        case 4: // Delivery Discount
          discountDetails.discount_description = {
            is_flat: formData.isFlat,
            is_max_radius: formData.isMaxRadius,
            max_discount_value: formData.maxDiscountRadius,
            minimum_order_value: formData.minimumOrderValue,
            radius: formData.radius,
            ineligible_cart_item: ["cly8cluxz1wc4nn2gnho4nu0d"]
          };
          break;
        case 5: // Custom Coupon Discount
          discountDetails.discount_description = {
            coupon_code: formData.couponCode,
            discount_info: {
              discount_type_code: formData.nestedDiscountType,
              discount_description: {
                percentage: formData.percentage,
                minimum_order_value: formData.minimumOrderValue,
                max_discount_value: formData.maxDiscountValue,
                ineligible_cart_item: ["cly8cluxz1wc4nn2gnho4nu0d"]
              }
            }
          };
          break;
        case 6: // Freebie Discount
          discountDetails.discount_description = {
            minimum_order_value: formData.minimumOrderValue,
            offer_items: formData.offerItems,
            max_items: formData.maxItems,
            ineligible_cart_item: ["cly8cluxz1wc4nn2gnho4nu0d"]
          };
          break;
        case 7: // BOGO Discount
          discountDetails.discount_description = {
            applicable_items: formData.offerItems,
            discount_item: formData.discountItem,
            minimum_order_value: formData.minimumOrderValue,
            max_pairs: formData.maxPairs,
            ineligible_cart_item: ["cly8cluxz1wc4nn2gnho4nu0d"]
          };
          break;
        default:
          console.error("Invalid discount type");
          return;
      }

      const payload = {
        campaign_start_date: formData.campaignStartDate,
        campaign_end_date: formData.campaignEndDate,
        created_by: user,
        user_code: 0, // User type can be set dynamically
        max_redumptions_per_user: 1,
        redeem_every: 2, // Example for redeem time window
        res_id: restaurantId,
        discount_window: formData.discountWindows,
        discount_details: discountDetails
      };

      const response = await createCampaign(payload);
      console.log('Campaign created:', response);
    } catch (error) {
      console.error('Error creating campaign:', error);
    }
  };

  return (
    <div>
      <div className={`fixed inset-0 z-50 offer ${show ? 'block' : 'hidden'}`} style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
        <div ref={offerCanvasRef} className="fixed right-0 top-0 h-full overflow-y-auto bg-white w-1/3 p-4 shadow-lg">
          <div className="flex justify-between items-center mb-2">
            <h5 className="text-lg font-bold">Create New Offer</h5>
            <button type="button" className="text-gray-600" onClick={onHide}><FaTimes /></button>
          </div>
          <div className="flex flex-col gap-4">
            <label htmlFor='startDate' className="block text-sm font-semibold leading-6 text-gray-900">Campaign Start Date</label>
            <div className="mt-2">
              <input type='date' id='startDate' value={formData.campaignStartDate} onChange={(e) => setFormData((prev) => ({ ...prev, campaignStartDate: e.target.value }))} className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900" />
            </div>

            <label htmlFor='endDate' className="block text-sm font-semibold leading-6 text-gray-900">Campaign End Date</label>
            <div className="mt-2">
              <input type='date' id='endDate' value={formData.campaignEndDate} onChange={(e) => setFormData((prev) => ({ ...prev, campaignEndDate: e.target.value }))} className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900" />
            </div>

            <p className='text-sm text-gray-500 mb-4 mt-3 pb-3 border-b-4 border-gray-300'>
              Your offer will start on {formData.campaignStartDate} and end on {formData.campaignEndDate}.
            </p>

            {/* Discount type selection */}
            <label htmlFor="discountType" className="block text-sm font-semibold">Select Discount Type:</label>
            <select value={formData.discountTypeCode} onChange={handleDiscountTypeChange} className="mt-1 block w-full">
              <option value={0}>Percentage Discount</option>
              <option value={1}>Flat Discount</option>
              <option value={2}>Flat Percentage Discount</option>
              <option value={3}>Predefined Discount</option>
              <option value={4}>Delivery Discount</option>
              <option value={5}>Custom Coupon Discount</option>
              <option value={6}>Freebie Discount</option>
              <option value={7}>BOGO Discount</option>
            </select>

            {/* Input fields based on discount type */}
            {formData.discountTypeCode === 0 && (
              <div>
                <label htmlFor="percentage" className="block text-sm font-semibold">Percentage:</label>
                <input type="number" value={formData.percentage} onChange={(e) => setFormData((prev) => ({ ...prev, percentage: Number(e.target.value) }))} className="mt-1 block w-full" />
                <label htmlFor="minimumOrderValue" className="block text-sm font-semibold">Minimum Order Value:</label>
                <input type="number" value={formData.minimumOrderValue} onChange={(e) => setFormData((prev) => ({ ...prev, minimumOrderValue: Number(e.target.value) }))} className="mt-1 block w-full" />
                <label htmlFor="maxDiscountValue" className="block text-sm font-semibold">Max Discount Value:</label>
                <input type="number" value={formData.maxDiscountValue} onChange={(e) => setFormData((prev) => ({ ...prev, maxDiscountValue: Number(e.target.value) }))} className="mt-1 block w-full" />
              </div>
            )}

            {/* Case 1: Flat Discount */}
            {formData.discountTypeCode === 1 && (
              <div className='mb-4'>
                <label htmlFor="minimumOrderValue" className="block text-sm font-semibold">Minimum Order Value:</label>
                <input type="number" value={formData.minimumOrderValue} onChange={(e) => setFormData((prev) => ({ ...prev, minimumOrderValue: Number(e.target.value) }))} className="mt-1 block w-full" />
                <label htmlFor="discountValue" className="block text-sm font-semibold">Discount Value:</label>
                <input type="number" value={formData.discountValue} onChange={(e) => setFormData((prev) => ({ ...prev, discountValue: Number(e.target.value) }))} className="mt-1 block w-full" />
              </div>
            )}

            {/* Case 2: Flat Percentage Discount */}
            {formData.discountTypeCode === 2 && (
              <div className='mb-4'>
                <label htmlFor="percentage" className="block text-sm font-semibold">Percentage:</label>
                <input type="number" value={formData.percentage} onChange={(e) => setFormData((prev) => ({ ...prev, percentage: Number(e.target.value) }))} className="mt-1 block w-full" />
                <label htmlFor="minimumOrderValue" className="block text-sm font-semibold">Minimum Order Value:</label>
                <input type="number" value={formData.minimumOrderValue} onChange={(e) => setFormData((prev) => ({ ...prev, minimumOrderValue: Number(e.target.value) }))} className="mt-1 block w-full" />
              </div>
            )}

            {/* Case 3: Predefined Discount */}
            {formData.discountTypeCode === 3 && (
              <div className='mb-4'>
                <label htmlFor="predefinedCouponCode" className="block text-sm font-semibold">Predefined Coupon Code:</label>
                <input type="text" value={formData.predefinedCouponCode} onChange={(e) => setFormData((prev) => ({ ...prev, predefinedCouponCode: Number(e.target.value) }))} className="mt-1 block w-full" />
                <label htmlFor="couponName" className="block text-sm font-semibold">Coupon Name:</label>
                <input type="text" value={formData.couponName} onChange={(e) => setFormData((prev) => ({ ...prev, couponName: Number(e.target.value) }))} className="mt-1 block w-full" />
                <label htmlFor="couponCode" className="block text-sm font-semibold">Coupon Code:</label>
                <input type="text" value={formData.couponCode} onChange={(e) => setFormData((prev) => ({ ...prev, couponCode: Number(e.target.value) }))} className="mt-1 block w-full" />
              </div>
            )}

            {/* Case 4: Delivery Discount */}
            {formData.discountTypeCode === 4 && (
              <div className='mb-4'>
                <label htmlFor="minimumOrderValue" className="block text-sm font-semibold">Minimum Order Value:</label>
                <input type="number" value={formData.minimumOrderValue} onChange={(e) => setFormData((prev) => ({ ...prev, minimumOrderValue: Number(e.target.value) }))} className="mt-1 block w-full" />
                <label htmlFor="maxDiscountRadius" className="block text-sm font-semibold">Max Discount Value:</label>
                <input type="number" value={formData.maxDiscountRadius} onChange={(e) => setFormData((prev) => ({ ...prev, maxDiscountRadius: Number(e.target.value) }))} className="mt-1 block w-full" />
                <label htmlFor="radius" className="block text-sm font-semibold">Radius (in meters):</label>
                <input type="number" value={formData.radius} onChange={(e) => setFormData((prev) => ({ ...prev, radius: Number(e.target.value) }))} className="mt-1 block w-full" />
                <div className="mt-2">
                  <label htmlFor="isFlat" className="block text-sm font-semibold">Is Flat:</label>
                  <input type="checkbox" checked={formData.isFlat} onChange={(e) => setFormData((prev) => ({ ...prev, isFlat: Number(e.target.checked) }))} />
                </div>
                <div className="mt-2">
                  <label htmlFor="isMaxRadius" className="block text-sm font-semibold">Is Max Radius:</label>
                  <input type="checkbox" checked={formData.isMaxRadius} onChange={(e) => setFormData((prev) => ({ ...prev, isMaxRadius: Number(e.target.checked) }))} />
                </div>
              </div>
            )}

            {/* Custom Coupon Discount */}
            {formData.discountTypeCode === 5 && (
              <>
                <div className="mb-4">
                  <label htmlFor="couponCode" className="block text-sm font-semibold">Coupon Code:</label>
                  <input type="text" value={formData.couponCode} onChange={(e) => setFormData((prev) => ({ ...prev, couponCode: Number(e.target.value) }))} className="mt-1 block w-full" />
                </div>

                <div className="mb-4">
                  <label htmlFor="nestedDiscountType" className="block text-sm font-semibold">Select Discount Type:</label>
                  <select value={formData.nestedDiscountType} onChange={(e) => setFormData((prev) => ({ ...prev, nestedDiscountType: parseInt(e.target.value), }))} className="mt-1 block w-full">
                    <option value="">Select Discount Type</option>
                    <option value="0">Percentage Discount</option>
                    <option value="1">Flat Discount</option>
                    <option value="2">Flat Percentage Discount</option>
                    <option value="3">Predefined Discount</option>
                    <option value="4">Delivery Discount</option>
                    <option value="6">Freebie Discount</option>
                    <option value="7">BOGO Discount</option>
                  </select>
                </div>

                {/* Show input fields based on nested discount type */}
                {formData.nestedDiscountType === 0 && (
                  <div className='mb-4'>
                    <label htmlFor="percentage" className="block text-sm font-semibold">Percentage:</label>
                    <input type="number" value={formData.percentage} onChange={(e) => setFormData((prev) => ({ ...prev, percentage: Number(e.target.value) }))} className="mt-1 block w-full" />
                    <label htmlFor="minimumOrderValue" className="block text-sm font-semibold">Minimum Order Value:</label>
                    <input type="number" value={formData.minimumOrderValue} onChange={(e) => setFormData((prev) => ({ ...prev, minimumOrderValue: Number(e.target.value) }))} className="mt-1 block w-full" />
                    <label htmlFor="maxDiscountValue" className="block text-sm font-semibold">Max Discount Value:</label>
                    <input type="number" value={formData.maxDiscountValue} onChange={(e) => setFormData((prev) => ({ ...prev, maxDiscountValue: Number(e.target.value) }))} className="mt-1 block w-full" />
                  </div>
                )}

                {formData.nestedDiscountType === 1 && (
                  <div className='mb-4'>
                    <label htmlFor="minimumOrderValue" className="block text-sm font-semibold">Minimum Order Value:</label>
                    <input type="number" value={formData.minimumOrderValue} onChange={(e) => setFormData((prev) => ({ ...prev, minimumOrderValue: Number(e.target.value) }))} className="mt-1 block w-full" />
                    <label htmlFor="discountValue" className="block text-sm font-semibold">Discount Value:</label>
                    <input type="number" value={formData.discountValue} onChange={(e) => setFormData((prev) => ({ ...prev, discountValue: Number(e.target.value) }))} className="mt-1 block w-full" />
                  </div>
                )}

                {formData.nestedDiscountType === 2 && (
                  <div className='mb-4'>
                    <label htmlFor="percentage" className="block text-sm font-semibold">Percentage:</label>
                    <input type="number" value={formData.percentage} onChange={(e) => setFormData((prev) => ({ ...prev, percentage: Number(e.target.value) }))} className="mt-1 block w-full" />
                    <label htmlFor="minimumOrderValue" className="block text-sm font-semibold">Minimum Order Value:</label>
                    <input type="number" value={formData.minimumOrderValue} onChange={(e) => setFormData((prev) => ({ ...prev, minimumOrderValue: Number(e.target.value) }))} className="mt-1 block w-full" />
                  </div>
                )}

                {formData.nestedDiscountType === 3 && (
                  <div className='mb-4'>
                    <label htmlFor="predefinedCouponCode" className="block text-sm font-semibold">Predefined Coupon Code:</label>
                    <input type="text" value={formData.predefinedCouponCode} onChange={(e) => setFormData((prev) => ({ ...prev, predefinedCouponCode: Number(e.target.value) }))} className="mt-1 block w-full" />
                    <label htmlFor="couponName" className="block text-sm font-semibold">Coupon Name:</label>
                    <input type="text" value={formData.couponName} onChange={(e) => setFormData((prev) => ({ ...prev, couponName: Number(e.target.value) }))} className="mt-1 block w-full" />
                    <label htmlFor="couponCode" className="block text-sm font-semibold">Coupon Code:</label>
                    <input type="text" value={formData.couponCode} onChange={(e) => setFormData((prev) => ({ ...prev, couponCode: Number(e.target.value) }))} className="mt-1 block w-full" />
                  </div>
                )}

                {formData.nestedDiscountType === 4 && (
                  <div className='mb-4'>
                    <label htmlFor="minimumOrderValue" className="block text-sm font-semibold">Minimum Order Value:</label>
                    <input type="number" value={formData.minimumOrderValue} onChange={(e) => setFormData((prev) => ({ ...prev, minimumOrderValue: Number(e.target.value) }))} className="mt-1 block w-full" />
                    <label htmlFor="maxDiscountRadius" className="block text-sm font-semibold">Max Discount Value:</label>
                    <input type="number" value={formData.maxDiscountRadius} onChange={(e) => setFormData((prev) => ({ ...prev, maxDiscountRadius: Number(e.target.value) }))} className="mt-1 block w-full" />
                    <label htmlFor="radius" className="block text-sm font-semibold">Radius (in meters):</label>
                    <input type="number" value={formData.radius} onChange={(e) => setFormData((prev) => ({ ...prev, radius: Number(e.target.value) }))} className="mt-1 block w-full" />
                    <div className="mt-2">
                      <label htmlFor="isFlat" className="block text-sm font-semibold">Is Flat:</label>
                      <input type="checkbox" checked={formData.isFlat} onChange={(e) => setFormData((prev) => ({ ...prev, isFlat: Number(e.target.checked) }))} />
                    </div>
                    <div className="mt-2">
                      <label htmlFor="isMaxRadius" className="block text-sm font-semibold">Is Max Radius:</label>
                      <input type="checkbox" checked={formData.isMaxRadius} onChange={(e) => setFormData((prev) => ({ ...prev, isMaxRadius: Number(e.target.checked) }))} />
                    </div>
                  </div>
                )}

                {formData.nestedDiscountType === 6 && (
                  <div className='mb-4'>
                    <label htmlFor="minimumOrderValue" className="block text-sm font-semibold">Minimum Order Value:</label>
                    <input type="number" value={formData.minimumOrderValue} onChange={(e) => setFormData((prev) => ({ ...prev, minimumOrderValue: Number(e.target.value) }))} className="mt-1 block w-full" />
                    <label htmlFor="offerItems" className="block text-sm font-semibold">Offer Items (comma-separated IDs):</label>
                    <input type="text" value={formData.offerItems} onChange={(e) => setFormData((prev) => ({ ...prev, offerItems: e.target.value.split(','), }))} className="mt-1 block w-full" />
                    <label htmlFor="maxItems" className="block text-sm font-semibold">Max Items:</label>
                    <input type="number" value={formData.maxItems} onChange={(e) => setFormData((prev) => ({ ...prev, maxItems: Number(e.target.value) }))} className="mt-1 block w-full" />
                  </div>
                )}

                {formData.nestedDiscountType === 7 && (
                  <div className='mb-4'>
                    <label htmlFor="applicableItems" className="block text-sm font-semibold">Applicable Items (comma-separated IDs):</label>
                    <input type="text" value={formData.offerItems} onChange={(e) => setFormData((prev) => ({ ...prev, offerItems: e.target.value.split(','), }))} className="mt-1 block w-full" />
                    <label htmlFor="discountItem" className="block text-sm font-semibold">Discount Item (0 for lowest priced, 1 for highest priced):</label>
                    <input type="number" value={formData.discountItem} onChange={(e) => setFormData((prev) => ({ ...prev, discountItem: Number(e.target.value) }))} className="mt-1 block w-full" />
                    <label htmlFor="minimumOrderValue" className="block text-sm font-semibold">Minimum Order Value:</label>
                    <input type="number" value={formData.minimumOrderValue} onChange={(e) => setFormData((prev) => ({ ...prev, minimumOrderValue: Number(e.target.value) }))} className="mt-1 block w-full" />
                    <label htmlFor="maxPairs" className="block text-sm font-semibold">Max Pairs:</label>
                    <input type="number" value={formData.maxPairs} onChange={(e) => setFormData((prev) => ({ ...prev, maxPairs: Number(e.target.value) }))} className="mt-1 block w-full" />
                  </div>
                )}
              </>
            )}

            {formData.discountTypeCode === 6 && (
              <div className='mb-4'>
                <label htmlFor="minimumOrderValue" className="block text-sm font-semibold">Minimum Order Value:</label>
                <input type="number" value={formData.minimumOrderValue} onChange={(e) => setFormData((prev) => ({ ...prev, minimumOrderValue: Number(e.target.value) }))} className="mt-1 block w-full" />
                <label htmlFor="offerItems" className="block text-sm font-semibold">Offer Items (comma-separated IDs):</label>
                <input type="text" value={formData.offerItems} onChange={(e) => setFormData((prev) => ({ ...prev, offerItems: e.target.value.split(','), }))} className="mt-1 block w-full" />
                <label htmlFor="maxItems" className="block text-sm font-semibold">Max Items:</label>
                <input type="number" value={formData.maxItems} onChange={(e) => setFormData((prev) => ({ ...prev, maxItems: Number(e.target.value) }))} className="mt-1 block w-full" />
              </div>
            )}

            {/* Case 7: BOGO Discount */}
            {formData.discountTypeCode === 7 && (
              <div className='mb-4'>
                <label htmlFor="applicableItems" className="block text-sm font-semibold">Applicable Items (comma-separated IDs):</label>
                <input type="text" value={formData.offerItems} onChange={(e) => setFormData((prev) => ({ ...prev, offerItems: e.target.value.split(','), }))} className="mt-1 block w-full" />
                <label htmlFor="discountItem" className="block text-sm font-semibold">Discount Item (0 for lowest priced, 1 for highest priced):</label>
                <input type="number" value={formData.discountItem} onChange={(e) => setFormData((prev) => ({ ...prev, discountItem: Number(e.target.value) }))} className="mt-1 block w-full" />
                <label htmlFor="minimumOrderValue" className="block text-sm font-semibold">Minimum Order Value:</label>
                <input type="number" value={formData.minimumOrderValue} onChange={(e) => setFormData((prev) => ({ ...prev, minimumOrderValue: Number(e.target.value) }))} className="mt-1 block w-full" />
                <label htmlFor="maxPairs" className="block text-sm font-semibold">Max Pairs:</label>
                <input type="number" value={formData.maxPairs} onChange={(e) => setFormData((prev) => ({ ...prev, maxPairs: Number(e.target.value) }))} className="mt-1 block w-full" />
              </div>
            )}

            {/* Similarly, handle other discount types */}
            {/* Rest of the code for different discount types remains here */}

            {/* Discount windows */}
            {formData.discountWindows.map((window, index) => (
              <div key={index} className='border-b-2 border-gray-300 pb-3'>
                <h6 className="font-semibold">Discount Window {index + 1}</h6>
                <select value={window.campaign_day_code} onChange={(e) => {
                  const updatedWindows = [...formData.discountWindows];
                  updatedWindows[index].campaign_day_code = e.target.value;
                  setFormData((prev) => ({
                    ...prev,
                    discountWindows: updatedWindows,
                  }));
                }} className="w-full px-3 py-2 border rounded mt-2">
                  <option value={0}>All Days</option>
                  <option value={1}>Sunday</option>
                  <option value={2}>Monday</option>
                  <option value={3}>Tuesday</option>
                  <option value={4}>Wednesday</option>
                  <option value={5}>Thursday</option>
                  <option value={6}>Friday</option>
                  <option value={7}>Saturday</option>
                </select>
                <input type="time" value={window.discount_time_windows[0].start_time} onChange={(e) => {
                  const updatedWindows = [...formData.discountWindows];
                  updatedWindows[index].discount_time_windows[0].start_time = e.target.value;
                  setFormData((prev) => ({
                    ...prev,
                    discountWindows: updatedWindows,
                  }));
                }} className="w-full px-3 py-2 border rounded mt-2" />
                <input type="time" value={window.discount_time_windows[0].end_time} onChange={(e) => {
                  const updatedWindows = [...formData.discountWindows];
                  updatedWindows[index].discount_time_windows[0].end_time = e.target.value;
                  setFormData((prev) => ({
                    ...prev,
                    discountWindows: updatedWindows,
                  }));
                }} className="w-full px-3 py-2 border rounded mt-2" />
              </div>
            ))}

            <button onClick={handleActivateOffer} className='bg-green text-white px-4 py-2 rounded-lg w-full'>Activate Offer</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNewOffer;
