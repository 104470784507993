
import React, { useState, useEffect } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { FaLeaf, FaBox, FaFileInvoiceDollar, FaInfoCircle, FaSearch, FaTimes } from "react-icons/fa";
import { MdOutlineAccessTime } from "react-icons/md";
import SimpleBar from "simplebar-react";
import { DeliveryMenu } from "./DeliveryMenu";
import { fetchMenuItems, fetchOutletInfo } from "../../API/Api";
import Packages from "./Packages";
import Help from "./Help";
import GstDeclaration from "./GstDeclaration";
import Charges from "./Charges";
import MenuDetails from "./MenuDetails";
import { useLocation, useNavigate } from "react-router-dom";
import { setLocalItem } from "../../API/Localstorage.helpers";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function MenuHead() {
  const query = useQuery();
  const navigate = useNavigate();
  const [menuDataForApi, setMenuDataForApi] = useState([]);
  const [deletedItems, setDeletedItems] = useState([]);
  const [open, setOpen] = useState(true);
  const [selectedMenu, setSelectedMenu] = useState("Delivery Menu");
  const [restaurantInfo, setRestaurantInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState('');
  const [itemsToDelete, setItemsToDelete] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [recentSearches, setRecentSearches] = useState([]);

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const token = localStorage.getItem("accessToken");
  const restaurantId = localStorage.getItem("restaurantId")

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchOutletInfo(restaurantId);
        setRestaurantInfo({
          name: data.outletName,
          address: data.addressLine1 + ", "+data.addressLine2,
          phone: data.mobileNumber,
          resId: restaurantId,
          registrationStatus: data.registrationStatus,
          lastLogin: formatDate(data.createdAt),
          lastUpdated: formatDate(data.updatedAt),
          imageUrl: data.restaurantBanner,
        });
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
        console.error("Error fetching restaurant info:", error.message);
      }
    };
    fetchData();
  }, [token]);

  const handleMenuClick = (menuTitle) => {
    setSelectedMenu(menuTitle);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;

    // Allow only alphabetic characters and spaces, limit input to 50 characters
    const regex = /^[A-Za-z\s]{0,50}$/;
    if (regex.test(value)) {
      setSearchInput(value);
    }
  };

  const handleSearch = async () => {
    if (searchInput.trim() === "") {
      return;
    }

    // Store the search query in localStorage (recent searches)
    const updatedSearches = [...recentSearches, searchInput].slice(-5); // Limit to 5 recent searches
    setRecentSearches(updatedSearches);
    localStorage.setItem("recentSearches", JSON.stringify(updatedSearches));

    try {
      const response = await fetchMenuItems();
      const results = response.filter((item) =>
        item.name.toLowerCase().includes(searchInput.toLowerCase())
      );
      setSearchResults(results);
    } catch (error) {
      console.error("Error searching for menu items:", error);
    }
  };

  const handleItemClick = (item) => {
    navigate("/menuhead?steps=MenuDetails&step1=1");
    setLocalItem("MenuDetailsItem", item);
    setSelectedItem(item);
  };

  const handleBack = () => {
    navigate("/menuhead");
    setSelectedItem(null);
  };

  const headerItems = [
    {
      type: "input",
      placeholder: "Search by category, item name...",
      className: "bg-gray-100 outline-none flex-grow",
      icon: <FaSearch className="text-gray-500" />,
      value: searchInput,
      onChange: handleInputChange,
    },
    {
      type: "button",
      text: "All Changes Saved",
      className: "bg-green text-white px-4 py-2 rounded-md",
    },
  ];

  useEffect(() => {
    // Load recent searches from localStorage
    const storedSearches = localStorage.getItem("recentSearches");
    if (storedSearches) {
      setRecentSearches(JSON.parse(storedSearches));
    }
  }, []);

  const handleRepeatSearch = (searchQuery) => {
    setSearchInput(searchQuery);
    handleSearch();
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const menus = [
    { title: "Delivery Menu", icon: <FaLeaf /> },
    { title: "Packages", icon: <FaBox /> },
    { title: "GST Declaration", icon: <FaFileInvoiceDollar /> },
    { title: "Charges", icon: <MdOutlineAccessTime /> },
    { title: "Help", icon: <FaInfoCircle /> },
  ];

  return (
    <div className="menu-head">
      <div className="flex">
        <SimpleBar
          style={{ height: "100vh" }}
          className={`bg-light-grey p-4 duration-300 border-r border-black relative ${open ? "w-64" : "w-20"}`}
        >
          <div className="flex items-center justify-between">
            <div className="text-green text-xl font-bold">Genie</div>
            <div className={`text-black text-sm font-semibold ${open ? "block" : "hidden"}`}>
              MENU DASHBOARD
            </div>
          </div>
          {open && (
            <>
              <img
                src={restaurantInfo.imageUrl}
                alt="Restaurant"
                className="w-full h-32 object-cover rounded mt-4"
              />
              <div className="text-dark mt-4">
                <h1 className="text-lg font-semibold">{restaurantInfo.name}</h1>
                <p className="text-sm">{restaurantInfo.address}</p>
                <p className="text-sm">{restaurantInfo.phone}</p>
                <p className="text-sm mt-2">RES ID: {restaurantInfo.resId}</p>
                <a href="#" className="text-sm text-green underline">
                  {restaurantInfo.registrationStatus}
                </a>
                <p className="text-sm mt-2">Last login: {restaurantInfo.lastLogin}</p>
              </div>
            </>
          )}
          <div className="text-gray-400 mt-4">
            <ul className="mt-4">
              {menus.map((menu, index) => (
                <li
                  key={index}
                  className={`flex items-center gap-x-4 p-2 rounded-md mt-2 cursor-pointer hover:bg-green text-black hover:text-white ${selectedMenu === menu.title ? "bg-green text-white" : "text-green hover:bg-green hover:text-white"
                    }`}
                  onClick={() => handleMenuClick(menu.title)}
                >
                  <span className="text-xl">{menu.icon}</span>
                  <span className={`text-sm ${!open && "hidden"}`}>{menu.title}</span>
                </li>
              ))}
            </ul>
          </div>

          <div className="absolute bottom-4 text-gray-500 text-xs">
            <p>Last updated on</p>
            <p>{restaurantInfo.lastLogin}</p>
          </div>

          <button
            onClick={() => setOpen(!open)}
            className="absolute bg-green text-white rounded-full text-xl -right-3 top-1/2 transform -translate-y-1/2 cursor-pointer p-1"
          >
            <BsArrowLeftShort className={`${open ? "" : "rotate-180"}`} />
          </button>
        </SimpleBar>
        <SimpleBar style={{ height: "100vh" }} className="w-full">
          {/* Conditional Rendering */}
          {query.get("steps") === "MenuDetails" ? (
            <MenuDetails onBack={handleBack} />
          ) : (
            <>
              <header className="bg-white shadow p-4 flex justify-between items-center">
                {headerItems.map((item, index) => (
                  <div
                    key={index}
                    className={`flex items-center ${item.type === "input" ? "bg-gray-100 rounded-md px-3 py-2 w-1/2" : ""}`}
                  >
                    {item.type === "input" && (
                      <>
                        <input
                          type="text"
                          placeholder={item.placeholder}
                          className={item.className}
                          value={item.value}
                          onChange={item.onChange}
                        />
                        {item.icon}
                      </>
                    )}
                    {item.type === "button" && (
                      <button className={item.className} disabled={isSaving}>
                        {isSaving ? "Saving..." : "All Changes Saved"}
                      </button>
                    )}
                  </div>
                ))}
              </header>

              <div className="p-4">
                {/* Search Suggestions */}
                {recentSearches.length > 0 && (
                  <div className="mb-4">
                    <h3 className="text-lg font-semibold">Recent Searches:</h3>
                    <ul>
                      {recentSearches.map((search, index) => (
                        <li
                          key={index}
                          className="cursor-pointer text-green underline"
                          onClick={() => handleRepeatSearch(search)}
                        >
                          {search}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                {/* Search Results */}
                {searchResults.length > 0 ? (
                  searchResults.map((result) => (
                    <div
                      key={result.id}
                      className="border p-4 rounded mb-2 cursor-pointer"
                      onClick={() => handleItemClick(result)}
                    >
                      {result.name}
                    </div>
                  ))
                ) : (
                  <p>No results found for "{searchInput}"</p>
                )}
              </div>

              {selectedMenu === "Delivery Menu" && (
                <DeliveryMenu setMenuDataForApi={setMenuDataForApi} onItemClick={handleItemClick} />
              )}
              {selectedMenu === "Packages" && <Packages />}
              {selectedMenu === "GST Declaration" && <GstDeclaration />}
              {selectedMenu === "Charges" && <Charges />}
              {selectedMenu === "Help" && <Help />}
            </>
          )}
        </SimpleBar>
      </div>
    </div>
  );
}

export default MenuHead;
