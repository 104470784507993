// import React, { useState, useEffect } from 'react';
// import { associateUser, listCustomRoles } from '../../API/Api';

// const AssociateUser = () => {
//     const [formData, setFormData] = useState({
//         restaurantId: localStorage.getItem("restaurantId"),
//         roleId: "",
//         userName: "",
//         mobileNumber: "",
//         roleType: "custom" // default role type
//     });

//     const [roles, setRoles] = useState([]);

//     // Fetch roles directly from the API
//     useEffect(() => {
//         const fetchCustomRoles = async () => {
//             try {
//                 const customRolesResponse = await listCustomRoles(formData.restaurantId);
//                 console.log("Custom Roles Response:", customRolesResponse);

//                 // Set roles state with the response from API
//                 const rolesToStore = customRolesResponse.map(role => ({
//                     roleId: role.roleId,
//                     roleName: role.roleName
//                 }));
//                 setRoles(rolesToStore);

//             } catch (error) {
//                 console.error("Error fetching custom roles:", error);
//             }
//         };

//         fetchCustomRoles();
//     }, [formData.restaurantId]);

//     // Handle form field changes
//     const handleInputChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({
//             ...formData,
//             [name]: value
//         });
//     };

//     // Form validation
//     const isFormValid = () => {
//         return (
//             formData.roleId &&
//             formData.userName.trim() !== "" &&
//             /^[\+\d]?\d{10,15}$/.test(formData.mobileNumber)
//         );
//     };

//     // Handle form submission
//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         if (!isFormValid()) {
//             alert("Please fill in all fields with valid data.");
//             return;
//         }

//         // Ensure mobileNumber has +91 prefix
//         const updatedFormData = {
//             ...formData,
//             mobileNumber: formData.mobileNumber.startsWith("+91") ? formData.mobileNumber : `+91${formData.mobileNumber}`
//         };

//         try {
//             const response = await associateUser(updatedFormData);
//             console.log("User associated successfully:", response);
//         } catch (error) {
//             console.error("Error associating user:", error);
//         }
//     };

//     return (
//         <div className="p-4 max-w-md mx-auto bg-white rounded-lg shadow-md">
//             <h2 className="text-xl font-semibold mb-4">Associate User</h2>
//             <form onSubmit={handleSubmit} className="space-y-4">
//                 {/* Role Selection */}
//                 <div>
//                     <label className="block text-gray-700 font-medium mb-2">Role</label>
//                     <select
//                         name="roleId"
//                         value={formData.roleId}
//                         onChange={handleInputChange}
//                         className="w-full px-3 py-2 border border-gray-300 rounded-md"
//                         required
//                     >
//                         <option value="">Select a Role</option>
//                         {roles.map((role) => (
//                             <option key={role.roleId} value={role.roleId}>
//                                 {role.roleName}
//                             </option>
//                         ))}
//                     </select>
//                 </div>

//                 {/* User Name Input */}
//                 <div>
//                     <label className="block text-gray-700 font-medium mb-2">User Name</label>
//                     <input
//                         type="text"
//                         name="userName"
//                         value={formData.userName}
//                         onChange={handleInputChange}
//                         className="w-full px-3 py-2 border border-gray-300 rounded-md"
//                         required
//                     />
//                 </div>

//                 {/* Mobile Number Input */}
//                 <div>
//                     <label className="block text-gray-700 font-medium mb-2">Mobile Number</label>
//                     <input
//                         type="text"
//                         name="mobileNumber"
//                         value={formData.mobileNumber}
//                         onChange={handleInputChange}
//                         className="w-full px-3 py-2 border border-gray-300 rounded-md"
//                         placeholder="+919876543210"
//                         required
//                     />
//                 </div>

//                 {/* Submit Button */}
//                 <button
//                     type="submit"
//                     className="w-full py-2 px-4 bg-green text-white font-semibold rounded-md hover:bg-green"
//                     disabled={!isFormValid()}
//                 >
//                     Associate User
//                 </button>
//             </form>
//         </div>
//     );
// };

// export default AssociateUser;




import React, { useState, useEffect } from 'react';
import { associateUser, listCustomRoles, listPredefinedRoles } from '../../API/Api';

const AssociateUser = () => {
    const [formData, setFormData] = useState({
        restaurantId: localStorage.getItem("restaurantId"),
        roleId: "",
        userName: "",
        mobileNumber: "",
        roleType: "custom" // default to custom role type
    });

    const [roles, setRoles] = useState([]); // Store roles based on selected type
    const [isCustomRole, setIsCustomRole] = useState(true); // Toggle for custom or predefined roles

    // Fetch roles based on role type
    useEffect(() => {
        const fetchRoles = async () => {
            try {
                let rolesResponse = [];
                if (isCustomRole) {
                    rolesResponse = await listCustomRoles(formData.restaurantId);
                } else {
                    rolesResponse = await listPredefinedRoles(formData.restaurantId);
                }

                const rolesToStore = rolesResponse.map(role => ({
                    roleId: role.roleId,
                    roleName: role.roleName
                }));
                setRoles(rolesToStore);
            } catch (error) {
                console.error("Error fetching roles:", error);
            }
        };

        fetchRoles();
    }, [formData.restaurantId, isCustomRole]);

    // Handle form field changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Handle role type toggle
    const handleRoleTypeChange = (e) => {
        const isCustom = e.target.value === "custom";
        setIsCustomRole(isCustom);
        setFormData({
            ...formData,
            roleType: isCustom ? "custom" : "preDefined",
            roleId: "" // Reset role selection on toggle
        });
    };

    // Form validation
    const isFormValid = () => {
        return (
            formData.roleId &&
            formData.userName.trim() !== "" &&
            /^[\+\d]?\d{10,15}$/.test(formData.mobileNumber)
        );
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!isFormValid()) {
            alert("Please fill in all fields with valid data.");
            return;
        }

        const updatedFormData = {
            ...formData,
            mobileNumber: formData.mobileNumber.startsWith("+91")
                ? formData.mobileNumber
                : `+91${formData.mobileNumber}`
        };

        try {
            const response = await associateUser(updatedFormData);
            console.log("User associated successfully:", response);
        } catch (error) {
            console.error("Error associating user:", error);
        }
    };

    return (
        <div className="p-4 max-w-md mx-auto bg-white rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">Associate User</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                
                {/* Role Type Toggle */}
                <div>
                    <label className="block text-gray-700 font-medium mb-2">Select Role Type</label>
                    <div className="flex items-center space-x-4">
                        <label className="flex items-center">
                            <input
                                type="radio"
                                value="custom"
                                checked={isCustomRole}
                                onChange={handleRoleTypeChange}
                                className="mr-2"
                            />
                            Custom Role
                        </label>
                        <label className="flex items-center">
                            <input
                                type="radio"
                                value="preDefined"
                                checked={!isCustomRole}
                                onChange={handleRoleTypeChange}
                                className="mr-2"
                            />
                            Predefined Role
                        </label>
                    </div>
                </div>

                {/* Role Selection */}
                <div>
                    <label className="block text-gray-700 font-medium mb-2">Role</label>
                    <select
                        name="roleId"
                        value={formData.roleId}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md"
                        required
                    >
                        <option value="">Select a Role</option>
                        {roles.map((role) => (
                            <option key={role.roleId} value={role.roleId}>
                                {role.roleName}
                            </option>
                        ))}
                    </select>
                </div>

                {/* User Name Input */}
                <div>
                    <label className="block text-gray-700 font-medium mb-2">User Name</label>
                    <input
                        type="text"
                        name="userName"
                        value={formData.userName}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md"
                        required
                    />
                </div>

                {/* Mobile Number Input */}
                <div>
                    <label className="block text-gray-700 font-medium mb-2">Mobile Number</label>
                    <input
                        type="text"
                        name="mobileNumber"
                        value={formData.mobileNumber}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md"
                        placeholder="+919876543210"
                        required
                    />
                </div>

                {/* Submit Button */}
                <button
                    type="submit"
                    className="w-full py-2 px-4 bg-green text-white font-semibold rounded-md hover:bg-green"
                    disabled={!isFormValid()}
                >
                    Associate User
                </button>
            </form>
        </div>
    );
};

export default AssociateUser;
