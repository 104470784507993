import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

function Helpcenter() {
  const navigate = useNavigate();
  const Menus = [
    {
      title: "Tickets",
      subtitle: "status of all rised tickets",
      link: "tickets",
    },
    {
      title: "Outlet online/offline status",
      subtitle: "Reason for being offline, if applicable",
      link: "outlet-online",
    },
    {
      title: "Order Related issue",
      subtitle: "Cancellation & delevery releted concerns",
      link: "order-releted-issue",
    },
    {
      title: "Restaurant",
      subtitle: "Timings, Contacts, FSSAI",
      link: "restaurant",
    },
    {
      title: "Address,Location",
      subtitle: "Update Outlets, Address and Location",
      link: "address",
    },
    {
      title: "Menu",
      subtitle: "Items, Photos, Prices, Packaging, Charge,etc..",
      link: "menus",
    },
    {
      title: "Payments",
      subtitle: "Account statement, invoices etc..",
      link: "payments",
    },
    {
      title: "Need Help With Something else",
      subtitle: "Account statement, invoices etc..",
      link: "needhelp",
    },
  ];
  const [activeMenu, setActiveMenu] = useState(""); // Track active menu item

  const handleMenuClick = (link) => {
    setActiveMenu(link); // Set the clicked menu as active
    navigate(link); // Navigate to the clicked link
  };

  return (
    <div className="w-full">
      <div>
        <div className="border-b-2 border-gray-300 shadow p-3">
          <h3 className="text-xl font-bold">Help Center</h3>
        </div>
        <div className="border-[1px] "></div>
        <div className="flex">
          <SimpleBar
            style={{ height: "calc(100vh - 300px)" }}
            className="w-[25%]"
          >
            <div className="border-r-4  ">
              <div className="flex relative justify-center">
                <CiSearch className="absolute -ml-[77%] mt-[29px]" />
                <input
                  type="text"
                  placeholder="search your issue"
                  className="border rounded-lg w-full my-5 mx-5 p-2 pl-7 focus:outline-none"
                />
              </div>
              <div className="">
                {Menus.map((val, index) => (
                  <div
                    key={index}
                    onClick={() => handleMenuClick(val.link)}
                    className={`p-2 py-5 space-x-10 px-2 flex items-center justify-between 
                                        ${
                                          activeMenu === val.link
                                            ? "border-green bg-lime-50 text-green"
                                            : "border-white"
                                        } 
                                        hover:border-green hover:bg-lime-50 hover:text-green cursor-pointer`}
                  >
                    <div>
                      <div>{val.title}</div>
                      <div className="text-sm text-gray-500">
                        {val.subtitle}
                      </div>
                    </div>
                    <FaChevronRight />
                  </div>
                ))}
              </div>
            </div>
          </SimpleBar>
          <SimpleBar
            style={{ height: "calc(100vh - 300px)" }}
            className="w-[75%]"
          >
            <Outlet />
          </SimpleBar>
        </div>
      </div>
    </div>
  );
}

export default Helpcenter;
