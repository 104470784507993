import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, subDays } from "date-fns";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useState } from "react";
import { FaChevronDown } from "react-icons/fa6";
import { FaTimes } from 'react-icons/fa';

const CustomerComplaints = () => {
 const [state, setState] = useState({
    startDate: null,
    endDate: null,
    buttonText: "Last 15 days",
    isCalendarOpen: false,
    isOpen: false,
    selectedCheckboxes: [],
    activeFilter: "issueTypes",
    activeTab: 'Issue Type',
    issueType: {
      open: false,
      dismissed: false,
      resolved: false,
      winback: false,
      expired: false,
    },
    reasons: {
      "Poor taste or quality": false,
      "Wrong items delivered": false,
      "Poor packing or spillage": false,
      "Items missing or not delivered": false,
      "Order delay": false
    }
  });
  const today = new Date();
  const startLimit = subDays(today, 15);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setState((prevState) => ({
      ...prevState,
      startDate: start,
      endDate: end
    }));
  };
  const applyDateRange = () => {
    setState((prevState) => ({
      ...prevState,
      buttonText: prevState.startDate && prevState.endDate
        ? `${format(prevState.startDate, "yyyy-MM-dd")} to ${format(prevState.endDate, "yyyy-MM-dd")}`
        : "Last 15 days",
      isCalendarOpen: false
    }));
  };
 

  const togglePopup = () => {
    setState((prevState) => ({
      ...prevState,
      isOpen: !prevState.isOpen
    }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setState((prevState) => ({
      ...prevState,
      selectedCheckboxes: checked
        ? [...prevState.selectedCheckboxes, name]
        : prevState.selectedCheckboxes.filter((checkbox) => checkbox !== name)
    }));
  };


  const handleReasonsChange = (event) => {
    const { name, checked } = event.target;
    setState((prevState) => ({
      ...prevState,
      reasons: {
        ...prevState.reasons,
        [name]: checked,
      },
    }));
  };

  const handleIssueTypeChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      issueType: {
        ...prevState.issueType,
        [e.target.name]: e.target.checked
      }
    }));
  };



  const handleClearAll = () => {
    setState((prevState) => ({
      ...prevState,
      issueType: {
        open: false,
        dismissed: false,
        resolved: false,
        winback: false,
        expired: false,
      },
      reasons: {
        "Poor taste or quality": false,
        "Wrong items delivered": false,
        "Poor packing or spillage": false,
        "Items missing or not delivered": false,
        "Order delay": false
      }
    }));
  };

  const handleApply = () => {
    console.log('Applied Filters', { issueType: state.issueType, reasons: state.reasons });
    setState((prevState) => ({
      ...prevState,
      selectedCheckboxes: { issueType: prevState.issueType, reasons: prevState.reasons },
      isOpen: false
    }));
  };


  return (
    <div className="">
      <div className="row border-b-2 border-gray-300 shadow-md flex flex-row justify-between items-center">
        <h3 className="p-3 font-bold text-xl">Customer Complaints</h3>
        <div className="flex items-center space-x-4 mx-4">
          <Menu as="div" className="relative inline-block text-left">
            <Menu.Button onClick={() => setState((prevState) => ({ ...prevState, isCalendarOpen: !prevState.isCalendarOpen }))} className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-green rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
              {state.buttonText}
              <FaChevronDown
                className="w-5 h-5 ml-2 -mr-1 text-white"
                aria-hidden="true"
              />
            </Menu.Button>

            <Transition
              as={Fragment}
              show={state.isCalendarOpen}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="right-0 mt-2 origin-top-right absolute bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="px-1 py-1">
                  <DatePicker
                    selected={state.startDate}
                    onChange={handleDateChange}
                    startDate={state.startDate}
                    endDate={state.endDate}
                    selectsRange
                    inline
                    minDate={startLimit}
                    maxDate={today}
                  />
                  <div className="flex justify-end mt-2">
                    <button
                      onClick={applyDateRange}
                      className="px-4 py-2 bg-green text-white rounded-md"
                    >
                      Apply
                    </button>
                    </div>
                  </div>
              </Menu.Items>
            </Transition>
          </Menu>

          <button
           onClick={togglePopup}
            className="px-4 py-2 text-white bg-green rounded-md focus:outline-none" >
            Filter
          </button>
        </div>

      </div>

      {state.startDate && state.endDate && (
        <div className="mt-4 text-xl text-green">
          No complaints reported. Keep it up!
        </div>
      )}
      <Transition
        show={state.isOpen}
        enter="transition ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
          <div className="bg-white rounded shadow-md w-1/3 relative">
            <div className="flex items-center p-3 justify-between border-b-2 border-gray-300">
              <h2 className="text-lg font-semibold">Filter</h2>
              <button
                onClick={togglePopup}
                className="p-2 rounded-full hover:bg-gray-200"
              >
                <FaTimes className="text-gray-500" />
              </button>
            </div>
            <div className="flex flex-col">
              <div className="grid grid-cols-[35%,1fr] gap-4">
                {/* Column 1: Tabs */}
                <div className="border-r-2 border-gray-300 bg-gray-200">
                  <div className="mx-2 my-3">
                    <button
                      className={`px-4 py-2 mb-2 w-full rounded  ${state.activeTab === 'Issue Type' ? 'bg-green , text-white' : ''}`}
                      onClick={() => setState((prevState) => ({ ...prevState, activeTab: 'Issue Type' }))}
                    >
                      Issue Type
                    </button>
                  </div>
                  <div className="mx-2 my-3">
                    <button
                      className={`px-4 py-2 mb-2 w-full rounded ${state.activeTab === 'Reasons' ? 'bg-green , text-white' : ''}`}
                      onClick={() => setState((prevState) => ({ ...prevState, activeTab: 'Reasons' }))}
                    >
                      Reasons
                    </button>
                  </div>
                </div>

                {/* Column 2: Options */}
                <div>
                  {state.activeTab === 'Issue Type' && (
                    <div className="mt-2 leading-7">
                      {Object.keys(state.issueType).map((key) => (
                        <div key={key} className="flex items-center mb-2">
                          <input
                            type="checkbox"
                            name={key}
                            checked={state.issueType[key]}
                            onChange={handleIssueTypeChange}
                            className="mr-2"
                          />
                          <label className="capitalize">{key}</label>
                        </div>
                      ))}
                    </div>
                  )}

                  {state.activeTab === 'Reasons' && (
                    <div className="mt-2 leading-7">
                      {Object.keys(state.reasons).map((key) => (
                        <div key={key} className="flex items-center mb-2">
                          <input
                            type="checkbox"
                            name={key}
                            checked={state.reasons[key]}
                            onChange={handleReasonsChange}
                            className="mr-2"
                          />
                          <label className="capitalize">{key}</label>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex justify-end pt-4 p-3 border-t-2 border-gray-300">
              <button onClick={handleClearAll} className="text-red mr-4">Clear all</button>
              <button onClick={handleApply} className="bg-green text-white px-4 py-2 rounded">Apply</button>
            </div>
          </div>
        </div>
      </Transition>
    </div>

  );
};

export default CustomerComplaints;
