import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faArrowLeft, faMapMarkerAlt, faSearch, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const Outletname = () => {
  const [state, setState] = useState({
    activeIndex: null,
    activeStep: 1,
    isEditing: false,
    mapValue: '',
    currentLocation: { lat: 0, lng: 0 },
    trackId: '123456',
  });
  const toggleAccordion = (index) => {
    setState((prevState) => ({
      ...prevState,
      activeIndex: prevState.activeIndex === index ? null : index,
    }));
  };


  const handleEditClick = () => {
    setState((prevState) => ({ ...prevState, isEditing: true }));
  };

  const navigateToGoogleMaps = () => {
    // Navigate to Google Maps with the current map value
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(state.mapValue)}`;
    window.location.href = googleMapsUrl;
  };
  const handleNextClick = () => {
    if (state.activeStep < 3) {
      setState((prevState) => ({ ...prevState, activeStep: prevState.activeStep + 1 }));
    }
  };
  const handleMapLoad = (map) => {
    map.addListener('mousemove', (e) => {
      setState((prevState) => ({
        ...prevState,
        currentLocation: { lat: e.latLng.lat(), lng: e.latLng.lng() },
      }));
    });
  };
  const handleLatitudeChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      currentLocation: { ...prevState.currentLocation, lat: parseFloat(e.target.value) },
    }));
  };


  const handleLongitudeChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      currentLocation: { ...prevState.currentLocation, lng: parseFloat(e.target.value) },
    }));
  };
  const handleFileUpload = (e) => {
    // Handle file upload logic here
  };
  const handleGoBack = () => {
    setState((prevState) => ({ ...prevState, activeStep: prevState.activeStep - 1 }));
  };


  const handleSubmit = () => {
    // Implement submit logic here
    setState((prevState) => ({ ...prevState, activeStep: 3 }));
  };
  //
  return (
    <div className="mx-8">
      {state.activeIndex === null && (
        <div className="bg-white shadow-md border mt-3 rounded-lg">
          <h1 className="text-base font-semibold border-b m-0 p-3">Select an option to proceed</h1>
          <div className="border-b">
            <div className="flex justify-between items-center px-6 py-4 cursor-pointer select-none bg-slate-50" onClick={() => toggleAccordion(1)}>
              <span className="text-lg">Update Outlet Name</span>
              <FontAwesomeIcon icon={faChevronDown} className="w-4 h-4 text-gray-500" />
            </div>
          </div>
          <div className="border-b">
            <div className="flex justify-between items-center px-6 py-4 cursor-pointer select-none bg-slate-50" onClick={() => toggleAccordion(2)}>
              <span className="text-lg">Update Outlet Address & Location</span>
              <FontAwesomeIcon icon={faChevronDown} className="w-4 h-4 text-gray-500" />
            </div>
          </div>
        </div>
      )}

      {state.activeIndex === 1 && (
        <div className="bg-white shadow-md border mt-3 rounded-lg p-6">
          <button onClick={() => setState((prevState) => ({ ...prevState, activeIndex: null }))} className="mb-4 text-green text-lg">
            <FontAwesomeIcon icon={faArrowLeft} className="w-4 h-4 text-gray-500" /> Back
          </button>
          <h1 className="text-2xl font-semibold mb-4">Update Outlet Name</h1>
          <form>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="outletName">
                Outlet Name
              </label>
              <input type="text" id="outletName" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
            </div>
            <button type="submit" className="bg-green hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              Next
            </button>
          </form>
        </div>
      )}

      {state.activeIndex === 2 && (
        <div className="bg-white shadow-md border mt-3 rounded-lg m-2 relative h-[500px] overflow-hidden">
          <div className='border-b-2 border-gray-300 bg-slate-100 p-3 sticky top-0 z-10'>
            <button onClick={() => setState((prevState) => ({ ...prevState, activeIndex: null }))} className="text-green text-lg">
              <FontAwesomeIcon icon={faArrowLeft} className="w-4 h-4 text-gray-500" /> Update Outlet Address & Location
            </button>
          </div>
          <div className="p-4 flex justify-around items-center border-b-2 border-gray-300 bg-white">
            <div className="text-center cursor-pointer" onClick={() => setState((prevState) => ({ ...prevState, activeStep: 1 }))}>
              <div className={`w-8 h-8 rounded-full ${state.activeStep === 1 ? 'bg-green text-white' : 'bg-gray-300 text-white'} inline-flex items-center justify-center mb-2`}>1</div>
              <div>Edit outlet details</div>
            </div>
            <div className="text-center cursor-pointer" onClick={() => setState((prevState) => ({ ...prevState, activeStep: 2 }))}>
              <div className={`w-8 h-8 rounded-full ${state.activeStep === 2 ? 'bg-green text-white' : 'bg-gray-300 text-white'} inline-flex items-center justify-center mb-2`}>2</div>
              <div>Upload documents</div>
            </div>
            <div className="text-center cursor-pointer" onClick={() => setState((prevState) => ({ ...prevState, activeStep: 3 }))}>
              <div className={`w-8 h-8 rounded-full ${state.activeStep === 3 ? 'bg-green text-white' : 'bg-gray-300 text-white'} inline-flex items-center justify-center mb-2`}>3</div>
              <div>Verification under process</div>
            </div>
          </div>
          <div className="overflow-auto h-[calc(100%-240px)] p-3">
            {state.activeStep === 1 && (
              <form>
                <div className="mb-4 mt-3 border-b-4 border-gray-400 pb-4">
                  <label className="block text-gray-700 text-lg font-bold mb-2" htmlFor="outletAddress">
                    Edit outlet details
                  </label>
                  <div className="relative">
                    <input type="text" id="outletAddress" className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-50 leading-tight focus:outline-none focus:shadow-outline ${state.isEditing ? 'bg-white' : 'bg-gray-200'}`} readOnly={!state.isEditing} />
                    {!state.isEditing && (
                      <span className="absolute right-0 top-0 mr-3 mt-2 text-green cursor-pointer" onClick={handleEditClick}>
                        Edit
                      </span>
                    )}
                  </div>
                </div>
                <div className="">
                  <label className="block text-gray-700 text-lg font-bold mb-2" htmlFor="outletAddress">
                    Update restaurant location on the map
                  </label>
                  <div className="grid grid-cols-3 gap-4">
                    <div className='col-span-2 relative'>
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <FontAwesomeIcon icon={faSearch} className="text-gray-400" />
                      </span>
                      <input type="text" id="mapValue" value={state.mapValue} onChange={(e) => setState((prevState) => ({ ...prevState, mapValue: e.target.value }))} className="shadow appearance-none border rounded w-full py-2 px-10 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Enter your restaurant's locality, e.g., Sector 43, Gurgaon" />
                    </div>

                    <div className=''>
                      <button type="button" className="bg-green target_blank hover:bg-green-700 text-white w-full font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={navigateToGoogleMaps}>
                        <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" /> Detect Current Location
                      </button>
                    </div>
                  </div>
                </div>
                <div className='h-64'>
                  <LoadScript googleMapsApiKey="YOUR_GOOGLE_MAPS_API_KEY">
                    <GoogleMap mapContainerStyle={{ width: '100%', height: '100%' }} center={state.currentLocation} zoom={15} onLoad={handleMapLoad}>
                      <Marker position={state.currentLocation} />
                    </GoogleMap>
                  </LoadScript>
                </div>
                <div className="mt-4 flex">
                  <label className="block text-gray-700 text-lg font-bold mb-2" htmlFor="latitude">
                    Latitude
                  </label>
                  <input type="number" id="latitude" value={state.currentLocation.lat} onChange={handleLatitudeChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                  <label className="block text-gray-700 text-lg font-bold mb-2" htmlFor="longitude">
                    Longitude
                  </label>
                  <input type="number" id="longitude" value={state.currentLocation.lng} onChange={handleLongitudeChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                </div>
              </form>
            )}
            {state.activeStep === 2 && (
              <div>
                {/* Placeholder for upload documents section */}
                <h5 className='font-bold text-lg mt-3'>Upload the new FSSAI license document, that matches your new outlet details</h5>
                <div className="flex h-full mt-4 mb-3">
                  <input type="file" id="fileUpload" onChange={handleFileUpload} className="hidden" />
                  <label htmlFor="fileUpload" className="bg-emerald-50 border-dashed border-2 border-green rounded-lg p-4 cursor-pointer text-center w-2/4">
                    <div className="text-green text-lg font-bold">Upload Attachment</div>
                  </label>
                </div>
                <p>Format : jpg, png, pdf, Max size: 5MB</p>
              </div>
            )}
            {state.activeStep === 3 && (
              <div className="bg-white shadow-md border rounded-lg p-4">
                <div className="border-b-2 border-gray-300 mb-4">
                  <h2 className="text-xl font-bold text-gray-700 mb-3">Restaurant: Update Name</h2>
                </div>
                <div className="flex flex-col">
                  <button type="button" className="bg-red hover:bg-red-700 text-white w-1/6 font-bold py-2 px-4 rounded mb-2">
                    Open
                  </button>
                  <div className="flex justify-between w-full text-gray-700 text-lg mt-3">
                    <span>Track ID: {state.trackId}</span>
                    <a href="#/details" className="text-green flex items-center">
                      View Details
                      <FontAwesomeIcon icon={faArrowRight} className="ml-1" />
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='border-t-2 border-gray-300 bg-white p-3 sticky bottom-0 z-10 flex justify-end space-x-4'>
            {state.activeStep === 1 && (
              <button type="button" className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={handleNextClick}>
                Next
              </button>
            )}

            {state.activeStep === 2 && (
              <>
                <button type="button" className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={handleGoBack}>
                  Go Back
                </button>
                <button type="button" className="bg-green hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={handleSubmit}>
                  Submit
                </button>
              </>
            )}

            {state.activeStep === 3 && (
              <>
                <button type="button" className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={handleGoBack}>
                  Go Back
                </button>
              </>
            )}
          </div>

        </div>
      )}
    </div>
  )
}

export default Outletname