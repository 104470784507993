import React, { useEffect, useState } from 'react';
import { createCustomRole, listCustomisablePermissions } from '../../API/Api';

// Modal Component
const Modal = ({ message, onClose }) => (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75">
        <div className="bg-white rounded-lg p-6 max-w-md mx-auto shadow-lg">
            <h3 className="text-lg font-semibold mb-4">{message}</h3>
            <button
                onClick={onClose}
                className="mt-4 w-full py-2 px-4 bg-green text-white rounded-md hover:bg-indigo-700"
            >
                Close
            </button>
        </div>
    </div>
);

const CreateRoles = () => {
    const [formData, setFormData] = useState({
        restaurantId: localStorage.getItem("restaurantId"),
        roleName: '',
        permissions: {},
    });

    const [permissionsList, setPermissionsList] = useState([]); // Store permissions dynamically
    const [selectAll, setSelectAll] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [showModal, setShowModal] = useState(false);

    // Load permissions from the API
    useEffect(() => {
        const fetchPermissions = async () => {
            try {
                const response = await listCustomisablePermissions(formData.restaurantId);
                const permissions = response.customisableRolePermissions;

                const initialPermissions = Object.keys(permissions).reduce((acc, key) => {
                    acc[key] = permissions[key] === 'boolean' ? false : 0;
                    return acc;
                }, {});

                setFormData(prevState => ({
                    ...prevState,
                    permissions: initialPermissions
                }));
                setPermissionsList(Object.entries(permissions));
            } catch (error) {
                console.error("Error fetching permissions:", error);
            }
        };
        fetchPermissions();
    }, [formData.restaurantId]);

    // Handle input changes for role name and permissions
    const handleInputChange = (e) => {
        const { name, type, checked, value } = e.target;
        if (name === 'roleName') {
            setFormData((prevData) => ({
                ...prevData,
                roleName: value,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                permissions: {
                    ...prevData.permissions,
                    [name]: type === 'checkbox' ? checked : value === ''
                        ? '' // Allow empty value
                        : !isNaN(value)
                            ? parseInt(value, 10) // Parse valid number
                            : prevData.permissions[name], // Preserve previous valid number if NaN
                },
            }));
        }
    };

    // Select All functionality for permissions
    const handleSelectAll = (e) => {
        const { checked } = e.target;
        setSelectAll(checked);
        setFormData((prevData) => ({
            ...prevData,
            permissions: Object.fromEntries(
                Object.entries(prevData.permissions).map(([key, value]) => [
                    key,
                    typeof value === 'boolean' ? checked : value,
                ])
            ),
        }));
    };

    // Reset form fields
    const resetForm = () => {
        setFormData({
            restaurantId: localStorage.getItem("restaurantId"),
            roleName: '',
            permissions: permissionsList.reduce((acc, [key, type]) => {
                acc[key] = type === 'boolean' ? false : 0;
                return acc;
            }, {}),
        });
        setSelectAll(false);
    };

    // Submit handler for the form
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await createCustomRole(formData);
            if (response.status === 'success') {
                setModalMessage("Role created successfully!");
            } else {
                setModalMessage("An error occurred. Please try again.");
            }
            setShowModal(true);
        } catch (error) {
            if (error.response?.status === 400) {
                setModalMessage("User already created. Role name already exists.");
            } else {
                setModalMessage("An error occurred. Please try again.");
            }
            console.error('Error creating custom role:', error);
        }
    };

    // Close modal and reset form
    const handleCloseModal = () => {
        setShowModal(false);
        resetForm();
    };

    const camelCaseToNormal = (text) => {
        return text
            .replace(/([A-Z])/g, ' $1') // Add a space before each uppercase letter
            .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
    };

    return (
        <div className="p-8 max-w-3xl mx-auto bg-white rounded-lg shadow-lg">
            <h2 className="text-2xl font-semibold text-gray-800 mb-6">Create Custom Role</h2>
            <form onSubmit={handleSubmit} className="space-y-6">
                <div className="mb-4">
                    <label className="block text-gray-700 font-medium mb-2">
                        Role Name:
                        <input
                            type="text"
                            name="roleName"
                            value={formData.roleName}
                            onChange={handleInputChange}
                            required
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                        />
                    </label>
                </div>
                <div>
                    <h3 className="text-xl font-semibold text-gray-700 mb-4">Permissions</h3>
                    {/* Select All Checkbox */}
                    <div className="flex items-center mb-2">
                        <span className="text-gray-700 font-medium">Select All</span>
                        <input
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                            className="h-5 ml-2 w-5 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                        />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4">
                        {permissionsList.map(([key, type]) => (
                            <div key={key} className="flex items-center space-x-3">
                                {type === 'boolean' ? (
                                    <input
                                        type="checkbox"
                                        name={key}
                                        checked={formData.permissions[key]}
                                        onChange={handleInputChange}
                                        className="h-5 w-5 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                    />
                                ) : (
                                    <input
                                        type="tel"
                                        name={key}
                                        value={formData.permissions[key] || ''}
                                        onChange={handleInputChange}
                                        className="w-10 border border-gray-300 text-center rounded shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                    />
                                )}
                                <span className="text-gray-700 font-medium">{camelCaseToNormal(key)}</span>
                            </div>
                        ))}
                    </div>
                </div>
                <div>
                    <button
                        type="submit"
                        className="w-full py-2 px-4 bg-green text-white font-semibold rounded-md focus:outline-none focus:ring-2"
                    >
                        Create Role
                    </button>
                </div>
            </form>

            {/* Modal */}
            {showModal && <Modal message={modalMessage} onClose={handleCloseModal} />}
        </div>
    );
};

export default CreateRoles;
