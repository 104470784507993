import React, { useState, useEffect, useRef } from 'react';
import Logoimg from '../assets/logo1.webp';
import { reSendOtp, verifyOtp } from '../API/AuthApi'; // Ensure this path is correct
import { updateApiBaseUrl } from '../API/Api';
import { handleError } from '../API/errorHandler';
import { useNavigate } from "react-router-dom";



const OTPVerification = ({ onVerify }) => {
  const [otp, setOtp] = useState(Array(6).fill(''));
  const [isResendEnabled, setIsResendEnabled] = useState(false);
  const [otpExpired, setOtpExpired] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const inputRefs = useRef(new Array(6).fill(null));
  const uniqueAttemptId = localStorage.getItem("uniqueAttemptId");
  const [showOtpExpiredMessage, setShowOtpExpiredMessage] = useState(false);

  const navigate = useNavigate();


  // Initialize max resend attempts
  const maxResendAttempts = parseInt(localStorage.getItem("otpRetriesLeft")) || 2;
  const [resendAttempts, setResendAttempts] = useState(maxResendAttempts);

  const [otpDetails, setOtpDetails] = useState(JSON.parse(localStorage.getItem("otpDetails")) || {});

  // Handle backspace key press in OTP fields
  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace") {
      const newOtp = [...otp];
      newOtp[index] = ""; // Clear current digit only

      // Update the OTP state
      setOtp(newOtp);

      // Move focus to the previous input, but don't clear it
      if (index > 0 && otp[index] === "") {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  useEffect(() => {
    let expiryTimer, resendTimer;
    const currentTimestamp = Date.now();

    const timeUntilExpiry = otpDetails.otpExpiry - currentTimestamp;
    const timeUntilResend = otpDetails.otpResendWindow - currentTimestamp;

    // Set OTP expiry timer
    if (timeUntilExpiry > 0) {
      expiryTimer = setTimeout(() => {
        setOtpExpired(true);
        if (resendAttempts > 0) {
          setShowOtpExpiredMessage(true);  // Show message only if resend attempts are available
        }
      }, timeUntilExpiry);
      // setTimeout(() => setOtpExpired(false), 1000);
    } else {
      setOtpExpired(true); // If already expired
      if (resendAttempts > 0) {
        setShowOtpExpiredMessage(true);
      }
    }

    // Hide the expired message after 5 seconds
    if (otpExpired && showOtpExpiredMessage) {
      const hideMessageTimer = setTimeout(() => {
        setShowOtpExpiredMessage(false);
      }, 5000);
      return () => clearTimeout(hideMessageTimer); // Clear timeout on component unmount
    }

    // Set resend button enable timer
    if (timeUntilResend > 0) {
      resendTimer = setTimeout(() => setIsResendEnabled(true), timeUntilResend);
    } else {
      setIsResendEnabled(true); // If already past resend window
    }

    return () => {
      clearTimeout(expiryTimer);
      clearTimeout(resendTimer);
    };
  }, [otpDetails, otpExpired, resendAttempts, showOtpExpiredMessage]);

  const handleOtpChange = (index, value) => {
    if (value.length === 1 && /^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (index < 5) inputRefs.current[index + 1]?.focus();
    } else if (value === '') {
      const newOtp = [...otp];
      newOtp[index] = '';
      setOtp(newOtp);
      inputRefs.current[index]?.focus();
    }
  };

  const handleVerifyOtp = async () => {
    const otpString = otp.join('');
    if (otpString.length !== 6) {
      setErrorMessage("Please enter the full 6-digit OTP");
      setTimeout(() => setErrorMessage(''), 5000); // Clear error after 5 seconds
      return;
    }

    if (otpExpired) {
      setErrorMessage("OTP expired. Please resend to get a new one.");
      setTimeout(() => setErrorMessage(''), 5000); // Clear error after 5 seconds
      return;
    }

    // if (otpString !== otpDetails.otp) {
    //   setErrorMessage("Wrong OTP entered.");
    //   setTimeout(() => setErrorMessage(''), 5000); // Clear error after 5 seconds
    //   return;
    // }

    const phoneNumber = localStorage.getItem("phoneNumber");
    if (!phoneNumber) return;

    try {
      const response = await verifyOtp(phoneNumber, otpString, uniqueAttemptId);
      const { accessToken, refreshToken, accessTokenExpiry, refreshTokenExpiry, restaurants, endpoint, user } = response || {};
      console.log("OTP Response", response);

      if (response.status === 404) {
        setErrorMessage("Wrong OTP entered.");
        setTimeout(() => setErrorMessage(''), 6000); // Clear error after 5 seconds
      }

      // Clear resend attempts from localStorage on successful verification
      localStorage.removeItem('otpRetriesLeft');

      localStorage.setItem("endpoint", endpoint);
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("accessTokenExpiry", accessTokenExpiry);
      localStorage.setItem("refreshTokenExpiry", refreshTokenExpiry);
      localStorage.setItem("restaurantId", restaurants[0].restaurantId);
      localStorage.setItem("userId", user.id)

      updateApiBaseUrl();
      onVerify(response.accessToken);
    } catch (error) {
      handleError(error);
    }
  };

  const handleResendOtp = async () => {
    if (!isResendEnabled || resendAttempts <= 0) return;

    const phoneNumber = localStorage.getItem("phoneNumber");
    if (!phoneNumber) return;

    try {
      const response = await reSendOtp(phoneNumber, 'resend', uniqueAttemptId);
      const { otp, otpExpiry, otpResendWindow } = response;

      // Update OTP details in localStorage with new expiry and resend window times
      const newOtpDetails = {
        otp,
        otpExpiry: Date.now() + otpExpiry * 1000,
        otpResendWindow: Date.now() + otpResendWindow * 1000
      };
      localStorage.setItem("newOtpDetails", JSON.stringify(newOtpDetails));

      setOtpDetails(JSON.parse(localStorage.getItem("newOtpDetails")) || {});
      // setOtpDetails(newOtpDetails);  // Update state with new OTP details

      setOtp(Array(6).fill(''));
      inputRefs.current[0]?.focus();

      setOtpExpired(false);
      setIsResendEnabled(false);

      // Set new timers based on the new OTP expiry and resend window
      setTimeout(() => setIsResendEnabled(true), otpResendWindow * 1000);

      // Decrement resend attempts and update local storage
      const newResendAttempts = resendAttempts - 2;
      setResendAttempts(newResendAttempts);
      localStorage.setItem('otpRetriesLeft', newResendAttempts);

      if (newResendAttempts <= 0) {
        setIsResendEnabled(false);
      }
    } catch (error) {
      handleError(error);
    }
  };

  // const handleOtpVerification = (token) => {
  //   sessionStorage.setItem("accessToken", token);
  //   navigate("/TrackOffer", { replace: true }); // Replace history stack
  // };

  return (
    <>
      <div className=' px-6 py-6'>
        <img src={Logoimg} alt="logo1" />
      </div>

      <div className="min-h-96 flex flex-col items-center justify-center">
        <div className=" bg-gray-100 p-8 rounded shadow-md w-96 relative">
          <h2 className="text-center text-2xl font-semibold mb-4">OTP Verification</h2>
          <p className="text-center mb-4">Check text messages for your OTP</p>
          <div className="flex justify-center mb-4">
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                className="w-12 h-12 m-1 text-center text-xl border border-gray-300 rounded focus:outline-none focus:border-green"
                value={digit}
                onChange={(e) => handleOtpChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                ref={(el) => inputRefs.current[index] = el}
              />
            ))}
          </div>
          {otpExpired && showOtpExpiredMessage && resendAttempts > 0 && (
            <p className="text-red-500 text-sm font-bold mt-3">
              OTP expired. Please resend to get a new one.
            </p>
          )}
          {errorMessage && <p className="text-red-500 font-bold mt-2">{errorMessage}</p>}
          <div className="mt-2">
            <button
              className={`bg-green hover:bg-green-600 w-full text-white font-semibold py-2 px-4 rounded ${otp.join('').length === 6 ? '' : 'opacity-50 cursor-not-allowed'}`}
              onClick={handleVerifyOtp}
              disabled={otp.join('').length !== 6}
            >
              Verify OTP
            </button>
          </div>
          <div className="text-center mt-2">
            Not received OTP?{' '}
            <button className={`text-green  ${isResendEnabled && resendAttempts > 0 ? '' : 'opacity-50 cursor-not-allowed'}`}
              onClick={handleResendOtp}
              disabled={!isResendEnabled || resendAttempts <= 0}
            >
              Resend Now
            </button>
            {resendAttempts <= 0 && (
              <p className="text-red-500 text-sm font-bold mt-2">Maximum resend attempts reached.</p>
            )}
          </div>
        </div>
      </div>

    </>
  );
};

export default OTPVerification;
