import axios from "axios";

const BASE_URL = `${process.env.REACT_APP_API_ENDPOINT}/api`;

const ApiService = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// OTP Send API function
export const sendOtp = async (phoneNumber, mode) => {
    try {
      const response = await ApiService.post("auth/otp/send", { phoneNumber, mode });
      return response.data;
    } catch (error) {
      throw error; // Will be caught by the error handler
    }
  };

  export const reSendOtp = async (phoneNumber, mode, requestId) => {
    try {
      const response = await ApiService.post("auth/otp/send", { phoneNumber, mode, requestId });
      return response.data;
    } catch (error) {
      throw error; // Will be caught by the error handler
    }
  };

  // OTP Verify API function
export const verifyOtp = async (phoneNumber, otp, uniqueAttemptId) => {
  try {
    const response = await ApiService.post("auth/otp/verify", {
      phoneNumber,
      otp,
      requestId : uniqueAttemptId,
      deviceType: "React_Merchant", // Default device type
      appVersion: "1.0.1", // Default app version
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
  
  export const refreshTokenAPI = async (refreshToken) => {
    try {
      const response = await ApiService.post("auth/token/refresh", {
        refreshToken,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  export const fetchCurrentUTCTime = async () => {
    try {
      const response = await ApiService.get("/_health/timesync");
      return response;
    } catch (error) {
      console.error("Error fetching UTC time:", error);
      throw error;
    }
  };