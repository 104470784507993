import React, { useState } from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { FaEdit, FaTrash, FaCopy } from 'react-icons/fa';
import Nested from './Nested';
import NoCustomize from './NoCustomize';
import { getLocalItem } from '../../API/Localstorage.helpers';
import { useLocation, useNavigate } from 'react-router-dom';
function useQuary() {
  return new URLSearchParams(useLocation().search);
}
const MenuDetails = ({ onBack }) => {
  const query = useQuary()
  const navigate = useNavigate()
  const item = getLocalItem('MenuDetailsItem')
  const [isNextClicked, setIsNextClicked] = useState(false);
  const [isSectionPage3, setIsSectionPage3] = useState(false);
  const [isSamePrice, setIsSamePrice] = useState(false);
  const [isCustomizable, setIsCustomizable] = useState(null);
  const [options, setOptions] = useState(['Crust']);
  const [optionItems, setOptionItems] = useState([{ optionName: 'New Hand Tossed', additionalPrice: '50' }]);
  const [variants, setVariants] = useState([{ variantName: '', additionalPrice: '' }]);
  const [variantGroupName, setVariantGroupName] = useState('Size');
  const [selectedOption, setSelectedOption] = useState('');
  const [showNested, setShowNested] = useState(false);
  const [takeaway, setTakeaway] = useState(false); // Checkbox state for Takeaway
  const [delivery, setDelivery] = useState(false); // Checkbox state for Delivery
  const [selectedBox, setSelectedBox] = useState(null);
  const [copiedSections, setCopiedSections] = useState([]);
  const [optionState, setOptionState] = useState([{ optionName: '', varient: [{ variantName: '', additionalPrice: '' }] }])
  
  const varientOnClick = (name) => {
    setOptionState((prev) => {
      // Create a copy of the previous state (array of options)
      const updatedOptions = [...prev];

      // Update the optionName of the first (0th) option
      updatedOptions[0] = {
        ...updatedOptions[0],    // Spread the existing fields of the first option
        optionName: name         // Set the new optionName
      };

      // Return the updated options array
      return updatedOptions;
    });
  };

  const handleVariantChanges = (optionIndex, variantIndex, e) => {
    const { name, value } = e.target;

    setOptionState((prev) => {
      // Create a copy of the entire state (array of options)
      const updatedOptions = [...prev];

      // Create a copy of the specific option's varient array
      const updatedVariants = [...updatedOptions[optionIndex].varient];

      // Update the specific variant within that option
      updatedVariants[variantIndex] = {
        ...updatedVariants[variantIndex],  // Spread existing fields
        [name]: value                      // Update the field by name (variantName or additionalPrice)
      };

      // Update the varient array within the option
      updatedOptions[optionIndex] = {
        ...updatedOptions[optionIndex],
        varient: updatedVariants
      };

      // Return the updated state
      return updatedOptions;
    });
  };

  const handleCostamizable = (value) => {
    if (value) {
      setIsCustomizable(value)
    }
    else {
      setIsCustomizable(value)
    }
  }
  const addOptionVariant = (index) => {
    setOptionState(prev => {
      // Create a copy of the previous state
      const updatedOptions = [...prev];

      // Update the specific option at the given index by adding a new variant
      updatedOptions[index] = {
        ...updatedOptions[index],
        varient: [...updatedOptions[index].varient, { variantName: '', additionalPrice: '' }]
      };

      // Return the updated array
      return updatedOptions;
    });
  };

  const handleVariantChange = (index, field, value, sectionIndex = null) => {
    if (sectionIndex === null) {
      // Modify original section variants
      const updatedVariants = [...variants];
      updatedVariants[index][field] = value;
      setVariants(updatedVariants);
    } else {
      // Modify variants of copied sections
      const updatedCopiedSections = [...copiedSections];
      updatedCopiedSections[sectionIndex].variants[index][field] = value;
      setCopiedSections(updatedCopiedSections);
    }
  };


  const addVariant = (sectionIndex = null) => {
    if (sectionIndex === null) {
      // Add variant to original section
      setVariants([...variants, { variantName: '', additionalPrice: '', }]);
    } else {
      // Add variant to specific copied section
      const updatedCopiedSections = [...copiedSections];
      updatedCopiedSections[sectionIndex].variants.push({ variantName: '', additionalPrice: '' });
      setCopiedSections(updatedCopiedSections);
    }
  };

  const handleEdit = (index) => {
    // console.log(Edit variants at index: ${index});
  };


  const handleDelete = (index, sectionIndex = null) => {
    if (sectionIndex === null) {
      const newVariants = variants.filter((_, i) => i !== index);
      setVariants(newVariants);
    } else {
      const updatedCopiedSections = [...copiedSections];
      updatedCopiedSections[sectionIndex].variants = updatedCopiedSections[sectionIndex].variants.filter((_, i) => i !== index);
      setCopiedSections(updatedCopiedSections);
    }
  };
  const handleDeleteVariant = (optionIndex, variantIndex) => {
    setOptionState((prev) => {
      // Create a copy of the state
      const updatedOptions = [...prev];

      // Create a copy of the specific option's varient array
      const updatedVariants = [...updatedOptions[optionIndex].varient];

      // Remove the variant at variantIndex
      updatedVariants.splice(variantIndex, 1);

      // Update the varient array of the option at optionIndex
      updatedOptions[optionIndex] = {
        ...updatedOptions[optionIndex],
        varient: updatedVariants
      };

      // Return the updated state
      return updatedOptions;
    });
  };

  const handleOptionClick = (optionName) => {
    setSelectedOption(optionName);
  };

  const handleOptionChange = (index, e) => {
    const { name, value } = e.target;

    setOptionItems((prevOptions) => {
      // Create a copy of the previous state
      const updatedOptions = [...prevOptions];

      // Update the specific object at the given index
      updatedOptions[index] = {
        ...updatedOptions[index],  // Spread the existing properties
        [name]: value              // Update the specific field (by name) with the new value
      };

      // Return the updated state
      return updatedOptions;
    });
  };

  const addOption = () => {
    setOptionItems([...optionItems, { optionName: '', additionalPrice: '', varients: [{ varientName: '', additionalPrice: '' }] }]);
  };

  const handleBoxSelect = (boxIndex) => setSelectedBox(boxIndex);
  const handleTakeawayChange = (e) => setTakeaway(e.target.checked);
  const handleDeliveryChange = (e) => setDelivery(e.target.checked);
  const handleToggleChange = (e) => setIsSamePrice(e.target.checked);

  // Handle Back navigation in MenuDetails
  const handleBackClick = () => {
    if (isSectionPage3) {
      setIsSectionPage3(false);
      setIsNextClicked(true);
    } else if (isNextClicked) {
      setIsNextClicked(false);
    } else if (!isCustomizable && selectedBox === 2) {
      setIsCustomizable(true); // or any other state reset logic
    }
  };

  // Handle the transition to Nested
  const handleContinueClick = () => {
    setShowNested(true);
  };

  const handleCopy = () => {
    setOptionState(prev => ([...prev, optionState[0]]))
  };

  // Render Nested component if showNested is true
  if (query.get('step1') === '4') {
    return (
      <Nested
        onBack={() => setShowNested(false) || setIsSectionPage3(true)} // Go back to isSectionPage3
        itemName={item.name} // Pass item name
        isSamePrice={isSamePrice} // Pass isSamePrice state
        takeaway={takeaway} // Pass takeaway state
        delivery={delivery} // Pass delivery state
        handleToggleChange={handleToggleChange} // Pass toggle change handler
        handleTakeawayChange={handleTakeawayChange} // Pass takeaway change handler
        handleDeliveryChange={handleDeliveryChange} // Pass delivery change handler
        optionsData={optionState}
        variantsData={variants}
      />
    );
  }

  if (!isCustomizable && selectedBox === 2) {
    return (
      <NoCustomize
        onBack={handleBackClick}
        itemName={item.name}
        takeaway={takeaway}
        delivery={delivery}
        isSamePrice={isSamePrice}
        isCustomizable={isCustomizable}
        basePrice={item.basePrice}
      />
    );
  }

  return (
    <div className='px-16 py-16 pt-10 bg-gray-50 h-screen'>
      {query.get('step1') === '3' ? (
        <div className="p-4 ">
          <div className="mb-4 flex items-center justify-between">
            <h1 className="text-2xl font-bold mb-4">The Genie - Menu Creation Tool</h1>
            <button className="bg-green text-white px-4 py-2 rounded">
              Save Draft
            </button>
          </div>
          <div className="flex items-center">
            <BsArrowLeft onClick={() => { navigate('/menuhead?steps=MenuDetails&step1=2') }} className="text-green text-2xl cursor-pointer mx-2" />
            <span className="font-semibold">Set Your Delivery Pricing</span>
          </div>
          <div className="mb-4 flex items-center mt-5">
            <label className="block text-base font-medium text-gray-700 mb-1">
              Base Price
            </label>
            <span className="flex items-center mx-5">
              <input type="text" className="border border-gray-300 rounded-md p-1" placeholder="Base Price" />
            </span>
          </div>
          <h2 className="text-lg font-bold my-4">Create Option</h2>
          <div className="mt-4">
            <p className="text-lg font-semibold">Option Items</p>
            {optionItems.map((option, index) => (
              <div key={index} className="flex items-center mt-2 border border-gray-300 rounded p-2 bg-white"
                onClick={() => { varientOnClick(option.optionName) }}>
                <input type="text" placeholder="Enter option name" name='optionName' value={option.optionName}
                  onChange={(e) => handleOptionChange(index, e)}
                  className="flex-grow bg-white outline-none" />
                <span className="flex items-center">
                  +
                  <input type="text" placeholder="0.00" name='additionalPrice' value={option.additionalPrice}
                    onChange={(e) => handleOptionChange(index, e)}
                    className="w-20 text-center border-none bg-white outline-none border rounded p-1" />
                </span>
              </div>
            ))}
          </div>
          <div className="mt-4">
            <div className="mb-4 flex items-center">
              <div>
                <label className="text-lg font-semibold">Variant Group Name:</label>
                <input
                  type="text"
                  value={variantGroupName}
                  onChange={(e) => setVariantGroupName(e.target.value)}
                  className="mt-2 text-center bg-white outline-none border border-gray-300 rounded p-2 mx-2" />
              </div>
              <div className="flex justify-end mt-2">
                <button onClick={handleCopy} className="px-4 py-2 bg-green text-white rounded flex items-center">
                  <FaCopy className="mr-1" /> Copy
                </button>
              </div>
            </div>
            <div>
              <p className="text-lg font-semibold mt-3">Create Variant Items</p>
              {optionState.map((variant, index) => (<div key={index}>
                <div className="mt-4">
                  <h2 className="text-lg font-bold mb-2">Option: <span className="font-normal">{variant.optionName}</span></h2>
                </div>
                {variant.varient.map((value, i) => (
                  <div key={i} className="flex items-center mt-2 border border-gray-300 rounded p-2 bg-white">
                    <input type="text" placeholder="Enter variant name" name='variantName' value={value.variantName}
                      onChange={(e) => handleVariantChanges(index, i, e)}
                      className="flex-grow bg-white outline-none" />
                    <span className="flex items-center">
                      Additional Price:
                      <input type="text" placeholder="0.00" name='additionalPrice' value={value.additionalPrice}
                        onChange={(e) => handleVariantChanges(index, i, e)}
                        className="ml-2 w-20 text-center bg-white outline-none border rounded p-1" />
                      <FaEdit className="ml-2 text-green cursor-pointer" onClick={() => handleEdit(index)} />
                      <FaTrash className="ml-2 text-red cursor-pointer" onClick={() => handleDeleteVariant(index, i)} />
                    </span>
                  </div>
                ))
                }
                <div className="flex justify-center">
                  <button onClick={() => { addOptionVariant(index) }} className="mt-4 px-4 py-2 bg-green text-white rounded">
                    + Add Another Variant
                  </button>
                </div>
              </div>
              ))}

            </div>

            {/* Render Copied Sections */}
            {/* {copiedSections.map((section, sectionIndex) => (
              <div key={sectionIndex} className="mt-4 border-t pt-4">
                <h2 className="text-lg font-bold mb-2">Option: <span className="font-normal">{section.selectedOption}</span></h2>
                <p className="text-lg font-semibold mt-3">Create Variant Items</p>
                {section.variants.map((variant, index) => (
                  <div key={index} className="flex items-center mt-2 border border-gray-300 rounded p-2 bg-white">
                    <input
                      type="text"
                      placeholder="Enter variant name"
                      value={variant.variantName}
                      onChange={(e) => handleVariantChange(index, 'variantName', e.target.value, sectionIndex)}
                      className="flex-grow bg-white outline-none"
                    />
                    <span className="flex items-center">
                      Additional Price:
                      <input
                        type="text"
                        placeholder="0.00"
                        value={variant.additionalPrice}
                        onChange={(e) => handleVariantChange(index, 'additionalPrice', e.target.value, sectionIndex)}
                        className="ml-2 w-20 text-center bg-white outline-none border rounded p-1"
                      />
                      <FaEdit className="ml-2 text-green cursor-pointer" />
                      <FaTrash className="ml-2 text-red cursor-pointer" onClick={() => handleDelete(index, sectionIndex)} />
                    </span>
                  </div>
                ))}
                <div className="flex justify-center">
                  <button onClick={() => addVariant(sectionIndex)} className="mt-4 px-4 py-2 bg-green text-white rounded">
                    + Add Another Variant
                  </button>
                </div>
              </div>
            ))} */}
            <div className='flex justify-center'>
              <button className='mt-4 px-4 py-2 bg-green text-white rounded w-3/12' onClick={() => { navigate('/menuhead?steps=MenuDetails&step1=4') }}>
                Continue
              </button>
            </div>
          </div>
        </div>
      ) : ''}
      
      {query.get('step1') === '2' ? (
        <div className="p-4 bg-gray-50 h-screen">
          <div className="mb-4 flex items-center justify-between">
            <h1 className="text-2xl font-bold mb-4">The Genie - Menu Creation Tool</h1>
            <button className="bg-green text-white px-4 py-2 rounded">
              Save Draft
            </button>
          </div>
          <div className="flex items-center">
            <BsArrowLeft onClick={() => { navigate('/menuhead?steps=MenuDetails&step1=1') }} className="text-green text-2xl cursor-pointer mx-2" />
            <span className="font-semibold">Set Your Delivery Pricing</span>
          </div>
          <div className="mb-4 flex items-center mt-5">
            <label className="block text-base font-medium text-gray-700 mb-1">
              Base Price
            </label>
            <span className="flex items-center mx-5">
              <input type="text" className="border border-gray-300 rounded-md p-1" placeholder="Base Price" />
            </span>
          </div>
          <div className='flex items-center'>
            <span className="text-lg font-bold my-4">Create Option</span>
            {options.map((option, index) => (
              <div key={index} className="">
                <input type="text" className="border border-gray-300 rounded-md p-2 w-full mx-3"
                  value={option} name="optionName" onChange={(e) => handleOptionChange(index, e)} placeholder="Option" />
              </div>
            ))}
          </div>
          <div className="mt-4">
            <h2 className="text-lg font-bold my-4">Option Items</h2>
            {optionItems.map((option, index) => (
              <div key={index} className="flex items-center border border-gray-300 rounded-md p-2 mb-2 bg-white">
                <input type="text" className="flex-grow bg-white outline-none"
                  value={option.optionName} name='optionName' onChange={(e) => { handleOptionChange(index, e) }}
                  placeholder="Option" disabled={index === 0} />
                <span className="flex items-center ml-2">
                  Additional Price:
                  <input type="text" className="ml-2 w-20 text-right bg-white outline-none"
                    value={option.additionalPrice} name='additionalPrice' onChange={(e) => { handleOptionChange(index, e) }}
                    placeholder="00" disabled={index === 0} />
                </span>
              </div>
            ))}
            <button type="button" className="text-gray-700 border border-gray-300 bg-gray-200 rounded-md p-2 w-full" onClick={addOption}>
              + Add another Option
            </button>
          </div>
          <div className="mt-6 flex justify-center">
            <button onClick={() => { setIsNextClicked(false); setIsSectionPage3(true); navigate('/menuhead?steps=MenuDetails&step1=3') }} className="bg-green text-white px-4 py-2 rounded w-3/12">
              Continue
            </button>
          </div>
        </div>
      ) : ''}

      {query.get('step1') === '1' ? (
        <div className="p-4 bg-gray-50 h-screen mb-8">
          <div className="mb-4 flex items-center justify-between">
            <h1 className="text-2xl font-bold mb-4">The Genie - Menu Creation Tool</h1>
            <button className="bg-green text-white px-4 py-2 rounded">
              Save Draft
            </button>
          </div>
          <div className="flex items-center">
            <BsArrowLeft onClick={onBack} className="text-green text-2xl cursor-pointer mx-2" />
            <span className="font-semibold">Categories</span>
          </div>
          <div className="mb-4 flex items-center mt-5">
            <input type="text" className="border border-gray-300 rounded-md p-2 w-full" value={item.name} readOnly />
          </div>
          <h2 className="text-base font-medium text-gray-700 my-4">Types</h2>
          <div className="mb-4">
            <div className="space-y-2 mb-2">
              <label className="inline-flex items-center">
                <input type="checkbox" className="form-checkbox text-green" checked={takeaway} onChange={handleTakeawayChange} />
                <span className="ml-2">Takeaway</span>
              </label>
            </div>
            <div className='space-y-2'>
              <label className="inline-flex items-center">
                <input type="checkbox" className="form-checkbox text-green" checked={delivery} onChange={handleDeliveryChange} />
                <span className="ml-2">Delivery</span>
              </label>
            </div>
          </div>
          <div className="mb-4 flex items-center">
            <span className="block text-base font-medium text-gray-700 mb-1">Same Price?</span>
            <div className="ml-4">
              <label className="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" className="sr-only" onChange={handleToggleChange} checked={isSamePrice} />
                <div className={`w-11 h-6 rounded-full ${isSamePrice ? 'bg-green' : 'bg-gray-200'} transition-colors`}>
                  <div className={`dot absolute left-1 top-1 w-4 h-4 rounded-full bg-white transition-transform ${isSamePrice ? 'translate-x-5' : ''}`}></div>
                </div>
              </label>
            </div>
          </div>
          <div className="mb-4 flex items-center space-x-4">
            <span className="text-base font-medium text-gray-700">Customizable?</span>
            <button className={`px-4 py-2 border rounded-md ${isCustomizable ? 'bg-green text-white' : 'bg-gray-300 text-black'}`} onClick={() => { handleCostamizable(true) }}>
              Yes
            </button>
            <button className={`px-4 py-2 border rounded-md ${!isCustomizable ? 'bg-green text-white' : 'bg-gray-300 text-black'}`} onClick={() => { handleCostamizable(false) }}>
              No
            </button>
          </div>
          <h2 className="text-lg font-bold my-4">Pricing Structure</h2>
          <div className="flex justify-start bg-white space-x-4 w-fit">
            <div className={`border-2 p-4 w-64 relative cursor-pointer 
          ${selectedBox === 1 ? 'border-green bg-lime-300' : 'border-green'}`}
              onClick={() => handleBoxSelect(1)}>
              <div className="relative mb-8">
                <div className="font-semibold">Option</div>
                <div className="absolute h-10 border-l-2 border-dotted border-black left-0 top-6"></div>
                <div className="absolute w-8 border-t-2 border-dotted border-black left-0 top-16"></div>
              </div>
              <div className="relative mb-8 ml-8">
                <div className="font-semibold ml-2">Variant</div>
                <div className="absolute h-10 border-l-2 border-dotted border-black left-0 top-6"></div>
                <div className="absolute w-8 border-t-2 border-dotted border-black left-0 top-16"></div>
              </div>
              <div className="relative ml-16">
                <div className="font-semibold ml-2">Add On</div>
              </div>
            </div>
            <div className={`border-2 p-4 w-64 relative cursor-pointer 
          ${selectedBox === 2 ? 'border-green bg-lime-300' : 'border-green'}`}
              onClick={() => handleBoxSelect(2)}>
              <div className="relative mb-8">
                <div className="font-semibold">Option</div>
                <div className="absolute h-10 border-l-2 border-dotted border-black left-0 top-6"></div>
              </div>
              <div className="relative mb-8 ">
                <div className="font-semibold ml-2">Variant</div>
                <div className="absolute h-10 border-l-2 border-dotted border-black left-0 top-6"></div>
              </div>
              <div className="relative">
                <div className="font-semibold ml-2">Add On</div>
              </div>
            </div>
          </div>
          <div className="mt-6 flex justify-center">
            <button onClick={() => { setIsNextClicked(true); setIsSectionPage3(false); navigate('/menuhead?steps=MenuDetails&step1=2') }} className="bg-green text-white px-4 py-2 rounded  w-3/12">
              Next
            </button>
          </div>
        </div>) : ''
      }
    </div>
  );
};
export default MenuDetails;