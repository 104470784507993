export const permissionStore = {
  permissions: null,

  setPermissions(data) {
    this.permissions = data;
  },

  getPermissions() {
    return this.permissions;
  },

  // Method to get a specific permission by key
  getPermission(key) {
    if (this.permissions && key in this.permissions) {
      return this.permissions[key];
    }
    return null; // Return null if the key doesn't exist or permissions are not set
  },
};

export default permissionStore;
