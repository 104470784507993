import React, { useState, useEffect } from "react";
import "./Payment.css";
import { fetchPayoutCycleInformation, fetchInvoices, fetchUTRReports, fetchTDSDocuments, fetchInvoiceCategories } from "../API/Api"
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const Payouts = () => {
  const [payoutData, setPayoutData] = useState([]);
  const [state, setState] = useState({
    showBreakup: false,
    downloadPop: false,
    showPicker: false,
    selectedRange: {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  });

  const restaurantId = localStorage.getItem('restaurantId');
  // Fetch Payout Cycle Information
  useEffect(() => {
    const fetchPayouts = async () => {
      try {
        const response = await fetchPayoutCycleInformation(restaurantId, 1, 10); // Provide restaurantId dynamically
        setPayoutData(response.payoutInfo || []);
      } catch (error) {
        console.error("Error fetching payouts:", error.message);
      }
    };
    fetchPayouts();
  }, []);

  return (
    <div className="payouts mt-1">
      <p className="ms-10 pt-3 font-semibold">Current Cycle</p>
      <p className="ms-10 mt-3 font-normal report">
        You are on the <span className="font-bold">weekly payout cycle</span> -
        payouts Monday-Sunday are credited to your account the following
        Wednesday
      </p>
      <table className="mt-5 mx-10 bg-white text-center rounded">
        <thead>
          <tr className="border-b">
            <td className="ps-8 py-5">Time period</td>
            <td className="ps-8 py-5">Orders</td>
            <td className="ps-8 py-5">Payout date</td>
            <td className="ps-8 py-5">Estimated payout</td>
          </tr>
        </thead>
        <tbody>
          {payoutData.length > 0 ? (
            payoutData.map((payout) => (
              <tr className="border-b" key={payout.id}>
                <td className="ps-8 py-5">
                  {`${payout.timePeriodStart} - ${payout.timePeriodEnd}`}
                </td>
                <td className="ps-8 py-5">{payout.numberOfOrders}</td>
                <td className="ps-8 py-5">{payout.payoutDate}</td>
                <td className="ps-8 py-5 estimation">₹{payout.estimatedPayout}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="ps-8 py-5 text-center">
                No payout data available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

const UTRReports = () => {
  const [utrReportsData, setUtrReportsData] = useState([]);
  const restaurantId = localStorage.getItem('restaurantId');

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const response = await fetchUTRReports(restaurantId, 1, 10); // Use actual restaurantId
        setUtrReportsData(response.urtReports || []);
      } catch (error) {
        console.error("Error fetching UTR reports:", error.message);
      }
    };
    fetchReports();
  }, []);

  return (
    <div className="utr-reports">
      <table className="min-w-full border-collapse border border-gray-200">
        <thead>
          <tr className="bg-green text-white">
            <th className="border border-gray-200 px-4 py-2">UTR Number</th>
            <th className="border border-gray-200 px-4 py-2">Net Payout</th>
            <th className="border border-gray-200 px-4 py-2">Payout Date</th>
            <th className="border border-gray-200 px-4 py-2">Status</th>
          </tr>
        </thead>
        <tbody>
          {utrReportsData.length > 0 ? (
            utrReportsData.map((report) => (
              <tr key={report.id} className="bg-white border-b border-gray-200">
                <td className="border border-gray-200 px-4 py-2 text-center">
                  {report.utrNumber}
                </td>
                <td className="border border-gray-200 px-4 py-2 text-center">
                  ₹{report.netPayout}
                </td>
                <td className="border border-gray-200 px-4 py-2 text-center">
                  {report.payoutDate}
                </td>
                <td className="border border-gray-200 px-4 py-2 text-center">
                  {report.payoutStatus}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="px-4 py-2 text-center">
                No UTR reports available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

const Invoices = () => {
  const [invoiceData, setInvoiceData] = useState([]);
  const [invoiceCategories, setInvoiceCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const restaurantId = localStorage.getItem("restaurantId");

  // Fetch Invoice Categories
  useEffect(() => {
    const fetchInvoiceCategoriesData = async () => {
      try {
        const response = await fetchInvoiceCategories(restaurantId);
        setInvoiceCategories(response.invoiceCategories || []);
      } catch (error) {
        console.error("Error fetching invoice categories:", error.message);
      }
    };
    fetchInvoiceCategoriesData();
  }, [restaurantId]);

  // Fetch Invoices for Selected Category
  const fetchInvoicesData = async (category) => {
    try {
      const response = await fetchInvoices(restaurantId, category, 1, 10); // Provide category and pagination
      setInvoiceData(response.invoices || []);
    } catch (error) {
      console.error("Error fetching invoices:", error.message);
    }
  };

  // Handle Category Selection
  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setSelectedCategory(category);
    if (category) {
      fetchInvoicesData(category);
    } else {
      setInvoiceData([]); // Clear invoices if no category is selected
    }
  };

  return (
    <div className="invoices flex flex-col justify-between p-4">
      {/* Dropdown for Invoice Categories */}
      <div className="mb-4">
        <label htmlFor="invoiceCategory" className="block text-lg font-semibold mb-2">
          Select Invoice Category
        </label>
        <select
          id="invoiceCategory"
          className="border border-gray-300 rounded-md p-2"
          value={selectedCategory}
          onChange={handleCategoryChange}
        >
          <option value="" className="">Select a Category</option>
          {invoiceCategories.map((category) => (
            <option key={category.id} value={category.name}>
              {category.name}
            </option>
          ))}
        </select>
      </div>

      {/* Invoice Table */}
      <table className="min-w-full border-collapse border border-gray-200">
        <thead>
          <tr className="bg-green text-white">
            <th className="border border-gray-200 px-4 py-2">Invoice ID</th>
            <th className="border border-gray-200 px-4 py-2">Generation Date</th>
            <th className="border border-gray-200 px-4 py-2">Status</th>
            <th className="border border-gray-200 px-4 py-2">Type</th>
          </tr>
        </thead>
        <tbody>
          {invoiceData.length > 0 ? (
            invoiceData.map((invoice) => (
              <tr key={invoice.id} className="bg-white border-b border-gray-200">
                <td className="border border-gray-200 px-4 py-2 text-center">
                  {invoice.id}
                </td>
                <td className="border border-gray-200 px-4 py-2 text-center">
                  {invoice.invoiceGenerationDate}
                </td>
                <td className="border border-gray-200 px-4 py-2 text-center">
                  {invoice.invoiceStatus}
                </td>
                <td className="border border-gray-200 px-4 py-2 text-center">
                  {invoice.invoiceType}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="px-4 py-2 text-center">
                No invoices available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

const Taxes = () => {
  const [tdsData, setTdsData] = useState([]);
  const restaurantId = localStorage.getItem('restaurantId');

  useEffect(() => {
    const fetchTDSData = async () => {
      try {
        const response = await fetchTDSDocuments(restaurantId, 1, 10);
        setTdsData(response.tdsDocuments || []);
      } catch (error) {
        console.error("Error fetching TDS documents:", error.message);
      }
    };
    fetchTDSData();
  }, []);

  return (
    <div className="taxes">
      <table className="min-w-full border-collapse border border-gray-200">
        <thead>
          <tr className="bg-green text-white">
            <th className="border border-gray-200 px-4 py-2">ID</th>
            <th className="border border-gray-200 px-4 py-2">Quarter</th>
            <th className="border border-gray-200 px-4 py-2">Date Range</th>
          </tr>
        </thead>
        <tbody>
          {tdsData.length > 0 ? (
            tdsData.map((tds) => (
              <tr key={tds.id} className="bg-white border-b border-gray-200">
                <td className="border border-gray-200 px-4 py-2 text-center">
                  {tds.id}
                </td>
                <td className="border border-gray-200 px-4 py-2 text-center">
                  {tds.financialQuarter}
                </td>
                <td className="border border-gray-200 px-4 py-2 text-center">
                  {tds.startDate} - {tds.endDate}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3" className="px-4 py-2 text-center">
                No TDS documents available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

const Payment = () => {
  const [state, setState] = useState({
    paymentButton: "Payouts",
    payoutInfo: { date: "17 Jun - 23 Jun, 24" },
    showBreakup: false,
    downloadPop: false
  });

  // Handle button selection change
  const handleButtonClick = (buttonName) => {
    setState((prevState) => ({
      ...prevState,
      paymentButton: buttonName
    }));
  };

  // Toggle visibility of the payout breakup section
  const showBreakupFun = () => {
    setState((prevState) => ({
      ...prevState,
      showBreakup: !prevState.showBreakup
    }));
  };
  // Toggle visibility of the download popup
  const downloadPayout = () => {
    setState((prevState) => ({
      ...prevState,
      downloadPop: !prevState.downloadPop
    }));
  };

  return (
    <div className="payment">
      <div className="border-b-2 border-gray-300 shadow">
        <h3 className="heading text-xl font-bold p-3">Payout</h3>
      </div>
      <hr className="divider" />
      <div className="payment-btns ms-10 mt-5">
        <button
          className={`mr-16 pb-2 mb-4 font-semibold  ${state.paymentButton === "Payouts" && "payment-btn"
            }`}
          onClick={() => handleButtonClick("Payouts")}
        >
          Payouts
        </button>
        <button
          className={`mr-16 pb-2 mb-4 font-semibold  ${state.paymentButton === "utrReports" && "payment-btn"
            }`}
          onClick={() => handleButtonClick("utrReports")}
        >
          UTR Reports
        </button>
        <button
          className={`mr-16 pb-2 mb-4 font-semibold  ${state.paymentButton === "inVoices" && "payment-btn"
            }`}
          onClick={() => handleButtonClick("inVoices")}
        >
          Invoices
        </button>

        <button
          className={`mr-16 pb-2 mb-4 font-semibold  ${state.paymentButton === "taxes" && "payment-btn"
            }`}
          onClick={() => handleButtonClick("taxes")}
        >
          Taxes
        </button>
      </div>

      <hr className="divider" />

      {state.paymentButton === "Payouts" && (
        <Payouts
          payoutInfo={state.payoutInfo}
          showBreakup={state.showBreakup}
          showBreakupFun={state.showBreakupFun}
          downloadPayout={downloadPayout}
          downloadPop={state.downloadPop}
        />
      )}
      {state.paymentButton === "utrReports" && <UTRReports />}
      {state.paymentButton === "inVoices" && <Invoices />}
      {state.paymentButton === "taxes" && <Taxes />}
    </div>
  );
};

export default Payment;
