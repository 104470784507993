import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import "./TrackOffer.css";
import { faExclamation } from "@fortawesome/free-solid-svg-icons/faExclamation";
import Createoffer from "./Createoffer";
import { fetchCurrentUTCTime } from "../API/AuthApi";
import RolesAndPermissions from "./RolesAndPermissions";
import Location from "./Location";
import { useNavigate } from "react-router-dom";

const TrackOffer = () => {

    const navigate = useNavigate();

    useEffect(() => {
        const handleBackNavigation = (event) => {
            event.preventDefault();
            navigate("/TrackOffer", { replace: true }); // Redirect to TrackOffer
        };

        // Add event listener to disable back button
        window.history.pushState(null, null, window.location.href);
        window.addEventListener("popstate", handleBackNavigation);

        return () => {
            window.removeEventListener("popstate", handleBackNavigation);
        };
    }, [navigate]);

    const [state, setState] = useState({
        createOffer: false,
        trackOffer: false,
        locationTab: false,
        activeButton: "Active",
        percentage: 50,
        price: 100,
        date: 5,
        month: "May",
        year: 2024,
        sales: "3,07,847",
        orders: 145,
        disPrice: "",
        offerVisible: true,
        perfomanceDay: "yesterday",
        minPrice: 149,
        hotel: "Happy Bites",
        area: "Alagapuram Pudur",
        stopOfferBtn: false,
        restaurantId: localStorage.getItem("restaurantId"),
    });

    const { createOffer, trackOffer, locationTab, activeButton, percentage, price, date, month, year, sales, orders, offerVisible, perfomanceDay, minPrice, hotel, area, stopOfferBtn } = state;

    useEffect(() => {
        const syncTime = async () => {
            try {
                const response = await fetchCurrentUTCTime();  // Fetch the UTC time from API
                const utcTime = response.data.utcTime;
                const utcTimestamp = new Date(utcTime);  // Convert fetched time to Date object
                localStorage.setItem("currentTime", utcTimestamp.getTime().toString()); // Store timestamp in milliseconds
            } catch (error) {
                console.error("Failed to sync time:", error);
            }
        };
        syncTime(); // Sync the time initially
    }, []);

    function createOfferDetails() {
        setState(prevState => ({
            ...prevState,
            createOffer: true,
            trackOffer: false,
            locationTab: false
        }));
    }

    function trackOfferDetails() {
        setState(prevState => ({
            ...prevState,
            trackOffer: true,
            createOffer: false,
            locationTab: false
        }));
    }

    function locationDetails() {
        setState(prevState => ({
            ...prevState,
            locationTab: true, // Activate Location tab
            createOffer: false,
            trackOffer: false // Deactivate other tabs when this is selected
        }));
    }

    const handleButtonClick = (buttonName) => {
        setState(prevState => ({
            ...prevState,
            activeButton: buttonName
        }));
    };

    function offerDisplaySymbolBtn() {
        setState(prevState => ({
            ...prevState,
            offerVisible: !prevState.offerVisible
        }));
    }

    function stopOfferPopup() {
        setState(prevState => ({
            ...prevState,
            stopOfferBtn: true
        }));
    }

    function closeOfferPopup() {
        setState(prevState => ({
            ...prevState,
            stopOfferBtn: false
        }));
    }

    return (
        <div className="trackOffer">
            <RolesAndPermissions />
            <div className="border-b-2 border-gray-300 shadow">
                <h3 className="heading text-xl font-bold p-3">Offers</h3>
            </div>
            <hr className="divider" />
            <div className="offer_headings flex items-center overflow-hidden">
                <p className={`heading font-semibold ms-10 me-14 mt-7 mb-2 ${createOffer && "createOffer_decoration"}`} onClick={createOfferDetails}>Create Offers</p>
                <p className={`heading font-semibold mt-7 mb-2 ${trackOffer && "track_decoration"}`} onClick={trackOfferDetails}>Track Offers</p>
                <p className={`heading font-semibold mt-7 mb-2 px-10 ${locationTab && "location_decoration"}`} onClick={locationDetails}>Location</p> {/* Added new tab */}
            </div>
            <hr className="divider" />
            {trackOffer ? (
                <>
                    <div className="trackOffer_btns ms-10 mt-7">
                        <button className={`rounded-md px-14 py-2 mr-12 ${activeButton === "Active" && "active-btn"}`} onClick={() => handleButtonClick("Active")}>Active</button>
                        <button className={`rounded-md px-14 py-2 mr-12 ${activeButton === "Scheduled" && "active-btn"}`} onClick={() => handleButtonClick("Scheduled")}>Scheduled</button>
                        <button className={`rounded-md px-14 py-2 mr-12 ${activeButton === "InActive" && "active-btn"}`} onClick={() => handleButtonClick("InActive")}>InActive</button>
                        <button className={`rounded-md px-14 py-2 mr-12 ${activeButton === "All" && "active-btn"}`} onClick={() => handleButtonClick("All")}>
                            All
                        </button>
                    </div>
                    {trackOffer && activeButton === "Active" && (
                        <div className="normalOffer bg-white rounded-md flex justify-between mt-4 mx-10 p-4">
                            <div className="left text-black font-normal">
                                <p>
                                    {percentage}% OFF Upto ₹{price}
                                </p>
                                <p className="date mt-2">
                                    Start Date: {date}th {month} {year}
                                </p>
                            </div>
                            <div className="right font-bold">
                                <button className="new_user rounded-md text-white px-5 p-1.5 me-2">
                                    New Users Only
                                </button>
                                <button className="active rounded-md px-5 py-1.5">
                                    Active
                                </button>
                            </div>
                        </div>
                    )}

                    {/*Active Offer Details */}
                    {trackOffer && activeButton === "Active" && (
                        <div className="offerInfo bg-white rounded-md mx-10 mt-4">
                            <div className="offer_boxes flex justify-between mx-3 mt-2">
                                <div className="box rounded-md ps-4 py-4">
                                    <p className="font-bold count">₹{sales}</p>
                                    <p className="font-normal count_head mt-1.5">Gross Sales</p>
                                </div>
                                <div className="box rounded-md ps-4 py-4">
                                    <p className="font-bold count">{orders}</p>
                                    <p className="font-normal count_head mt-1.5">
                                        Orders Delivered
                                    </p>
                                </div>
                                <div className="box rounded-md ps-4 py-4">
                                    <p className="font-bold count">₹1,23,567</p>
                                    <p className="font-normal count_head mt-1.5">
                                        Discount Given
                                    </p>
                                </div>
                                <div className="box rounded-md ps-4 py-4">
                                    <p className="font-bold count">67%</p>
                                    <p className="font-normal count_head mt-1.5">
                                        Effective Discount %
                                    </p>
                                </div>
                            </div>
                            {offerVisible && (
                                <div className="offerDetails" id="ds">
                                    <p className="mt-5 ms-5">
                                        Performance updated till {perfomanceDay}
                                    </p>
                                    <p className="mt-5 ms-5">
                                        Offer applicable for:New users on all menu items,excluding
                                        MRP items
                                    </p>
                                    <p className="mt-5 ms-5">Minimum order value:₹{minPrice}</p>
                                    <p className="mt-5 ms-5">
                                        Valid at: {hotel}, {area}
                                    </p>
                                    <p className="mt-5 ms-5">
                                        Offer sharing:100% of the discount value is being funded by
                                        you
                                    </p>
                                    <button
                                        className="rounded-md w-full text-center mt-5 ms-5 py-1"
                                        onClick={stopOfferPopup}
                                    >
                                        Stop Offer
                                    </button>
                                </div>
                            )}
                            <hr className="divider my-2 " />
                            <div className="flex items-center justify-center mb-2">
                                {offerVisible ? (
                                    <button onClick={offerDisplaySymbolBtn}>
                                        <FontAwesomeIcon icon={faAngleUp}></FontAwesomeIcon>
                                    </button>
                                ) : (
                                    <button onClick={offerDisplaySymbolBtn}>
                                        <FontAwesomeIcon icon={faAngleDown}></FontAwesomeIcon>
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                    {/* Stop  */}

                    {stopOfferBtn && (
                        <div className="flex items-center justify-center fixed top-0 left-0 right-0 bottom-0 backdrop-blur-3xl ">
                            <div className="stopOffer bg-white rounded-md text-center">
                                <div className="px-6 pt-5">
                                    <FontAwesomeIcon
                                        icon={faExclamation}
                                        className="exclamation"
                                    ></FontAwesomeIcon>
                                    <h2 className="font-bold mt-2">
                                        Are you sure you want to stop this Offer?
                                    </h2>
                                    <p className="mt-2">
                                        You have earned ₹32,253 of revenue with 110 order from this
                                        offer. If you want to stop this offer, it will stop at 19th
                                        June, 2024.
                                    </p>
                                    <p className="mt-2 mb-3">
                                        We will send deactivation confirmation on your email and
                                        phone number
                                    </p>
                                </div>
                                <div className="mt-3">
                                    <hr className="divider" />
                                    <button className="text-sky-500 my-3" onClick={closeOfferPopup}>Yes, Stop</button>
                                    <hr className="divider" />
                                    <button className="my-3" onClick={closeOfferPopup}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    {trackOffer && activeButton === "Scheduled" && (
                        <div className="normalOffer bg-white rounded-md flex justify-between mt-4 mx-10 p-4">
                            <div className="left text-black font-normal">
                                <p>
                                    {percentage}% OFF Upto ₹{price}
                                </p>
                                <p className="date mt-2">
                                    Start Date: {date}th {month} {year}
                                </p>
                            </div>
                            <div className="right font-bold">
                                <button className="new_user rounded-md text-white px-5 p-1.5 me-2">
                                    New Users Only
                                </button>
                                <button className="active rounded-md px-5 py-1.5">
                                    Active
                                </button>
                            </div>
                        </div>
                    )}

                    {trackOffer && activeButton === "Scheduled" && (
                        <div className="offerInfo bg-white rounded-md mx-10 mt-4">
                            <div className="offer_boxes flex justify-between mx-3 mt-2">
                                <div className="box rounded-md ps-4 py-4">
                                    <p className="font-bold count">₹{sales}</p>
                                    <p className="font-normal count_head mt-1.5">Gross Sales</p>
                                </div>
                                <div className="box rounded-md ps-4 py-4">
                                    <p className="font-bold count">{orders}</p>
                                    <p className="font-normal count_head mt-1.5">
                                        Orders Delivered
                                    </p>
                                </div>
                                <div className="box rounded-md ps-4 py-4">
                                    <p className="font-bold count">₹1,23,567</p>
                                    <p className="font-normal count_head mt-1.5">
                                        Discount Given
                                    </p>
                                </div>
                                <div className="box rounded-md ps-4 py-4">
                                    <p className="font-bold count">67%</p>
                                    <p className="font-normal count_head mt-1.5">
                                        Effective Discount %
                                    </p>
                                </div>
                            </div>
                            {offerVisible && (
                                <div className="offerDetails" id="ds">
                                    <p className="mt-5 ms-5">
                                        Performance updated till {perfomanceDay}
                                    </p>
                                    <p className="mt-5 ms-5">
                                        Offer applicable for:New users on all menu items,excluding
                                        MRP items
                                    </p>
                                    <p className="mt-5 ms-5">Minimum order value:₹{minPrice}</p>
                                    <p className="mt-5 ms-5">
                                        Valid at: {hotel}, {area}
                                    </p>
                                    <p className="mt-5 ms-5">
                                        Offer sharing:100% of the discount value is being funded by
                                        you
                                    </p>
                                    <button
                                        className="rounded-md w-full text-center mt-5 ms-5 py-1"
                                        onClick={stopOfferPopup}
                                    >
                                        Stop Offer
                                    </button>
                                </div>
                            )}
                            <hr className="divider my-2 " />
                            <div className="flex items-center justify-center mb-2">
                                {offerVisible ? (
                                    <button onClick={offerDisplaySymbolBtn}>
                                        <FontAwesomeIcon icon={faAngleUp}></FontAwesomeIcon>
                                    </button>
                                ) : (
                                    <button onClick={offerDisplaySymbolBtn}>
                                        <FontAwesomeIcon icon={faAngleDown}></FontAwesomeIcon>
                                    </button>
                                )}
                            </div>
                        </div>
                    )}


                    {trackOffer && activeButton === "InActive" && (
                        <div className="normalOffer bg-white rounded-md flex justify-between mt-4 mx-10 p-4">
                            <div className="left text-black font-normal">
                                <p>
                                    {percentage}% OFF Upto ₹{price}
                                </p>
                                <p className="date mt-2">
                                    Start Date: {date}th {month} {year}
                                </p>
                            </div>
                            <div className="right font-bold">
                                <button className="new_user rounded-md text-white px-5 p-1.5 me-2">
                                    New Users Only
                                </button>
                                <button className="active rounded-md px-5 py-1.5">
                                    Active
                                </button>
                            </div>
                        </div>
                    )}

                    {trackOffer && activeButton === "InActive" && (
                        <div className="offerInfo bg-white rounded-md mx-10 mt-4">
                            <div className="offer_boxes flex justify-between mx-3 mt-2">
                                <div className="box rounded-md ps-4 py-4">
                                    <p className="font-bold count">₹{sales}</p>
                                    <p className="font-normal count_head mt-1.5">Gross Sales</p>
                                </div>
                                <div className="box rounded-md ps-4 py-4">
                                    <p className="font-bold count">{orders}</p>
                                    <p className="font-normal count_head mt-1.5">
                                        Orders Delivered
                                    </p>
                                </div>
                                <div className="box rounded-md ps-4 py-4">
                                    <p className="font-bold count">₹1,23,567</p>
                                    <p className="font-normal count_head mt-1.5">
                                        Discount Given
                                    </p>
                                </div>
                                <div className="box rounded-md ps-4 py-4">
                                    <p className="font-bold count">67%</p>
                                    <p className="font-normal count_head mt-1.5">
                                        Effective Discount %
                                    </p>
                                </div>
                            </div>
                            {offerVisible && (
                                <div className="offerDetails" id="ds">
                                    <p className="mt-5 ms-5">
                                        Performance updated till {perfomanceDay}
                                    </p>
                                    <p className="mt-5 ms-5">
                                        Offer applicable for:New users on all menu items,excluding
                                        MRP items
                                    </p>
                                    <p className="mt-5 ms-5">Minimum order value:₹{minPrice}</p>
                                    <p className="mt-5 ms-5">
                                        Valid at: {hotel}, {area}
                                    </p>
                                    <p className="mt-5 ms-5">
                                        Offer sharing:100% of the discount value is being funded by
                                        you
                                    </p>
                                    <button
                                        className="rounded-md w-full text-center mt-5 ms-5 py-1"
                                        onClick={stopOfferPopup}
                                    >
                                        Stop Offer
                                    </button>
                                </div>
                            )}
                            <hr className="divider my-2 " />
                            <div className="flex items-center justify-center mb-2">
                                {offerVisible ? (
                                    <button onClick={offerDisplaySymbolBtn}>
                                        <FontAwesomeIcon icon={faAngleUp}></FontAwesomeIcon>
                                    </button>
                                ) : (
                                    <button onClick={offerDisplaySymbolBtn}>
                                        <FontAwesomeIcon icon={faAngleDown}></FontAwesomeIcon>
                                    </button>
                                )}
                            </div>
                        </div>
                    )}

                    {trackOffer && activeButton === "All" && (
                        <div className="normalOffer bg-white rounded-md flex justify-between mt-4 mx-10 p-4">
                            <div className="left text-black font-normal">
                                <p>
                                    {percentage}% OFF Upto ₹{price}
                                </p>
                                <p className="date mt-2">
                                    Start Date: {date}th {month} {year}
                                </p>
                            </div>
                            <div className="right font-bold">
                                <button className="new_user rounded-md text-white px-5 p-1.5 me-2">
                                    New Users Only
                                </button>
                                <button className="active rounded-md px-5 py-1.5">
                                    Active
                                </button>
                            </div>
                        </div>
                    )}

                    {trackOffer && activeButton === "All" && (
                        <div className="offerInfo bg-white rounded-md mx-10 mt-4">
                            <div className="offer_boxes flex justify-between mx-3 mt-2">
                                <div className="box rounded-md ps-4 py-4">
                                    <p className="font-bold count">₹{sales}</p>
                                    <p className="font-normal count_head mt-1.5">Gross Sales</p>
                                </div>
                                <div className="box rounded-md ps-4 py-4">
                                    <p className="font-bold count">{orders}</p>
                                    <p className="font-normal count_head mt-1.5">
                                        Orders Delivered
                                    </p>
                                </div>
                                <div className="box rounded-md ps-4 py-4">
                                    <p className="font-bold count">₹1,23,567</p>
                                    <p className="font-normal count_head mt-1.5">
                                        Discount Given
                                    </p>
                                </div>
                                <div className="box rounded-md ps-4 py-4">
                                    <p className="font-bold count">67%</p>
                                    <p className="font-normal count_head mt-1.5">
                                        Effective Discount %
                                    </p>
                                </div>
                            </div>
                            {offerVisible && (
                                <div className="offerDetails" id="ds">
                                    <p className="mt-5 ms-5">
                                        Performance updated till {perfomanceDay}
                                    </p>
                                    <p className="mt-5 ms-5">
                                        Offer applicable for:New users on all menu items,excluding
                                        MRP items
                                    </p>
                                    <p className="mt-5 ms-5">Minimum order value:₹{minPrice}</p>
                                    <p className="mt-5 ms-5">
                                        Valid at: {hotel}, {area}
                                    </p>
                                    <p className="mt-5 ms-5">
                                        Offer sharing:100% of the discount value is being funded by
                                        you
                                    </p>
                                    <button
                                        className="rounded-md w-full text-center mt-5 ms-5 py-1"
                                        onClick={stopOfferPopup}
                                    >
                                        Stop Offer
                                    </button>
                                </div>

                            )}
                            <hr className="divider my-2 " />
                            <div className="flex items-center justify-center mb-2">
                                {offerVisible ? (
                                    <button onClick={offerDisplaySymbolBtn}>
                                        <FontAwesomeIcon icon={faAngleUp}></FontAwesomeIcon>
                                    </button>
                                ) : (
                                    <button onClick={offerDisplaySymbolBtn}>
                                        <FontAwesomeIcon icon={faAngleDown}></FontAwesomeIcon>
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                    <div>

                    </div>

                </>
            ) : createOffer ? (
                <>
                    <Createoffer />

                </>
            ) : locationTab ? (
                <Location />
            ) : <Createoffer />}

        </div>
    );
};

export default TrackOffer;