import React from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import nopayout from "../assets/nopayout.webp";
import { useNavigate } from "react-router-dom";

function Order() {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("orders");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <div className="order-history mt-1 overflow-x-hidden">
        <button onClick={goBack} className="ml-17 mt-5">
          <FontAwesomeIcon icon={faArrowLeftLong} className="mr-2" />
          Back
        </button>
        <div className="tabs mt-5">
          <button
            className={`px-4 py-2 font-semibold ${activeTab === "orders"
                ? "bg-green rounded text-white"
                : "bg-gray-200 rounded"
              }`}
            onClick={() => handleTabClick("orders")}
          >
            Orders
          </button>
          <button
            className={`px-4 py-2 ml-10 font-semibold ${activeTab === "expenses"
                ? "bg-green rounded text-white"
                : "bg-gray-200 rounded"
              }`}
            onClick={() => handleTabClick("expenses")}
          >
            Expenses
          </button>
        </div>

        <div className="content mt-5 ml-10">
          {activeTab === "orders" ? (
            <div className="no-orders flex flex-col items-center">
              <img src={nopayout} alt="No orders found" className="h-20 mt-4" />
              <p>No orders found</p>
            </div>
          ) : (
            <div className="no-expenses flex flex-col items-center">
              <img
                src={nopayout}
                alt="No expenses found"
                className="h-20 mt-4"
              />
              <p>No expenses found</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Order;
