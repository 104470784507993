// import React, { useEffect, useState } from 'react';
// import { fetchMenuDetails } from '../../API/Api'; // Assuming the API is defined here
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCaretDown, faCaretRight, faEdit, faImage, faIndianRupeeSign, faLeaf, faTrash } from '@fortawesome/free-solid-svg-icons';
// import { useLocation, useNavigate } from 'react-router-dom';

// export function DeliveryMenu({ setMenuDataForApi, setDeletedItems, onItemClick }) {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [menuData, setMenuData] = useState([]);
//   const [activeCategoryId, setActiveCategoryId] = useState(null);
//   const [activeSubCategoryId, setActiveSubCategoryId] = useState(null);
//   const [expandedCategories, setExpandedCategories] = useState(new Set());
//   const [expandedSubCategories, setExpandedSubCategories] = useState(new Set());
//   const [newCategoryName, setNewCategoryName] = useState('');
//   const [newSubCategoryName, setNewSubCategoryName] = useState('');
// const [newItemName, setNewItemName] = useState('');
// const [newItemPrice, setNewItemPrice] = useState('');
//   const [editingItem, setEditingItem] = useState(null);

//   useEffect(() => {
//     const fetchMenu = async () => {
//       try {
//         const restaurantId = localStorage.getItem('restaurantId'); // Assuming restaurant ID is stored in localStorage
//         const data = await fetchMenuDetails(restaurantId); // Fetch menu details API
//         setMenuData(data.menuCategories || []);
//         console.log("Menu Data:", data.menuCategories);
//         navigate(location.pathname, { state: data }); // Store the API response in location state
//       } catch (error) {
//         console.error("Error fetching menu details:", error.response?.data || error.message);
//       }
//     };
//     fetchMenu();
//   }, [navigate, location.pathname]);

//   const handleCategoryClick = (categoryId) => {
//     setActiveCategoryId(activeCategoryId === categoryId ? null : categoryId);
//     setExpandedCategories(prev => {
//       const newSet = new Set(prev);
//       newSet.has(categoryId) ? newSet.delete(categoryId) : newSet.add(categoryId);
//       return newSet;
//     });
//   };

//   const handleSubCategoryClick = (subCategoryId) => {
//     setActiveSubCategoryId(activeSubCategoryId === subCategoryId ? null : subCategoryId);
//     setExpandedSubCategories(prev => {
//       const newSet = new Set(prev);
//       newSet.has(subCategoryId) ? newSet.delete(subCategoryId) : newSet.add(subCategoryId);
//       return newSet;
//     });
//   };

//   const handleAddCategory = () => {
//     const newCategory = {
//       id: Date.now().toString(),
//       name: newCategoryName,
//       sortOrder: menuData.length + 1,
//       MenuSubCategory: [],
//     };
//     setMenuData([...menuData, newCategory]);
//     setNewCategoryName('');
//   };

//   const handleAddSubCategory = () => {
//     const updatedCategories = menuData.map((category) =>
//       category.id === activeCategoryId
//         ? {
//           ...category,
//           MenuSubCategory: [
//             ...category.MenuSubCategory,
//             {
//               id: Date.now().toString(),
//               name: newSubCategoryName,
//               sortOrder: category.MenuSubCategory.length + 1,
//               MenuItemSubCategoryLookup: [],
//             },
//           ],
//         }
//         : category
//     );
//     setMenuData(updatedCategories);
//     setNewSubCategoryName('');
//   };

//   const handleAddItem = () => {
//     const updatedCategories = menuData.map((category) =>
//       category.id === activeCategoryId
//         ? {
//           ...category,
//           MenuSubCategory: category.MenuSubCategory.map((subCat) =>
//             subCat.id === activeSubCategoryId
//               ? {
//                 ...subCat,
//                 MenuItemSubCategoryLookup: [
//                   ...subCat.MenuItemSubCategoryLookup,
//                   {
//                     id: Date.now().toString(),
//                     name: newItemName,
//                     basePrice: newItemPrice,
//                     sortOrder: subCat.MenuItemSubCategoryLookup.length + 1,
//                   },
//                 ],
//               }
//               : subCat
//           ),
//         }
//         : category
//     );
//     setMenuData(updatedCategories);
//     setNewItemName('');
//     setNewItemPrice('');
//   };

//   const handleEditCategory = (categoryId) => {
//     // const updatedCategories = menuData.filter((category) => category.id !== categoryId);
//     // setMenuData(updatedCategories);
//     // setDeletedItems((prev) => [...prev, { type: 'category', id: categoryId }]);
//   };

//   const handleDeleteCategory = (categoryId) => {
//     // const updatedCategories = menuData.filter((category) => category.id !== categoryId);
//     // setMenuData(updatedCategories);
//     // setDeletedItems((prev) => [...prev, { type: 'category', id: categoryId }]);
//   };

//   const handleEditSubCategory = (subCategoryId) => {
//     const updatedCategories = menuData.map((category) =>
//       category.id === activeCategoryId
//         ? {
//           ...category,
//           MenuSubCategory: category.MenuSubCategory.filter((subCat) => subCat.id !== subCategoryId),
//         }
//         : category
//     );
//     setMenuData(updatedCategories);
//     setDeletedItems((prev) => [...prev, { type: 'subcategory', id: subCategoryId }]);
//   };

//   const handleDeleteSubCategory = (subCategoryId) => {
//     const updatedCategories = menuData.map((category) =>
//       category.id === activeCategoryId
//         ? {
//           ...category,
//           MenuSubCategory: category.MenuSubCategory.filter((subCat) => subCat.id !== subCategoryId),
//         }
//         : category
//     );
//     setMenuData(updatedCategories);
//     setDeletedItems((prev) => [...prev, { type: 'subcategory', id: subCategoryId }]);
//   };

//   return (
//     <div className="flex flex-col">
//       <div className="mx-4 my-4 mt-2 p-4 bg-green rounded-md">
//         <div className="flex items-center">
//           <FontAwesomeIcon icon={faCaretDown} className="mr-2 text-white" />
//           <span className="text-xl font-semibold text-white">Starters</span>
//         </div>
//         <div className="flex items-center mt-2 text-white">
//           <div className="flex items-center">
//             <FontAwesomeIcon icon={faLeaf} className="mr-2" />
//             <span className="text-lg">Paneer Tikka</span>
//           </div>
//           <div className="flex items-center mx-8">
//             <FontAwesomeIcon icon={faImage} className="mr-2" />
//             <FontAwesomeIcon icon={faIndianRupeeSign} className="mr-1" />
//             <span className="text-lg">200</span>
//           </div>
//         </div>
//       </div>
//       <div className="items-center my-2 mx-3">
//         <div>
//           {menuData.map((category) => (
//             <div key={category.id} className='border-b border-gray-200'>
//               <div className='flex items-center p-3 hover:bg-gray-100'>
//                 <button
//                   onClick={() => handleCategoryClick(category.id)}
//                   className="text-left flex items-center py-2 px-1 w-full"
//                 >
//                   {expandedCategories.has(category.id) ? (
//                     <FontAwesomeIcon icon={faCaretDown} className='text-2xl' />
//                   ) : (
//                     <FontAwesomeIcon icon={faCaretRight} className='text-2xl' />
//                   )}
//                   {category.name}
//                 </button>
//                 <div className='flex items-center gap-4'>
//                   <div className="ml-2">
//                     <button onClick={() => handleEditCategory(category.id)} className='text-blue-500'>
//                       <FontAwesomeIcon icon={faEdit} />
//                     </button>
//                   </div>
//                   <div className="ml-2">
//                     <button onClick={() => handleDeleteCategory(category.id)} className='text-red'>
//                       <FontAwesomeIcon icon={faTrash} />
//                     </button>
//                   </div>
//                 </div>
//               </div>
//               {expandedCategories.has(category.id) && (
//                 <div className="ml-4">
//                   {category.MenuSubCategory.map((subCategory) => (
//                     <div key={subCategory.id}>
//                       <div className='flex items-center p-3 hover:bg-gray-100'>
//                         <button
//                           onClick={() => handleSubCategoryClick(subCategory.id)}
//                           className="text-left flex items-center py-2 w-full"
//                         >
//                           {expandedSubCategories.has(subCategory.id) ? (
//                             <FontAwesomeIcon icon={faCaretDown} className='text-2xl' />
//                           ) : (
//                             <FontAwesomeIcon icon={faCaretRight} className='text-2xl' />
//                           )}
//                           {newSubCategoryName || 'Unnamed Subcategory'}
//                         </button>
//                         <div className='flex items-center gap-4'>
//                           <div className="ml-2">
//                             <button onClick={() => handleEditSubCategory(subCategory.id)} className='text-blue-500'>
//                               <FontAwesomeIcon icon={faEdit} />
//                             </button>
//                           </div>
//                           <div className="ml-2">
//                             <button onClick={() => handleDeleteSubCategory(subCategory.id)} className='text-red'>
//                               <FontAwesomeIcon icon={faTrash} />
//                             </button>
//                           </div>
//                         </div>
//                       </div>
//                       {expandedSubCategories.has(subCategory.id) && (
//                         <div className="ml-4">
//                           {subCategory.MenuItemSubCategoryLookup.map((itemLookup) => {
//                             const menuItem = itemLookup.MenuItem; // Extract the MenuItem object
//                             const activeRevision = menuItem?.MenuItemRevision?.find(
//                               (revision) => revision.isActiveRevision
//                             ); // Get the active revision

//                             if (!activeRevision) return null; // Skip if no active revision

//                             return (
//                               <div key={itemLookup.id} className="p-2 border-b">
//                                 <div className="flex justify-between items-center">
//                                   <div className='flex items-center'>

//                                     {/* <p className="text-sm text-gray-600">{activeRevision.description}</p> */}
//                                     <img
//                                       src={activeRevision.imageUrl}
//                                       alt={activeRevision.name}
//                                       className="mt-2 w-10 h-10 object-cover rounded-md"
//                                     />
//                                     <span className="text-base mx-5 font-medium">{activeRevision.name}</span>
//                                   </div>
//                                   <div className="flex items-center gap-4">
//                                     <span className="text-green-600 font-bold">
//                                       ₹{activeRevision.basePrice}
//                                     </span>
//                                     <button
//                                       // onClick={() => handleEditItem(menuItem.id)} // Edit functionality
//                                       className="text-blue-500 mx-2"
//                                     >
//                                       <FontAwesomeIcon icon={faEdit} />
//                                     </button>
//                                     <button
//                                       // onClick={() => handleDeleteItem(menuItem.id)} // Delete functionality
//                                       className="text-red"
//                                     >
//                                       <FontAwesomeIcon icon={faTrash} />
//                                     </button>
//                                   </div>
//                                 </div>
//                               </div>
//                             );
//                           })}
//                           <div>
//                             <input
//                               type="text"
//                               placeholder="New Item Name"
//                               value={newItemName}
//                               onChange={(e) => setNewItemName(e.target.value)}
//                               className="mr-2 p-2 border border-gray-300 rounded"
//                             />
//                             <input
//                               type="text"
//                               placeholder="New Item Price"
//                               value={newItemPrice}
//                               onChange={(e) => setNewItemPrice(e.target.value)}
//                               className="mr-2 p-2 border border-gray-300 rounded"
//                             />
//                             <button
//                               onClick={handleAddItem} // Add item functionality
//                               className="bg-green text-white px-3 py-2 rounded-md"
//                             >
//                               Add Item
//                             </button>
//                           </div>
//                         </div>
//                       )}

//                     </div>
//                   ))}
//                   <div className="mt-2">
//                     <input
//                       type="text"
//                       placeholder="New SubCategory Name"
//                       value={newSubCategoryName}
//                       onChange={(e) => setNewSubCategoryName(e.target.value)}
//                       className="mr-2 p-2 border border-gray-300 rounded"
//                     />
//                     <button
//                       onClick={handleAddSubCategory}
//                       className="bg-green text-white px-3 py-2 rounded-md"
//                     >
//                       Add SubCategory
//                     </button>
//                   </div>
//                 </div>
//               )}
//             </div>
//           ))}
//           <div className="mt-4">
//             <input
//               type="text"
//               placeholder="New Category Name"
//               value={newCategoryName}
//               onChange={(e) => setNewCategoryName(e.target.value)}
//               className="mr-2 p-2 border border-gray-300 rounded"
//             />
//             <button
//               onClick={handleAddCategory}
//               className="bg-green text-white px-3 py-2 rounded-md"
//             >
//               Add Category
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

import React, { useEffect, useState } from 'react';
import {
  fetchMenuDetails,
  menuCategoriesCreate,
  menuCategoriesUpdate,
  menuCategoriesDelete,
  menuSubCategoriesCreate,
  menuSubCategoriesUpdate,
  menuSubCategoriesDelete,
  approveChanges,
} from '../../API/Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';

export function DeliveryMenu({ setMenuDataForApi, setDeletedItems, onItemClick }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [menuData, setMenuData] = useState([]);
  const [activeCategoryId, setActiveCategoryId] = useState(null);
  const [activeSubCategoryId, setActiveSubCategoryId] = useState(null);
  const [expandedCategories, setExpandedCategories] = useState(new Set());
  const [expandedSubCategories, setExpandedSubCategories] = useState(new Set());
  const [newCategoryName, setNewCategoryName] = useState('');
  const [newSubCategoryName, setNewSubCategoryName] = useState('');
  const [selectedCategories, setSelectedCategories] = useState(new Set());
  const [selectedSubCategories, setSelectedSubCategories] = useState(new Set());
  const restaurantId = localStorage.getItem('restaurantId');

  useEffect(() => {
    const fetchMenu = async () => {
      try {
        const data = await fetchMenuDetails(restaurantId);
        setMenuData(Array.isArray(data.menuCategories) ? data.menuCategories : []);
        console.log("Menu Data:", data.menuCategories);
        navigate(location.pathname, { state: data });
      } catch (error) {
        console.error("Error fetching menu details:", error.response?.data || error.message);
      }
    };
    fetchMenu();
  }, [navigate, location.pathname]);

  const handleCategoryClick = (categoryId) => {
    setActiveCategoryId(activeCategoryId === categoryId ? null : categoryId);
    setExpandedCategories((prev) => {
      const newSet = new Set(prev);
      newSet.has(categoryId) ? newSet.delete(categoryId) : newSet.add(categoryId);
      return newSet;
    });
  };

  const handleSubCategoryClick = (subCategoryId) => {
    setActiveSubCategoryId(activeSubCategoryId === subCategoryId ? null : subCategoryId);
    setExpandedSubCategories((prev) => {
      const newSet = new Set(prev);
      newSet.has(subCategoryId) ? newSet.delete(subCategoryId) : newSet.add(subCategoryId);
      return newSet;
    });
  };

  const handleAddCategory = async () => {
    if (!newCategoryName.trim()) return; // Exit if the input is empty
    try {
      const payload = {
        categories: [
          {
            restaurantId, // Include the restaurant ID
            categoryData: {
              name: newCategoryName.trim(), // Trim any extra spaces
              sortOrder: menuData.length + 1, // Calculate sort order dynamically
              description: '', // Optional description (set as empty for now)
            },
          },
        ],
      };
      await menuCategoriesCreate(payload);
      await approveChanges(restaurantId);
      const updatedMenu = await fetchMenuDetails(restaurantId);
      setMenuData(Array.isArray(updatedMenu.menuCategories) ? updatedMenu.menuCategories : []); // Update the menu data state
      setNewCategoryName(''); // Clear the input field
    } catch (error) {
      console.error('Error creating category:', error.response?.data || error.message);
    }
  };

  const handleUpdateCategory = async (categoryId, updatedName) => {
    try {
      const payload =
      {
        categories: [
          {
            restaurantId,
            menuCategoryId: categoryId,
            categoryData: {
              description: "",
              name: updatedName,
            }
          }
        ]
      }
      await menuCategoriesUpdate(payload);
      await approveChanges(restaurantId);
      const updatedCategories = menuData.map((category) =>
        category.id === categoryId ? { ...category, name: updatedName } : category
      );
      setMenuData(Array.isArray(updatedCategories.menuCategories) ? updatedCategories.menuCategories : []);
    } catch (error) {
      console.error('Error updating category:', error.response?.data || error.message);
    }
  };

  const handleAddSubCategory = async () => {
    if (!newSubCategoryName.trim() || !activeCategoryId) return;

    // // Find the active category and count its subcategories
    // const activeCategory = menuData.find((category) => category.id === activeCategoryId);
    // const currentSubCategoryCount = activeCategory?.MenuSubCategory?.length || 0;

    try {
      const payload = {
        subCategories:
          [
            {
              restaurantId,
              menuCategoryId: activeCategoryId,
              subCategoryData:
              {
                name: newSubCategoryName,
                // sortOrder: currentSubCategoryCount + 1, // Increment based on current count
              }
            }
          ]
      }
      await menuSubCategoriesCreate(payload);
      await approveChanges(restaurantId);
      const updatedMenuData = await fetchMenuDetails(restaurantId);
      setMenuData(Array.isArray(updatedMenuData.menuCategories) ? updatedMenuData.menuCategories : []);
      setNewSubCategoryName('');
    } catch (error) {
      console.error('Error creating subcategory:', error.response?.data || error.message);
    }
  };

  const handleUpdateSubCategory = async (subCategoryId, categoryId, updatedName) => {
    try {
      const payload = {
        subCategories: [
          {
            restaurantId,
            menuSubCategoryId: subCategoryId,
            data: {
              menuCategoryId: categoryId,
              description: "",
              name: updatedName,
            }
          }
        ]
      }
      await menuSubCategoriesUpdate(payload);
      await approveChanges(restaurantId);
      const updatedMenuData = await fetchMenuDetails(restaurantId);
      setMenuData(Array.isArray(updatedMenuData.menuCategories) ? updatedMenuData.menuCategories : []);
    } catch (error) {
      console.error('Error updating subcategory:', error.response?.data || error.message);
    }
  };

  const handleDeleteCategory = async (categoryId) => {
    try {
      const payload = {
        restaurantId,
        menuCategoryIds: [categoryId],
      };
      console.log(payload);

      await menuCategoriesDelete(payload);
      // await approveChanges(restaurantId);
      const updatedCategories = await fetchMenuDetails(restaurantId);
      setMenuData(Array.isArray(updatedCategories.menuCategories) ? updatedCategories.menuCategories : []);
    } catch (error) {
      console.error('Error deleting category:', error.response?.data || error.message);
    }
  };

  const handleDeleteSubCategory = async (subCategoryId) => {
    try {
      const payload = {
        restaurantId,
        menuSubCategoryIds: [subCategoryId],
      };
      await menuSubCategoriesDelete(payload);
      // await approveChanges(restaurantId);
      const updatedCategories = await fetchMenuDetails(restaurantId)
      setMenuData(Array.isArray(updatedCategories.menuCategories) ? updatedCategories.menuCategories : []);
    } catch (error) {
      console.error('Error deleting subcategory:', error.response?.data || error.message);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="items-center my-2 mx-3">
        {Array.isArray(menuData) && menuData && menuData.map((category) => (
          <div key={category.id} className="border-b border-gray-200">
            <div className="flex items-center p-3 hover:bg-gray-100">
              <button
                onClick={() => handleCategoryClick(category.id)}
                className="text-left flex items-center py-2 px-1 w-full gap-4"
              >
                {expandedCategories.has(category.id) ? (
                  <FontAwesomeIcon icon={faCaretDown} className="text-2xl" />
                ) : (
                  <FontAwesomeIcon icon={faCaretRight} className="text-2xl" />
                )}
                {category.name}
              </button>
              <div className="ml-2 flex items-center gap-4">
                <button
                  onClick={() => handleUpdateCategory(category.id, prompt('Enter new category name', category.name))}
                  className="text-blue-500"
                >
                  <FontAwesomeIcon icon={faEdit} />
                </button>
                <button onClick={() => handleDeleteCategory(category.id)} className="text-red">
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            </div>
            {expandedCategories.has(category.id) && (
              <div className="ml-4">
                {category.MenuSubCategory.map((subCategory) => (
                  <div key={subCategory.id}>
                    <div className="flex items-center p-3 hover:bg-gray-100">
                      <button
                        onClick={() => handleSubCategoryClick(subCategory.id)}
                        className="text-left flex items-center py-2 w-full gap-4"
                      >
                        {expandedSubCategories.has(subCategory.id) ? (
                          <FontAwesomeIcon icon={faCaretDown} className="text-2xl" />
                        ) : (
                          <FontAwesomeIcon icon={faCaretRight} className="text-2xl" />
                        )}
                        {subCategory.name || 'Unnamed Subcategory'}
                      </button>
                      <div className="ml-2 flex items-center gap-4">
                        <button
                          onClick={() =>
                            handleUpdateSubCategory(
                              subCategory.id,
                              category.id,
                              prompt('Enter new subcategory name', subCategory.name)
                            )
                          }
                          className="text-blue-500"
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </button>
                        <button onClick={() => handleDeleteSubCategory(subCategory.id)} className="text-red">
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </div>
                    </div>
                    {expandedSubCategories.has(subCategory.id) && (
                      <div className="ml-4">
                        {subCategory.MenuItemSubCategoryLookup.map((itemLookup) => {
                          const menuItem = itemLookup.MenuItem; // Extract the MenuItem object
                          const activeRevision = menuItem?.MenuItemRevision?.find(
                            (revision) => revision.isActiveRevision
                          ); // Get the active revision

                          if (!activeRevision) return null; // Skip if no active revision

                          return (
                            <div key={itemLookup.id} className="p-2 border-b">
                              <div className="flex justify-between items-center">
                                <div className='flex items-center'>

                                  {/* <p className="text-sm text-gray-600">{activeRevision.description}</p> */}
                                  <img
                                    src={activeRevision.imageUrl}
                                    alt={activeRevision.name}
                                    className="mt-2 w-10 h-10 object-cover rounded-md"
                                  />
                                  <span className="text-base mx-5 font-medium">{activeRevision.name}</span>
                                </div>
                                <div className="flex items-center gap-4">
                                  <span className="text-green-600 font-bold">
                                    ₹{activeRevision.basePrice}
                                  </span>
                                  <button
                                    // onClick={() => handleEditItem(menuItem.id)} // Edit functionality
                                    className="text-blue-500 mx-2"
                                  >
                                    <FontAwesomeIcon icon={faEdit} />
                                  </button>
                                  <button
                                    // onClick={() => handleDeleteItem(menuItem.id)} // Delete functionality
                                    className="text-red"
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </button>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                ))}
                <div className="mt-2">
                  <input
                    type="text"
                    placeholder="New SubCategory Name"
                    value={newSubCategoryName}
                    onChange={(e) => setNewSubCategoryName(e.target.value)}
                    className="mr-2 p-2 border border-gray-300 rounded"
                  />
                  <button
                    onClick={handleAddSubCategory}
                    className="bg-green text-white px-3 py-2 rounded-md"
                  >
                    Add SubCategory
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}
        <div className="mt-4">
          <input
            type="text"
            placeholder="New Category Name"
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
            className="mr-2 p-2 border border-gray-300 rounded"
          />
          <button onClick={handleAddCategory} className="bg-green text-white px-3 py-2 rounded-md">
            Add Category
          </button>
        </div>
      </div>
    </div>
  );
}






// import React, { useEffect, useState } from 'react';
// import {
//   fetchMenuDetails,
//   menuCategoriesCreate,
//   menuCategoriesUpdate,
//   menuCategoriesDelete,
//   menuSubCategoriesCreate,
//   menuSubCategoriesUpdate,
//   menuSubCategoriesDelete,
//   approveChanges,
// } from '../../API/Api';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCaretDown, faCaretRight, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
// import { useLocation, useNavigate } from 'react-router-dom';

// export function DeliveryMenu({ setMenuDataForApi, setDeletedItems, onItemClick }) {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [menuData, setMenuData] = useState([]);
//   const [activeCategoryId, setActiveCategoryId] = useState(null);
//   const [activeSubCategoryId, setActiveSubCategoryId] = useState(null);
//   const [expandedCategories, setExpandedCategories] = useState(new Set());
//   const [expandedSubCategories, setExpandedSubCategories] = useState(new Set());
//   const [newCategoryName, setNewCategoryName] = useState('');
//   const [newSubCategoryName, setNewSubCategoryName] = useState('');
//   const [selectedCategories, setSelectedCategories] = useState(new Set());
//   const [selectedSubCategories, setSelectedSubCategories] = useState(new Set());
//   const restaurantId = localStorage.getItem('restaurantId');

//   useEffect(() => {
//     const fetchMenu = async () => {
//       try {
//         const data = await fetchMenuDetails(restaurantId);
//         setMenuData(data.menuCategories || []);
//         navigate(location.pathname, { state: data });
//       } catch (error) {
//         console.error('Error fetching menu details:', error.response?.data || error.message);
//       }
//     };
//     fetchMenu();
//   }, [navigate, location.pathname]);

//   const handleCategorySelection = (categoryId) => {
//     setSelectedCategories((prev) => {
//       const newSet = new Set(prev);
//       newSet.has(categoryId) ? newSet.delete(categoryId) : newSet.add(categoryId);
//       return newSet;
//     });
//   };

//   const handleSubCategorySelection = (subCategoryId) => {
//     setSelectedSubCategories((prev) => {
//       const newSet = new Set(prev);
//       newSet.has(subCategoryId) ? newSet.delete(subCategoryId) : newSet.add(subCategoryId);
//       return newSet;
//     });
//   };

//   const handleAddCategory = async () => {
//     if (!newCategoryName.trim()) return;
//     try {
//       const payload = {
//         categories: [
//           {
//             restaurantId,
//             categoryData: {
//               name: newCategoryName.trim(),
//               sortOrder: menuData.length + 1,
//               description: '',
//             },
//           },
//         ],
//       };
//       await menuCategoriesCreate(payload);
//       await approveChanges(restaurantId);
//       const updatedMenu = await fetchMenuDetails(restaurantId);
//       setMenuData(updatedMenu.menuCategories || []);
//       setNewCategoryName('');
//     } catch (error) {
//       console.error('Error creating category:', error.response?.data || error.message);
//     }
//   };

//   const handleAddSubCategory = async () => {
//     if (!newSubCategoryName.trim() || !activeCategoryId) return;
//     try {
//       const payload = {
//         subCategories: [
//           {
//             restaurantId,
//             menuCategoryId: activeCategoryId,
//             subCategoryData: {
//               name: newSubCategoryName.trim(),
//             },
//           },
//         ],
//       };
//       await menuSubCategoriesCreate(payload);
//       await approveChanges(restaurantId);
//       const updatedMenu = await fetchMenuDetails(restaurantId);
//       setMenuData(updatedMenu.menuCategories || []);
//       setNewSubCategoryName('');
//     } catch (error) {
//       console.error('Error creating subcategory:', error.response?.data || error.message);
//     }
//   };

//   const handleUpdateCategory = async (categoryId, updatedName) => {
//     try {
//       const payload = {
//         categories: [
//           {
//             restaurantId,
//             menuCategoryId: categoryId,
//             categoryData: {
//               description: '',
//               name: updatedName.trim(),
//             },
//           },
//         ],
//       };
//       await menuCategoriesUpdate(payload);
//       await approveChanges(restaurantId);
//       const updatedCategories = menuData.map((category) =>
//         category.id === categoryId ? { ...category, name: updatedName } : category
//       );
//       setMenuData(updatedCategories);
//     } catch (error) {
//       console.error('Error updating category:', error.response?.data || error.message);
//     }
//   };

//   const handleUpdateSubCategory = async (subCategoryId, categoryId, updatedName) => {
//     try {
//       const payload = {
//         subCategories: [
//           {
//             restaurantId,
//             menuSubCategoryId: subCategoryId,
//             data: {
//               menuCategoryId: categoryId,
//               description: '',
//               name: updatedName.trim(),
//             },
//           },
//         ],
//       };
//       await menuSubCategoriesUpdate(payload);
//       await approveChanges(restaurantId);
//       const updatedMenu = await fetchMenuDetails(restaurantId);
//       setMenuData(updatedMenu.menuCategories || []);
//     } catch (error) {
//       console.error('Error updating subcategory:', error.response?.data || error.message);
//     }
//   };

//   const handleDeleteCategory = async (categoryId) => {
//     try {
//       const payload = {
//         restaurantId,
//         menuCategoryIds: [categoryId],
//       };
//       await menuCategoriesDelete(payload);
//       await approveChanges(restaurantId);
//       const updatedCategories = menuData.filter((category) => category.id !== categoryId);
//       setMenuData(updatedCategories);
//     } catch (error) {
//       console.error('Error deleting category:', error.response?.data || error.message);
//     }
//   };

//   const handleDeleteSubCategory = async (subCategoryId) => {
//     try {
//       const payload = {
//         restaurantId,
//         menuSubCategoryIds: [subCategoryId],
//       };
//       await menuSubCategoriesDelete(payload);
//       await approveChanges(restaurantId);
//       const updatedCategories = menuData.map((category) =>
//         category.id === activeCategoryId
//           ? {
//             ...category,
//             MenuSubCategory: category.MenuSubCategory.filter(
//               (subCategory) => subCategory.id !== subCategoryId
//             ),
//           }
//           : category
//       );
//       setMenuData(updatedCategories);
//     } catch (error) {
//       console.error('Error deleting subcategory:', error.response?.data || error.message);
//     }
//   };

//   const handleBulkDeleteCategories = async () => {
//     if (selectedCategories.size === 0) return;
//     try {
//       const payload = {
//         restaurantId,
//         menuCategoryIds: Array.from(selectedCategories),
//       };
//       await menuCategoriesDelete(payload);
//       await approveChanges(restaurantId);
//       const updatedMenu = await fetchMenuDetails(restaurantId);
//       setMenuData(updatedMenu.menuCategories || []);
//       setSelectedCategories(new Set());
//     } catch (error) {
//       console.error('Error bulk deleting categories:', error.response?.data || error.message);
//     }
//   };

//   const handleBulkDeleteSubCategories = async () => {
//     if (selectedSubCategories.size === 0) return;
//     try {
//       const payload = {
//         restaurantId,
//         menuSubCategoryIds: Array.from(selectedSubCategories),
//       };
//       await menuSubCategoriesDelete(payload);
//       await approveChanges(restaurantId);
//       const updatedMenu = await fetchMenuDetails(restaurantId);
//       setMenuData(updatedMenu.menuCategories || []);
//       setSelectedSubCategories(new Set());
//     } catch (error) {
//       console.error('Error bulk deleting subcategories:', error.response?.data || error.message);
//     }
//   };

//   return (
//     <div className="flex flex-col">
//       <div className="items-center my-2 mx-3">
//         <div>
//           {menuData.map((category) => (
//             <div key={category.id} className="border-b border-gray-200">
//               <div className="flex items-center p-3 hover:bg-gray-100">
//                 <input
//                   type="checkbox"
//                   checked={selectedCategories.has(category.id)}
//                   onChange={() => handleCategorySelection(category.id)}
//                   className="mr-2"
//                 />
//                 <button
//                   onClick={() => setExpandedCategories((prev) =>
//                     prev.has(category.id)
//                       ? new Set([...prev].filter((id) => id !== category.id))
//                       : new Set(prev.add(category.id))
//                   )}
//                   className="text-left flex items-center py-2 px-1 w-full gap-4"
//                 >
//                   {expandedCategories.has(category.id) ? (
//                     <FontAwesomeIcon icon={faCaretDown} className="text-2xl" />
//                   ) : (
//                     <FontAwesomeIcon icon={faCaretRight} className="text-2xl" />
//                   )}
//                   {category.name}
//                 </button>
//                 <div className="ml-2 flex items-center gap-4">
//                   <button
//                     onClick={() => handleUpdateCategory(category.id, prompt('Enter new category name', category.name))}
//                     className="text-blue-500"
//                   >
//                     <FontAwesomeIcon icon={faEdit} />
//                   </button>
//                   <button onClick={() => handleDeleteCategory(category.id)} className="text-red">
//                     <FontAwesomeIcon icon={faTrash} />
//                   </button>
//                 </div>
//               </div>
//               {expandedCategories.has(category.id) && (
//                 <div className="ml-4">
//                   {category.MenuSubCategory.map((subCategory) => (
//                     <div key={subCategory.id} className="flex items-center p-3 hover:bg-gray-100">
//                       <input
//                         type="checkbox"
//                         checked={selectedSubCategories.has(subCategory.id)}
//                         onChange={() => handleSubCategorySelection(subCategory.id)}
//                         className="mr-2"
//                       />
//                       <button
//                         onClick={() => setExpandedSubCategories((prev) =>
//                           prev.has(subCategory.id)
//                             ? new Set([...prev].filter((id) => id !== subCategory.id))
//                             : new Set(prev.add(subCategory.id))
//                         )}
//                         className="text-left flex items-center py-2 w-full gap-4"
//                       >
//                         {expandedSubCategories.has(subCategory.id) ? (
//                           <FontAwesomeIcon icon={faCaretDown} className="text-2xl" />
//                         ) : (
//                           <FontAwesomeIcon icon={faCaretRight} className="text-2xl" />
//                         )}
//                         {subCategory.name}
//                       </button>
//                       <div className="ml-2 flex items-center gap-4">
//                         <button
//                           onClick={() =>
//                             handleUpdateSubCategory(
//                               subCategory.id,
//                               category.id,
//                               prompt('Enter new subcategory name', subCategory.name)
//                             )
//                           }
//                           className="text-blue-500"
//                         >
//                           <FontAwesomeIcon icon={faEdit} />
//                         </button>
//                         <button onClick={() => handleDeleteSubCategory(subCategory.id)} className="text-red">
//                           <FontAwesomeIcon icon={faTrash} />
//                         </button>
//                       </div>
//                       {expandedSubCategories.has(subCategory.id) && (
//                         <div className="ml-4">
//                           {subCategory.MenuItemSubCategoryLookup.map((itemLookup) => {
//                             const menuItem = itemLookup.MenuItem; // Extract the MenuItem object
//                             const activeRevision = menuItem?.MenuItemRevision?.find(
//                               (revision) => revision.isActiveRevision
//                             ); // Get the active revision

//                             if (!activeRevision) return null; // Skip if no active revision

//                             return (
//                               <div key={itemLookup.id} className="p-2 border-b">
//                                 <div className="flex justify-between items-center">
//                                   <div className='flex items-center'>

//                                     {/* <p className="text-sm text-gray-600">{activeRevision.description}</p> */}
//                                     <img
//                                       src={activeRevision.imageUrl}
//                                       alt={activeRevision.name}
//                                       className="mt-2 w-10 h-10 object-cover rounded-md"
//                                     />
//                                     <span className="text-base mx-5 font-medium">{activeRevision.name}</span>
//                                   </div>
//                                   <div className="flex items-center gap-4">
//                                     <span className="text-green-600 font-bold">
//                                       ₹{activeRevision.basePrice}
//                                     </span>
//                                     <button
//                                       // onClick={() => handleEditItem(menuItem.id)} // Edit functionality
//                                       className="text-blue-500 mx-2"
//                                     >
//                                       <FontAwesomeIcon icon={faEdit} />
//                                     </button>
//                                     <button
//                                       // onClick={() => handleDeleteItem(menuItem.id)} // Delete functionality
//                                       className="text-red"
//                                     >
//                                       <FontAwesomeIcon icon={faTrash} />
//                                     </button>
//                                   </div>
//                                 </div>
//                               </div>
//                             );
//                           })}
//                         </div>
//                       )}
//                     </div>
//                   ))}
//                   <div className="mt-2">
//                     <input
//                       type="text"
//                       placeholder="New SubCategory Name"
//                       value={newSubCategoryName}
//                       onChange={(e) => setNewSubCategoryName(e.target.value)}
//                       className="mr-2 p-2 border border-gray-300 rounded"
//                     />
//                     <button onClick={handleAddSubCategory} className="bg-green text-white px-3 py-2 rounded-md">
//                       Add SubCategory
//                     </button>
//                   </div>
//                 </div>
//               )}
//             </div>
//           ))}
//           <div className="mt-4">
//             <input
//               type="text"
//               placeholder="New Category Name"
//               value={newCategoryName}
//               onChange={(e) => setNewCategoryName(e.target.value)}
//               className="mr-2 p-2 border border-gray-300 rounded"
//             />
//             <button onClick={handleAddCategory} className="bg-green text-white px-3 py-2 rounded-md">
//               Add Category
//             </button>
//           </div>
//         </div>
//         <div className="flex mt-4">
//           <button
//             onClick={handleBulkDeleteCategories}
//             className="bg-red-500 text-white px-3 py-2 rounded-md mr-2"
//           >
//             Delete Selected Categories
//           </button>
//           <button
//             onClick={handleBulkDeleteSubCategories}
//             className="bg-red-500 text-white px-3 py-2 rounded-md"
//           >
//             Delete Selected SubCategories
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// }
