import React, { useCallback, useEffect, useState } from 'react';
import { fetchLearningCenterVideos } from '../API/Api';
import banner from "../assets/banner.webp";

const LearningCenter = () => {
  const restaurantId = localStorage.getItem('restaurantId');

  // State as an object
  const [state, setState] = useState({
    language: 'English',
    learningCenterVideos: [],
    isLoading: false,
    error: null,
  });

  // Update state helper function
  const updateState = (newState) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  };

  // Memoized function to load videos
  const loadLearningCenterVideos = useCallback(
    async (selectedLanguage) => {
      if (!restaurantId) return; // Guard clause if restaurantId is not available

      updateState({ isLoading: true, error: null });

      try {
        const data = await fetchLearningCenterVideos(selectedLanguage, restaurantId);
        console.log('Learning Center Response', data);

        updateState({
          learningCenterVideos: data.learningCenterVideos || [],
          isLoading: false,
        });
      } catch (error) {
        updateState({ error: error.message, isLoading: false });
      }
    },
    [restaurantId] // Dependency array to re-memoize only when restaurantId changes
  );

  // Effect to fetch data when language or restaurantId changes
  useEffect(() => {
    loadLearningCenterVideos(state.language);
  }, [state.language, loadLearningCenterVideos]);

  const handleLanguageChange = (e) => {
    updateState({ language: e.target.value }); // Update language in state
  };

  return (
    <div>
      <img src={banner} className="w-full" alt="banner1" />
      <div className="flex items-center justify-end w-full p-4">
        {/* <div className="font-bold text-lg">Learning Center</div> */}
        <select
          name="language"
          className="border-gray-300 border shadow-lg text-center px-3 cursor-pointer py-2 rounded focus:outline-none focus:ring focus:ring-green-500"
          value={state.language}
          onChange={handleLanguageChange}
        >
          <option className='hover:bg-green hover:text-white' value="English">English</option>
          <option className='hover:bg-green hover:text-white' value="Tamil">Tamil</option>
          <option className='hover:bg-green hover:text-white' value="Hindi">Hindi</option>
          <option className='hover:bg-green hover:text-white' value="Telugu">Telugu</option>
          <option className='hover:bg-green hover:text-white' value="Kannada">Kannada</option>
        </select>
      </div>
      <div className="p-4">
        {state.isLoading && <p>Loading videos...</p>}
        {state.error && <p className="text-red-500">Error: {state.error}</p>}
        {!state.isLoading && !state.error && state.learningCenterVideos.length > 0 ? (
          <div>
            {state.learningCenterVideos.map((category) => (
              <div key={category.categoryName} className="mb-6">
                <h3 className="font-bold text-lg mb-4">{category.categoryName}</h3>
                {category.videos && category.videos.length > 0 ? (
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6">
                    {category.videos.map((video, index) => (
                      <div
                        key={index}
                        className="border p-3 rounded shadow flex flex-col justify-between"
                      >
                        <div>
                          <h4 className="font-bold mb-2">{video.videoTitle}</h4>
                          {video.videoDescription && (
                            <p
                              className="text-sm text-gray-500 mb-2 truncate hover:whitespace-normal hover:text-gray-700"
                              style={{
                                display: '-webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                              }}
                              title={video.videoDescription} // Show full text on hover
                            >
                              {video.videoDescription}
                            </p>
                          )}
                        </div>
                        <div className="mt-2">
                          <iframe
                            src={video.videoUrl}
                            title={video.videoTitle}
                            className="w-full h-48 rounded-md"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-gray-800">No videos available in this category.</p>
                )}
              </div>
            ))}
          </div>
        ) : (
          !state.isLoading && (
            <p className="text-lg text-center">
              No videos available for the selected language.
            </p>
          )
        )}
      </div>
    </div>
  );
};

export default LearningCenter;
