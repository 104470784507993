import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AssociateUser from './AssociateUser';
import CustomRolesList from './CustomRolesList';
import PredefinedRoles from './PredefinedRoles';
import CreateRoles from './CreateRoles';
import PortalUsersList from './PortalUsersList';
import ReAssociateUser from './ReAssociateUser';

const UserMgmt = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // Initial state based on the current query parameter
    const initialActiveItem = (() => {
        const query = new URLSearchParams(location.search).get('/');
        const queryMapping = {
            'Portal Users List': 'portaluserslist',
            'createroles': 'Create Roles',
            'associateusers': 'Associate User',
            'reassociateusers': 'Re-Associate User',
            'customroleslist': 'Custom Roles List'
        };
        return queryMapping[query] || "Portal Users List";
    })();

    const [activeItem, setActiveItem] = useState(initialActiveItem);

    // Map of active items to query parameters
    const queryMapping = {
        'Portal Users List': 'portaluserslist',
        'Create Roles': 'createroles',
        'Associate User': 'associateusers',
        'Custom Roles List': 'customroleslist',
        'Re-Associate User': 'customroleslist'
    };

    // Update the URL query string and active item without reloading the page
    const handleItemClick = (itemName) => {
        setActiveItem(itemName);
        navigate(`/usermanagement?/${queryMapping[itemName]}`, { replace: true });
    };

    const renderContent = () => {
        switch (activeItem) {
            case 'Portal Users List':
                return <PortalUsersList />;
            case 'Create Roles':
                return <CreateRoles />;
            case 'Associate User':
                return <AssociateUser />;
            case 'Re-Associate User':
                return <ReAssociateUser />;
            case 'Custom Roles List':
                return <CustomRolesList />;
            default:
                return <CreateRoles />;
        }
    };

    return (
        <>
            <div className="">
                <div className="container border-b-2 border-slate-300">
                    <div className="row flex justify-center p-3">
                        <h3 className="text-xl text-center font-bold">User Management</h3>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-12">
                <ul className="col-span-3">
                    <li
                        onClick={() => handleItemClick('Portal Users List')}
                        className={`flex justify-between items-center p-4 cursor-pointer border-r-4 ${activeItem === 'Portal Users List' ? 'bg-green text-white border-green' : 'border-transparent'}`}
                    >
                        Portal Users List<span className="ml-1">&gt;</span>
                    </li>
                    <li
                        onClick={() => handleItemClick('Custom Roles List')}
                        className={`flex justify-between items-center p-4 cursor-pointer border-r-4 ${activeItem === 'Custom Roles List' ? 'bg-green text-white border-green' : 'border-transparent'}`}
                    >
                        Custom Roles List<span className="ml-1">&gt;</span>
                    </li>
                    <li
                        onClick={() => handleItemClick('Create Roles')}
                        className={`flex justify-between items-center p-4 cursor-pointer border-r-4 ${activeItem === 'Create Roles' ? 'bg-green text-white border-green' : 'border-transparent'}`}
                    >
                        Custom Roles - Create<span className="ml-1">&gt;</span>
                    </li>
                    <li
                        onClick={() => handleItemClick('Associate User')}
                        className={`flex justify-between items-center p-4 cursor-pointer border-r-4 ${activeItem === 'Associate User' ? 'bg-green text-white border-green' : 'border-transparent'}`}
                    >
                        Associate User<span className="ml-1">&gt;</span>
                    </li>
                    <li
                        onClick={() => handleItemClick('Re-Associate User')}
                        className={`flex justify-between items-center p-4 cursor-pointer border-r-4 ${activeItem === 'Re-Associate User' ? 'bg-green text-white border-green' : 'border-transparent'}`}
                    >
                        Re-Associate User<span className="ml-1">&gt;</span>
                    </li>
                </ul>
                <div className="col-span-9 border-l-2 h-lvh border-gray-400">
                    {renderContent()}
                </div>
            </div>
        </>
    );
};

export default UserMgmt;
