import React, { useState } from "react";
import { format } from "date-fns";
import { SlCalender } from "react-icons/sl";
import { DateRangePicker } from "react-date-range";

function Tickets() {
    const [state, setState] = useState({
        date: {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
        },
        isOpen: false,
        option: 'ticket'
    });
// Handle date change from date range picker
    const handleChange = (ranges) => {
        setState(prevState=>({
            ...prevState,
            date : ranges.selection,
        }));
    };
     // Toggle the date picker visibility
     const toggleDatePicker = () => {
        setState(prevState => ({
            ...prevState,
            isOpen: !prevState.isOpen,
        }));
    };

    // Change the current selected tab (ticket, open, or response-received)
    const changeOption = (option) => {
        setState(prevState => ({
            ...prevState,
            option,
        }));
    };
    return (
        <div className="">
            <div className='flex justify-between my-2 mx-2'>
                <div className='flex space-x-5'>
                    <button type='button' onClick={() => changeOption('ticket')} className={`text-gray-400 font-semibold ${state.option === 'ticket' ? "border-b-2 border-green" : ''}`}>All Tickets (0)</button>
                    <button type='button' onClick={() => changeOption('open')} className={`text-gray-400 font-semibold ${state.option === 'open' ? "border-b-2 border-green" : ''}`}>Open (0)</button>
                    <button type='button' onClick={() => changeOption('response-received')} className={`text-gray-400 font-semibold ${state.option === 'response-received' ? "border-b-2 border-green" : ''}`}>Response Received (0)</button>
                </div>
                <div className="relative">
                    <button className="border p-2 rounded flex items-center bg-green text-white" onClick={toggleDatePicker}><SlCalender className="inline mx-1" />{`${format(state.date.startDate, "MMM dd yyyy")} to ${format(
                        state.date.endDate,
                        "MMM dd yyyy"
                    )}`} </button>
                    <div className="absolute right-0">

                        {state.isopen && <DateRangePicker
                            ranges={[state.date]}
                            onChange={handleChange}
                        // minDate={new Date()} its gives change only from start date
                        />}
                    </div>
                    
            
                </div>
            </div>
            <div className="border border-gray-300 shadow"></div>
            {state.option === 'ticket' && <AllTicket />}
            {state.option === 'open' && <Open />}
            {state.option === 'response-received' && <ResponseReceived />}
        </div>
    );
}
const AllTicket = () => {
    return (
        <div>
            <p className="text-center py-4">All Ticket</p>
            <div className="flex justify-center  my-10">
                
                <img src="https://www.shareicon.net/data/128x128/2016/07/23/800747_food_512x512.webp" alt="" />
            </div>
        </div>
    )
}
const Open = () => {
    return (
        <div>
            <p className="text-center py-4">Open</p>
            <div className="flex justify-center  my-10">
                <img src="https://www.shareicon.net/data/128x128/2016/07/23/800747_food_512x512.webp" alt="" />
            </div>
        </div>
    )
}
const ResponseReceived = () => {
    return (
        <div>
            <p className="text-center py-4">ResponseReceived</p>
            <div className="flex justify-center my-10">

                <img src="https://www.shareicon.net/data/128x128/2016/07/23/800747_food_512x512.webp" alt="" />
            </div>
        </div>
    )
}
export default Tickets;




