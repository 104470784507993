import React from 'react'
import { GoChevronRight } from "react-icons/go";
import { useNavigate } from 'react-router-dom';
function NeedHelp() {
    return (
        <div>
            <div className='m-5 border rounded-md overflow-hidden space-y-4'>
                <div className='p-3  text-lg bg-gray-100 '>Need Help With Something else</div>
                <div className='grid grid-cols-2 gap-2 m-2'>
                    <Options text={"why is my outlet offline ?"} path={'outlet-offline'} />
                    <Options text={"menu changes and price revision"} path={'price-revision'} />
                    <Options text={"Growth Related Issue"} path={'growth-related-issue'} />
                    <Options text={"Payment & Settlements"} path={'payemnt-settilement'} />
                    <Options text={"Edit Packaging Charges"} path={'packaging-charges'} />
                    <Options text={"change Name Are Address"} path={'change-address'} />
                    <Options text={"Change Contacts"} path={'change-contacts'} />
                    <Options text={"Order And Refund Issues"} path={'refund-issue'} />
                    <Options text={"Request Owner Access"} path={'request-Owner'} />
                    <Options text={"Start/Stop Promotion"} path={'start-promotion'} />

                </div>
            </div>
        </div>
    )
}
function Options({ text, path }) {
    const navigate = useNavigate()
    return (
        <div onClick={() => navigate(`/helpcenter/${path}`)} className='border-2 items-center flex justify-between  py-2 rounded m-2'>
            <p className='text-lg px-3'>{text}</p><GoChevronRight size={25} className='text-gray-400' />
        </div>
    )
}
export default NeedHelp