// services/errorHandler.js
export const handleError = (error) => {
    if (error.response) {
      // Server responded with a status outside the 200 range
      const status = error.response.status; 
      const data = error.response.data; 
  
      console.error(`Error Status: ${status}`);
      console.error('Error Response Data:', JSON.stringify(data)); // Log the entire error object
  
      let message = 'Something went wrong!';
  
      // Use specific error messages if available
      if (data.message) {
        message = data.message;
      } else if (data.error) {
        message = data.error;
      } else {
        message = JSON.stringify(data); // Show the entire error object if no specific message
      }
  
      alert(`Error: ${message}`);  // Display the error in a readable format
    } else if (error.request) {
      // The request was made, but no response received
      console.error('Error Request:', error.request);
      alert('Error: No response from the server, please try again later.');
    } else {
      // Something happened in setting up the request that triggered an error
      console.error('Error Message:', error.message);
      alert('Error: Unexpected error occurred, please try again.');
    }
  };