import React, { useEffect } from "react";
import {
  listPortalUsers,
  listCustomRoles,
  listPredefinedRoles,
  listCustomisablePermissions,
} from "../API/Api"; // Assuming these functions are in a file called Api.js

const restaurantId = localStorage.getItem("restaurantId")

const RolesAndPermissions = () => {
  useEffect(() => {
    // Function to log all API responses
    const logApiResponses = async () => {
      
      try {
        // Log portal users
        const portalUsersResponse = await listPortalUsers(restaurantId);
        console.log("Portal Users Response:", portalUsersResponse);

        // Log custom roles
        const customRolesResponse = await listCustomRoles(restaurantId);
        console.log("Custom Roles Response:", customRolesResponse);

        // Log predefined roles
        const predefinedRolesResponse = await listPredefinedRoles(restaurantId);
        console.log("Predefined Roles Response:", predefinedRolesResponse);

        // Log customizable permissions
        const customizablePermissionsResponse = await listCustomisablePermissions(restaurantId);
        console.log("Customizable Permissions Response:", customizablePermissionsResponse);

      } catch (error) {
        console.error("Error logging API responses:", error);
      }
    };

    logApiResponses();
  }, []);

  return (
    <div>
      
    </div>
  );
};

export default RolesAndPermissions;
