import React, { useState, useEffect, useRef } from 'react';
import offer1 from '../assets/offer-img.webp';
import './Createoffer.css';
import { FaTimes } from 'react-icons/fa';
import Freebies from './Freebies';
import Promocard from './Promocard';
import { getPredefinedCampaigns, createCampaign } from '../API/Api'; // Import API functions
import CreateNewOffer from './CreateNewOffer';
import { Link, useNavigate } from 'react-router-dom';

const Createoffer = () => {
  const [state, setState] = useState({
    showOffer: true,
    showImage: true,
    selectedOffer: null,
    campaignStartDate: '2024-06-26',
    minimumOrderValue: '₹159',
    restaurantid: '(ID: 19528561)',
    offers: [],
    showCanvas: false,
    canvasContent: '',
    showCreateOffer: false,
    user: localStorage.getItem("userId"),
    restaurantId: localStorage.getItem("restaurantId"),
    token: localStorage.getItem("accessToken")
  });

  const {
    showOffer, showImage, selectedOffer, campaignStartDate, minimumOrderValue,
    restaurantid, offers, showCanvas, canvasContent, showCreateOffer
  } = state;

  const offCanvasRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (!state.token) {
      navigate("/login");
    }
  }, [state.token, navigate]);

  useEffect(() => {
    // Fetch predefined campaigns when component mounts
    const fetchOffers = async () => {
      try {
        const response = await getPredefinedCampaigns({ restaurant_Id: state.restaurantId });
        setState(prevState => ({
          ...prevState,
          offers: response,
          loading: false
        }));
        console.log(response);
      } catch (error) {
        console.error("Error fetching offers:", error);
        setState(prevState => ({ ...prevState, loading: false }));
      }
    };

    fetchOffers();
  }, [state.user, state.restaurantId]);

  const handleOfferClick = (offer) => {
    setState(prevState => ({
      ...prevState,
      selectedOffer: offer
    }));
  };

  const closeOffCanvas = () => {
    setState(prevState => ({
      ...prevState,
      selectedOffer: null
    }));
  };

  const handleCardClick = (content) => {
    setState(prevState => ({
      ...prevState,
      canvasContent: content,
      showCanvas: true,
      showCreateOffer: true
    }));
  };

  const handleHideCreateOffer = () => {
    setState(prevState => ({
      ...prevState,
      showCreateOffer: false
    }));
  };

  const hideCanvas = () => {
    setState(prevState => ({
      ...prevState,
      showCanvas: false
    }));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (offCanvasRef.current && !offCanvasRef.current.contains(event.target)) {
        closeOffCanvas();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
  }, [selectedOffer]);

  const PromoCard = ({ title, description, imgSrc, onClick }) => (
    <div className="w-full col-span-12 mb-4">
      <div className="bg-white shadow-lg rounded-lg border border-green p-4 cursor-pointer" onClick={onClick}>
        <h3 className="text-xl font-bold mb-3">{title}</h3>
        <div className="flex">
          <div className="flex-grow">
            <p className="text-gray-600">{description}</p>
          </div>
          <img src={imgSrc} alt={title} className="self-end mt-auto" style={{ maxWidth: '100%', height: '60px' }} />
        </div>
      </div>
    </div>
  );

  const handleActivateOffer = async () => {
    try {
      const payload = {
        campaign_start_date: campaignStartDate,
        campaign_end_date: '2024-08-10T00:00:00.000Z',
        created_by: 'your_user_id_here',
        user_code: 0,
        max_redumptions_per_user: 1,
        redeem_every: 2,
        res_id: 'your_restaurant_id_here',
        discount_window: [
          {
            campaign_day_code: 0,
            discount_time_windows: [
              {
                start_time: '09:00',
                end_time: '11:00',
              },
              {
                start_time: '14:00',
                end_time: '16:00',
              },
            ],
          },
        ],
        discount_details: selectedOffer // Use selected offer data
      };
      const response = await createCampaign(payload);
      console.log('Campaign created:', response);
    } catch (error) {
      console.error('Error creating campaign:', error);
    }
  };

  return (
    <>
      <div className='createoffer px-10'>
        <h2 className='offer-text mb-2 pt-3'>Recommended Offers For You</h2>
        {showOffer && (
          <div className="bg-green text-white p-4 rounded-lg flex justify-between items-center">
            <div className='flex flex-col justify-start'>
              <div className="mb-2">
                <p className="text-lg">50% Up to ₹120</p>
              </div>
              <div className="mb-2">
                <p>Grow your business with an Offer</p>
              </div>
              <button className="bg-white text-green px-4 py-2 rounded-lg">Active Now</button>
            </div>
            {showImage && (
              <div className='ml-4'>
                <img className="h-20 w-20 object-cover rounded-lg" src={offer1} alt="Offer" />
              </div>
            )}
          </div>
        )}

        <div className=''>
          <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-7 gap-4 mt-8'>
            {offers.map((offer, index) => (
              <div
                key={index}
                className='bg-green text-black p-4 rounded-lg cursor-pointer'
                onClick={() => handleOfferClick(offer)}
              >
                <p className='text-lg text-white font-bold'>
                  {offer.couponName.split(' ').map(word =>
                    word === 'percent' ? '%' : word.charAt(0).toUpperCase() + word.slice(1)
                  ).join(' ')} 
                </p>
                <p className='text-base text-white mt-2'>{offer.couponCode}</p>
              </div>
            ))}
            <button className='text-red-500' onClick={() => handleCardClick('createnewoffer')}>Create New Offer</button>
          </div>

          {selectedOffer && (
            <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-end z-50 offer'>
              <div ref={offCanvasRef} className='bg-white w-full sm:w-1/3 p-6 shadow-lg relative'>
                <div className='flex justify-between items-center mb-4'>
                  <h2 className='text-lg font-bold'>Create New Offer</h2>
                  <button className='text-red-500' onClick={closeOffCanvas}>
                    <FaTimes size={20} />
                  </button>
                </div>
                <div className='bg-green p-4 rounded-lg flex items-center justify-between mb-4'>
                  <div>
                    <p className='text-base font-semibold text-white'>{selectedOffer.coupon_name}</p>
                    <p className='text-base text-white'>{selectedOffer.coupon_code}</p>
                  </div>
                  <img src={offer1} alt="Offer" className='w-24 h-24 object-cover rounded-lg' />
                </div>
                <label htmlFor='startDate' className="block text-sm font-semibold leading-6 text-gray-900">Campaign Start Date</label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1  ring-gray-300 sm:max-w-md">
                    <input type='date'
                      id='startDate'
                      name='startDate' value={campaignStartDate}
                      onChange={(e) => setState(prevState => ({ ...prevState, campaignStartDate: e.target.value }))} className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900" />
                  </div>
                </div>
                <p className='text-sm text-gray-500 mb-4 mt-3 pb-3 border-b-4 border-gray-300'>
                  Your offer will start on {campaignStartDate} at 7:00 PM. You can stop this offer at any time.
                </p>
                <div className='mb-4'>
                  <h3 className='text-sm font-semibold leading-6 text-gray-900'>Offer Details:</h3>
                  <ul className='list-disc list-inside text-sm text-gray-500 mb-4 pb-3 border-b-4 border-gray-300'>
                    <li>Offer applicable for: All users on all menu items, excluding MRP items</li>
                    <li>Minimum order value: {minimumOrderValue}</li>
                    <li>Valid at: Happy Bites, Alagapuram Pudur {restaurantid}</li>
                  </ul>
                </div>
                <div className='mb-4'>
                  <input type='checkbox' id='terms' name='terms' className='mr-2' />
                  <label htmlFor='terms' className='text-sm text-gray-600'>I have read and accept all the <Link className='text-green underline'>terms and conditions</Link>.</label>
                </div>
                <button onClick={handleActivateOffer} className='bg-green text-white px-4 py-2 rounded-lg w-full'>Activate Offer</button>
              </div>
            </div>
          )}
        </div>

        {/* Promo card and Freebies code start */}
        <div className="container mt-8">
          <div className="row grid gap-4 grid-cols-2">
            <div className="col-md-8 mb-4">
              <PromoCard title="Promo Code" description="Delight customers with free discounts on every order." imgSrc={offer1} onClick={() => handleCardClick('promo')} />
            </div>
            <div className="col-md-8 mb-4">
              <PromoCard title="Freebies" description="Offer a free dish above a certain order value." imgSrc={offer1} onClick={() => handleCardClick('freebie')} />
            </div>
          </div>
          {showCanvas && canvasContent === 'freebie' && <Freebies onClose={hideCanvas} />}
          {showCanvas && canvasContent === 'promo' && <Promocard show={showCanvas} onHide={hideCanvas} />}
          {showCanvas && canvasContent === 'createnewoffer' && <CreateNewOffer show={showCreateOffer} onHide={handleHideCreateOffer} />}
        </div>
        {/* Promo card and freebies code end */}
        <div>
          <p className='text-sm text-gray-500 text-center'>For Creating a fixed discount, percentage discount or buy one get one offer use the Genie Restaurant Partner app on Android. This feature will be available on the web dashboard soon</p>
        </div>

      </div>
    </>
  );
}

export default Createoffer;


