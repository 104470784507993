import React, { useState, useEffect } from "react";
import Chef from "../assets/chef.webp";
import { Link, useNavigate } from "react-router-dom";
import { FaGoogle, FaEnvelope } from "react-icons/fa";
import { sendOtp } from "../API/AuthApi";

function LogIn() {

  const navigate = useNavigate();
  const [phone, setPhone] = useState('');
  const [isGoogleSignedIn, setIsGoogleSignedIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    // Allow only numeric input (no spaces, letters, or special characters)
    const digitsOnly = value.replace(/\D/g, '');
    // Restrict the input to exactly 10 digits
    if (digitsOnly.length <= 10) {
      setPhone(digitsOnly); // Update phone state with digits only
    }
    // Validate if the phone number is less than 10 digits
    if (digitsOnly.length === 10) {
      setErrorMessage(''); // Clear error when valid
    } else {
      setErrorMessage('Phone number should be exactly 10 digits');
    }
  };

  const handleGoogleSignIn = () => {
    setIsGoogleSignedIn(true);
  };

  const logIn = async () => {
    setLoading(true);
    const mode = "send";
    setErrorMessage('');

    try {
      const phoneNumber = `+91${phone}`;
      localStorage.setItem("phoneNumber", phoneNumber);
      const response = await sendOtp(phoneNumber, mode);
      console.log("OTP Sent", response);

      const { otp, otpExpiry, otpResendWindow, totalAttempts } = response;

      localStorage.setItem("otpRetriesLeft", totalAttempts)

      // Store OTP details in localStorage
      localStorage.setItem("otpDetails", JSON.stringify({
        otp: otp,
        otpExpiry: Date.now() + otpExpiry * 1000, // current time + expiry time (5 minutes)
        otpResendWindow: Date.now() + otpResendWindow * 1000, // 7 minutes window
        totalAttempts: totalAttempts
      }));
      localStorage.setItem("uniqueAttemptId", response.uniqueAttemptId);

      if (response && response.message === "OTP sent successfully") {
        navigate("/otp"); // Navigate to OTP verification page
      } else {
        setErrorMessage(response?.message || 'Failed to send OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error details:', error.message);
      setErrorMessage('An error occurred. Please try again later.');
    } finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    sessionStorage.removeItem("accessToken"); // Clear session on login
  }, []);



  return (
    <div className="min-h-screen grid grid-cols-2">
      <div className="bg-gray-100 flex flex-col p-10">
        <h1 className="text-3xl font-bold mb-2 text-center">
          Genie Restaurant Application
        </h1>
        <p className="mb-8 text-center">
          Your Restaurant’s Success, Our Mission
        </p>
        <div className="flex justify-center items-center">
          <img
            src={Chef}
            alt="Illustration"
            className="w-full rounded justify-items-center"
            style={{ height: "33rem", width: "33rem" }}
          />
        </div>
      </div>
      <div className="flex items-center justify-center bg-gray-100 p-14">
        <div className="bg-white p-8 rounded shadow-md w-full h-96 mt-5 max-w-md relative">
          <h2 className="text-center text-2xl font-semibold mb-4">Login</h2>
          <div className="flex items-center border rounded mb-1">
            <span className="px-3 py-2 border-r">+91</span>
            <input
              type="tel"
              value={phone}
              onChange={handlePhoneChange}
              className="w-full py-2 px-3 focus:outline-none"
              placeholder="Phone"
            />
          </div>
          {errorMessage && (
            <p className="text-red font-semibold text-xs">
              {errorMessage}
            </p>
          )}
          <button
            className={`bg-green text-white py-2 rounded w-full my-3 ${loading ? "opacity-50 cursor-not-allowed" : "hover:bg-green"
              }`}
            onClick={logIn}
            disabled={loading}
          >
            {loading ? "Sending OTP..." : "Send OTP"}
          </button>
          <div className="text-center mb-3">or</div>
          <button className="flex items-center justify-center bg-white text-red border border-red py-2 rounded w-full mb-3 hover:bg-red-50">
            <FaEnvelope className="mr-2" /> Continue with Email
          </button>
          {isGoogleSignedIn ? (
            <div className="flex items-center justify-center bg-gray-100 py-2 rounded w-full mb-3">
              <button
                onClick={handleGoogleSignIn}
                className="flex items-center justify-center bg-green text-white border border-gray-300 py-2 rounded w-full mb-3"
              >
                <FaGoogle className="mr-2" /> Sign in with Google
              </button>
            </div>
          ) : (
            <button
              onClick={handleGoogleSignIn}
              className="flex items-center justify-center bg-green text-white border border-gray-300 py-2 rounded w-full mb-3"
            >
              <FaGoogle className="mr-2" /> Sign in with Google
            </button>
          )}
          <div className="text-center mt-4">
            New to Genie? <Link className="text-green">Create account</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogIn;
