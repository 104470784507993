// import { useState } from "react";
// import React from 'react';

// function Charges() {


//     const [charges, setCharges] = useState([
//         {
//             id: 1,
//             name: 'Packaging Charge - Fixed',
//             value: 10,
//             multiItem: 'No',
//             applicableOn: 'Order',
//             taxes: 'No Taxes Applied',
//         },
//         // Add more charges here if needed
//     ]);

//     const handleDelete = (id) => {
//         setCharges(charges.filter(charge => charge.id !== id));
//     };



//     return (
//         <div>


//             <div className="container mx-auto p-6">
//                 <h1 className="text-2xl font-semibold text-gray-700 mb-4">Charges</h1>
//                 <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6" role="alert">
//                     <p>This is the peak lunch hour; please avoid making any menu changes to menu between 12 pm and 2 pm to prevent order loss.</p>
//                 </div>
//                 <h2 className="text-xl font-medium text-gray-700 mb-4">Delivery Charges</h2>
//                 <table className="min-w-full bg-white border border-gray-200">
//                     <thead>
//                         <tr>
//                             <th className="px-4 py-2 border-b border-gray-200 text-left text-gray-700">Charges</th>
//                             <th className="px-4 py-2 border-b border-gray-200 text-left text-gray-700">Value</th>
//                             <th className="px-4 py-2 border-b border-gray-200 text-left text-gray-700">Multi Item</th>
//                             <th className="px-4 py-2 border-b border-gray-200 text-left text-gray-700">Applicable On</th>
//                             <th className="px-4 py-2 border-b border-gray-200 text-left text-gray-700">Taxes</th>
//                             <th className="px-4 py-2 border-b border-gray-200 text-left text-gray-700"></th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {charges.map(charge => (
//                             <tr key={charge.id}>
//                                 <td className="px-4 py-2 border-b border-gray-200">{charge.name}</td>
//                                 <td className="px-4 py-2 border-b border-gray-200">{charge.value}</td>
//                                 <td className="px-4 py-2 border-b border-gray-200">{charge.multiItem}</td>
//                                 <td className="px-4 py-2 border-b border-gray-200">{charge.applicableOn}</td>
//                                 <td className="px-4 py-2 border-b border-gray-200">{charge.taxes}</td>
//                                 <td className="px-4 py-2 border-b border-gray-200 text-right">
//                                     <button className="text-red-500 hover:text-red-700" onClick={() => handleDelete(charge.id)}>
//                                         <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                                             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
//                                         </svg>
//                                     </button>
//                                 </td>
//                             </tr>
//                         ))}
//                     </tbody>
//                 </table>
//                 <div className="mt-4">
//                     <button className="bg-green text-white px-4 py-2 rounded">Add Charges</button>
//                 </div>
//             </div>

//         </div>
//     )
// }

// export default Charges



import React, { useState } from "react";

function Charges() {
    const [charges, setCharges] = useState([
        {
            id: 1,
            name: "Packaging Charge - Fixed",
            chargeType: "Order Level Package",
            value: 10,
            isFlat: true,
            taxApplicable: "No Taxes Applied",
            status: "Prepared",
        },
    ]);

    const handleAddRow = () => {
        const newCharge = {
            id: charges.length + 1,
            name: "",
            chargeType: "Order Level Package",
            value: "",
            isFlat: true,
            taxApplicable: "No Taxes Applied",
            status: "Prepared",
        };
        setCharges([...charges, newCharge]);
    };

    const handleDelete = (id) => {
        setCharges(charges.filter((charge) => charge.id !== id));
    };

    const handleChange = (id, field, value) => {
        setCharges(
            charges.map((charge) =>
                charge.id === id ? { ...charge, [field]: value } : charge
            )
        );
    };

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-2xl font-semibold text-gray-700 mb-4">Charges</h1>
            <div
                className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6"
                role="alert"
            >
                <p>
                    This is the peak lunch hour; please avoid making any menu changes
                    between 12 pm and 2 pm to prevent order loss.
                </p>
            </div>

            <h2 className="text-xl font-medium text-gray-700 mb-4">Delivery Charges</h2>
            <table className="min-w-full bg-white border border-gray-200">
                <thead>
                    <tr>
                        <th className="px-4 py-2 border-b border-gray-200 text-left text-gray-700">
                            Charge Name
                        </th>
                        <th className="px-4 py-2 border-b border-gray-200 text-left text-gray-700">
                            Charge Type
                        </th>
                        <th className="px-4 py-2 border-b border-gray-200 text-left text-gray-700">
                            Value
                        </th>
                        <th className="px-4 py-2 border-b border-gray-200 text-left text-gray-700">
                            Tax Applicable
                        </th>
                        <th className="px-4 py-2 border-b border-gray-200 text-left text-gray-700">
                            Status
                        </th>
                        <th className="px-4 py-2 border-b border-gray-200 text-left text-gray-700">
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {charges.map((charge) => (
                        <tr key={charge.id}>
                            {/* Charge Name */}
                            <td className="px-4 py-2 border-b border-gray-200">
                                <input
                                    type="text"
                                    placeholder="Enter Charge Name"
                                    className="w-full p-2 border rounded"
                                    value={charge.name}
                                    onChange={(e) =>
                                        handleChange(charge.id, "name", e.target.value)
                                    }
                                />
                            </td>

                            {/* Charge Type Dropdown */}
                            <td className="px-4 py-2 border-b border-gray-200">
                                <select
                                    className="w-full p-2 border rounded"
                                    value={charge.chargeType}
                                    onChange={(e) =>
                                        handleChange(charge.id, "chargeType", e.target.value)
                                    }
                                >
                                    <option value="Order Level Package">Order Level Package</option>
                                    <option value="Item Level Package">Item Level Package</option>
                                </select>
                            </td>

                            {/* Charge Value */}
                            <td className="px-4 py-2 border-b border-gray-200">
                                {charge.chargeType === "Order Level Package" ? (
                                    <input
                                        type="text"
                                        placeholder="Enter Flat Price or %"
                                        className="w-full p-2 border rounded"
                                        value={charge.value}
                                        onChange={(e) =>
                                            handleChange(charge.id, "value", e.target.value)
                                        }
                                    />
                                ) : (
                                    <input
                                        type="number"
                                        placeholder="Enter Per Item Charge"
                                        className="w-full p-2 border rounded"
                                        value={charge.value}
                                        onChange={(e) =>
                                            handleChange(charge.id, "value", e.target.value)
                                        }
                                    />
                                )}
                            </td>

                            {/* Tax Applicable */}
                            <td className="px-4 py-2 border-b border-gray-200">
                                <input
                                    type="text"
                                    placeholder="Enter Tax Info"
                                    className="w-full p-2 border rounded"
                                    value={charge.taxApplicable}
                                    onChange={(e) =>
                                        handleChange(charge.id, "taxApplicable", e.target.value)
                                    }
                                />
                            </td>

                            {/* Status Toggle */}
                            <td className="px-4 py-2 border-b border-gray-200">
                                <button
                                    className={`px-4 py-2 rounded ${charge.status === "Prepared"
                                            ? "bg-green text-white"
                                            : "bg-green text-white"
                                        }`}
                                    onClick={() =>
                                        handleChange(
                                            charge.id,
                                            "status",
                                            charge.status === "Prepared" ? "Packaging" : "Prepared"
                                        )
                                    }
                                >
                                    {charge.status}
                                </button>
                            </td>

                            {/* Actions */}
                            <td className="px-4 py-2 border-b border-gray-200">
                                <button
                                    className="text-red-500 hover:text-red-700"
                                    onClick={() => handleDelete(charge.id)}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Add Charges Button */}
            <div className="mt-4">
                <button
                    className="bg-green text-white px-4 py-2 rounded hover:bg-green-600"
                    onClick={handleAddRow}
                >
                    Add Charges
                </button>
            </div>
        </div>
    );
}

export default Charges;

