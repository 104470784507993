import React, { useEffect, useState } from 'react';
import { deleteCustomRole, listCustomRoles } from '../../API/Api';
import { FaTrash } from 'react-icons/fa';

const Modal = ({ message, onConfirm, onCancel }) => (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75">
        <div className="bg-white rounded-lg p-6 max-w-md mx-auto shadow-lg">
            <h3 className="text-lg text-center font-semibold mb-4">{message}</h3>
            <div className="flex justify-around">
                <button
                    onClick={onCancel}
                    className="px-4 py-2 bg-gray-300 rounded-md hover:bg-gray-400"
                >
                    Cancel
                </button>
                <button
                    onClick={onConfirm}
                    className="px-4 py-2 bg-red text-white rounded-md hover:bg-red-600"
                >
                    Delete
                </button>
            </div>
        </div>
    </div>
);

const CustomRolesList = () => {
    const [state, setState] = useState({
        restaurantId: localStorage.getItem("restaurantId"),
        customRoles: []
    });

    const [modalState, setModalState] = useState({
        isVisible: false,
        roleId: null,
        roleName: ''
    });

    // Fetch custom roles from the API
    useEffect(() => {
        const fetchCustomRoles = async () => {
            try {
                const customRolesResponse = await listCustomRoles(state.restaurantId);
                console.log("Custom Roles Response:", customRolesResponse);

                setState(prevState => ({
                    ...prevState,
                    customRoles: customRolesResponse
                }));

                // Store roles in localStorage as an array of objects with roleId and roleName
                const rolesToStore = customRolesResponse.map(role => ({
                    roleId: role.roleId,
                    roleName: role.roleName
                }));
                localStorage.setItem("rolesList", JSON.stringify(rolesToStore));

            } catch (error) {
                console.error("Error fetching custom roles:", error);
            }
        };

        fetchCustomRoles();
    }, [state.restaurantId]);

    // Handle storing roleId in localStorage when a role name is clicked
    const handleRoleClick = (roleId) => {
        localStorage.setItem("selectedRoleId", roleId);
        console.log(`Role ID ${roleId} stored in localStorage`);
    };

    // Handle showing the delete confirmation modal
    const handleShowDeleteModal = (roleId, roleName) => {
        setModalState({
            isVisible: true,
            roleId,
            roleName
        });
    };

    // Handle hiding the modal
    const handleCancelDelete = () => {
        setModalState({
            isVisible: false,
            roleId: null,
            roleName: ''
        });
    };

    // Handle confirming deletion
    const handleConfirmDelete = async () => {
        const payload = {
            restaurantId: state.restaurantId,
            roleId: modalState.roleId
        };

        try {
            await deleteCustomRole(payload); // Call the API to delete the role
            setState(prevState => ({
                ...prevState,
                customRoles: prevState.customRoles.filter(role => role.roleId !== modalState.roleId)
            }));
            console.log(`Role ID ${modalState.roleId} removed from list and deleted from server`);
        } catch (error) {
            console.error("Error deleting role:", error);
        } finally {
            handleCancelDelete(); // Close the modal after deletion
        }
    };

    return (
        <div className="p-4">
            <h2 className="text-xl font-semibold mb-4">Custom Roles</h2>
            <ul className="space-y-2">
                {state.customRoles.map((role) => (
                    <li
                        key={role.roleId}
                        className="flex items-center justify-between p-2 border border-gray-300 rounded-md"
                    >
                        <span
                            onClick={() => handleRoleClick(role.roleId)}
                            className="text-gray-700 font-medium cursor-pointer"
                        >
                            {role.roleName}
                        </span>
                        <button
                            onClick={() => handleShowDeleteModal(role.roleId, role.roleName)}
                            className="text-red font-medium hover:underline flex items-center space-x-1"
                        >
                            <FaTrash />
                        </button>
                    </li>
                ))}
            </ul>

            {/* Modal */}
            {modalState.isVisible && (
                <Modal
                    message={`Are you sure you want to delete the role "${modalState.roleName}"?`}
                    onConfirm={handleConfirmDelete}
                    onCancel={handleCancelDelete}
                />
            )}
        </div>
    );
};

export default CustomRolesList;
