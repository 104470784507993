import React, { useState } from "react";
import "../pages/Orderhistory.css";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { format } from "date-fns";
import { IoClose } from "react-icons/io5";
import { IoDownloadOutline } from "react-icons/io5";
import { FaSearch } from "react-icons/fa";
import noshow from '../assets/no-food.webp';
// import { set } from "date-fns/set";
import { fetchOrderHistory } from "../API/Api";

function Orderhistory() {
  const [state, setState] = useState({
    openDate: false,
    date: {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
    appliedDateRange: {
      startDate: format(new Date(), "MMM dd yyyy"),
      endDate: format(new Date(), "MMM dd yyyy"),
    },
    isOpen: false,
    activeTab: "Order Status",
    orderStatus: [],
    rating: "",
    searchResult: null,
    restaurantId: localStorage.getItem("restaurantId"),
    orderHistory: [], // Store the fetched order history
  });

  const handleChange = (ranges) => {
    setState((prevState) => ({
      ...prevState,
      data: ranges.selection,
    }));
  };

  const handleClick = () => {
    setState((prevState) => ({
      ...prevState,
      openDate: !prevState.openDate
    }))
  };

  const handleApplyDateRange = async () => {
    try {
      const { startDate, endDate } = state.date;
      const formattedStartDate = format(startDate, "yyyy-MM-dd");
      const formattedEndDate = format(endDate, "yyyy-MM-dd");

      const history = await fetchOrderHistory({
        restaurantId: state.restaurantId,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        days: 10,
        queryStyle: "byDay",
      });

      setState((prevState) => ({
        ...prevState,
        appliedDateRange: {
          startDate: format(startDate, "MMM dd yyyy"),
          endDate: format(endDate, "MMM dd yyyy"),
        },
        openDate: false,
        orderHistory: history.orderHistory || [], // Store the fetched order history
      }));
    } catch (error) {
      console.error("Error fetching order history:", error.message);
    }
  };

  const togglePopup = () => {
    setState((prevState) => ({
      ...prevState,
      isOpen: !prevState.isOpen,
    }));
  };

  // Function to handle preset clicks
  const applyPreset = (preset) => {
    const now = new Date();
    let startDate, endDate;

    if (preset === "Today") {
      startDate = endDate = now;
    } else if (preset === "Yesterday") {
      startDate = endDate = new Date(now.setDate(now.getDate() - 1));
    } else if (preset === "This Week") {
      const firstDayOfWeek = new Date(now.setDate(now.getDate() - now.getDay()));
      startDate = firstDayOfWeek;
      endDate = now;
    } else if (preset === "Last Week") {
      const firstDayOfLastWeek = new Date(
        now.setDate(now.getDate() - now.getDay() - 7)
      );
      const lastDayOfLastWeek = new Date(
        now.setDate(now.getDate() - now.getDay() + 6)
      );
      startDate = firstDayOfLastWeek;
      endDate = lastDayOfLastWeek;
    }

    setState((prevState) => ({
      ...prevState,
      date: {
        ...prevState.date,
        startDate,
        endDate,
      },
    }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setState((prevState) => ({
      ...prevState,
      orderStatus: checked
        ? [...prevState.orderStatus, name]
        : prevState.orderStatus.filter((status) => status !== name),
    }));
  };

  const handleOrderTypeChange = (event) => {
    setState((prevState) => ({
      ...prevState,
      orderType: event.target.value,
    }));

  };

  const handleRatingChange = (event) => {
    setState((prevState) => ({
      ...prevState,
      rating: event.target.value,
    }));
  };

  const handleClearAll = () => {
    setState((prevState) => ({
      ...prevState,
      orderStatus: [],
      orderType: "",
      rating: "",
    }));
  };

  const handleApply = () => {
    console.log("Order Status:", state.orderStatus);
    console.log("Order Type:", state.orderType);
    console.log("Rating:", state.rating);
    togglePopup();
  };

  const renderActiveTabContent = () => {
    switch (state.activeTab) {
      case "Order Status":
        return (
          <div>
            {["Preparing", "Ready", "Pickup", "Delivered", "Rejected"].map(
              (status) => (
                <div key={status} className="mt-5 text-xl">
                  <input
                    type="checkbox"
                    id={status}
                    name={status}
                    checked={state.orderStatus.includes(status)}
                    onChange={handleCheckboxChange}
                    className="mr-2"
                  />
                  <label htmlFor={status}>{status}</label>
                </div>
              )
            )}
          </div>
        );
      case "Order Type":
        return (
          <div>
            <div className="mt-5 text-xl">
              <input
                type="radio"
                id="online"
                name="orderType"
                value="Online"
                checked={state.orderType === "Online"}
                onChange={handleOrderTypeChange}
                className="mr-2"
              />
              <label htmlFor="online">Online</label>
            </div>
            <div className="mt-5 text-xl">
              <input
                type="radio"
                id="offline"
                name="orderType"
                value="Offline"
                checked={state.orderType === "Offline"}
                onChange={handleOrderTypeChange}
                className="mr-2"
              />
              <label htmlFor="offline">Offline</label>
            </div>
          </div>
        );
      case "Ratings":
        return (
          <div>
            {[1, 2, 3, 4, 5].map((ratingValue) => (
              <div key={ratingValue} className="mt-5 text-xl">
                <input
                  type="radio"
                  id={`rating-${ratingValue}`}
                  name="rating"
                  value={ratingValue}
                  checked={state.rating === ratingValue.toString()}
                  onChange={handleRatingChange}
                  className="mr-2"
                />
                <label htmlFor={`rating-${ratingValue}`}>
                  {ratingValue} Stars
                </label>
              </div>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 7) {
      // Allow only numbers and limit input to 6 digits
      setState((prevState) => ({
        ...prevState,
        orderId: value,
      }));
    }
  };

  const handleSearch = () => {
    if (state.orderId.length === 7) {
      setState((prevState) => ({
        ...prevState,
        searchResult: state.orderId === "1234567"
          ? { id: state.orderId, name: "Sample Order" }
          : null,
      }));
    } else {
      alert("Please enter a 7-digit Order ID.");
    }
  };

  return (
    <>
      <div className="">
        <div className="flex justify-between items-center mb-4 border-b-2 p-3 border-gray-300 shadow-md">
          <h3 className="text-xl font-bold">Order History</h3>
          <div className="flex space-x-4 items-center">
            <div className="relative">
              <span onClick={handleClick} className="cursor-pointer calender flex items-center">
                {`${state.appliedDateRange.startDate} to ${state.appliedDateRange.endDate}`}
                <MdOutlineArrowDropDown className="text-xl ml-1" />
              </span>
              {state.openDate && (
                <div className="absolute top-full mt-2 z-10">
                  <DateRangePicker
                    ranges={[state.date]}
                    onChange={handleChange}
                    showSelectionPreview={true}
                  />
                  <div className="preset-buttons">
                    <button onClick={() => applyPreset("Today")} className="preset-btn">
                      Today
                    </button>
                    <button onClick={() => applyPreset("Yesterday")} className="preset-btn">
                      Yesterday
                    </button>
                    <button onClick={() => applyPreset("This Week")} className="preset-btn">
                      This Week
                    </button>
                    <button onClick={() => applyPreset("Last Week")} className="preset-btn">
                      Last Week
                    </button>
                  </div>
                  <div className="flex justify-end mt-2">
                    <button
                      className="bg-green text-white py-2 px-4 rounded"
                      onClick={handleApplyDateRange}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              )}
            </div>
            <button
              onClick={togglePopup}
              className="bg-green hover:bg-green-700 text-white text-lg h-12 w-40 rounded"
            >
              Filter
            </button>
            {state.isOpen && (
              <div className="fixed inset-0 bg-gray-600 top-0 left-0 right-0 bottom-0 bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white p-6 rounded shadow-lg relative">
                  <button
                    onClick={togglePopup}
                    className="text-red-500 absolute top-4 right-4"
                  >
                    <IoClose className="text-xl" />
                  </button>
                  <div className="flex">
                    <div className="bg-gray-100 p-4">
                      <button
                        onClick={() => setState((prevState) => ({ ...prevState, activeTab: "Order Status" }))}
                        className={`w-full text-left text-xl mb-2 ${state.activeTab === "Order Status"
                          ? "font-bold"
                          : "text-green"
                          }`}
                      >
                        Order Status
                      </button>
                      <button
                        onClick={() => setState((prevState) => ({ ...prevState, activeTab: "Order Type" }))}
                        className={`w-full text-left text-xl mb-2 ${state.activeTab === "Order Type"
                          ? "font-bold"
                          : "text-green"
                          }`}
                      >
                        Order Type
                      </button>
                      <button
                        onClick={() => setState((prevState) => ({ ...prevState, activeTab: "Rating" }))}
                        className={`w-full text-xl text-left ${state.activeTab === "Ratings"
                          ? "font-bold"
                          : "text-green"
                          }`}
                      >
                        Ratings
                      </button>
                    </div>
                    <div className="flex-grow p-4">
                      {renderActiveTabContent()}
                      <div className="mt-auto flex justify-between pt-4">
                        <button
                          onClick={handleClearAll}
                          className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
                        >
                          Clear All
                        </button>
                        <button
                          onClick={handleApply}
                          className="bg-green text-white px-4 py-2 rounded"
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <button className="bg-green hover:bg-green-700 text-white text-lg h-12 w-40 rounded flex items-center justify-center">
              <IoDownloadOutline className="text-2xl mr-2" /> Download CSV
            </button>
          </div>
        </div>

        <div className="mb-4 p-2">
          <div className="flex items-center border border-gray-300 rounded-md overflow-hidden w-2/5">
            <input
              type="text"
              value={state.orderId}
              onChange={handleInputChange}
              className="px-4 py-2 flex-grow focus:outline-none"
              placeholder="Search Order ID (7 digits)"
            />
            <button
              onClick={handleSearch}
              className="bg-green hover:bg-green-700 text-white px-4 py-2 flex items-center justify-center focus:outline-none"
            >
              <FaSearch className="mr-2" /> Search
            </button>
          </div>
          <div className="mt-8">
            {state.orderHistory.length > 0 ? (
              state.orderHistory.map((order, index) => (
                <div
                  key={order.orderId}
                  className="border border-gray-300 p-4 mb-4 rounded-md shadow-md"
                >
                  <h4 className="text-lg font-bold">Order #{index + 1}</h4>
                  <p><strong>Order ID:</strong> {order.orderId}</p>
                  <p><strong>User Name:</strong> {order.userName || "Unknown"}</p>
                  <p><strong>Date:</strong> {format(new Date(order.createdAt), "MMM dd yyyy")}</p>
                  <p><strong>Total Amount:</strong> ₹{order.totalAmount}</p>
                  <p><strong>Paid:</strong> {order.isPaid ? "Yes" : "No"}</p>
                  <p><strong>Status:</strong> {order.isFulfilled ? "Fulfilled" : "Pending"}</p>
                  <p><strong>Type:</strong> {order.orderType}</p>
                  <p><strong>Discount:</strong> ₹{order.discountOffered}</p>
                  <p><strong>Order Value:</strong> ₹{order.orderTotalValue}</p>
                </div>
              ))
            ) : (
              <div>
                <div className="text-red flex justify-center mt-8">
                  <img src={noshow} alt="no-food" className="w-56 h-56" />
                </div>
                <p className="text-red flex justify-center mt-3 text-lg">No orders to show</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Orderhistory;